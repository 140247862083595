import React from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {Row} from "react-bootstrap";

class BrochureRequestCreate extends Form {
    state = {
        data: {
            gender: "",
            firstName: "",
            lastName: "",
            email: "",
            telephone: "",
            zipCode: 0,
            acceptedMarketingActivity: false,
        },
        errors: {},
    };

    apiPath = "/brochure_requests";

    schema = {
        gender: Joi.string().required().min(2).max(30).label("Gender"),
        firstName: Joi.string().required().min(2).max(30).label("First name"),
        lastName: Joi.string().required().min(2).max(30).label("Last name"),
        email: Joi.string().required().email().label("Email"),
        telephone: Joi.string().required().min(10).max(14).label("Telephone"),
        zipCode: Joi.number().required().label("Zip code"),
        acceptedMarketingActivity: Joi.boolean().required().label("Marketing activity acceptance"),
    };

    brochureRequestHelper;

    constructor(props) {
        super(props);
        this.brochureRequestHelper = new ApiResourceHelper(this.apiPath);
    }

    doSubmit = async () => {
        console.log("Submitted");
        console.log('data submitted', this.state.data);

        await this.brochureRequestHelper.create(this.state.data);
        this.props.history.replace("/brochure_requests");
    };

    displayError = (field) => {
        const {errors} = this.state;

        if (errors === undefined || errors === null) return null;

        const copiedErrors = Object.keys(errors);
        //console.log("displayError", copiedErrors);

        if (copiedErrors.includes(field)) {
            console.log("error", errors[field]);
            return errors[field];
        }

        return null;
    };

    render() {
        return (

<React.Fragment>

            <div className="mt-6 mb-4 media"><span className="fa-stack ml-n1 mr-2"><svg aria-hidden="true"
                                                                                        focusable="false"
                                                                                        data-prefix="fas"
                                                                                        data-icon="circle"
                                                                                        className="svg-inline--fa fa-circle fa-w-16 text-300 fa-stack-2x"
                                                                                        role="img"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 512 512"><path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path></svg><svg
                aria-hidden="true" focusable="false" data-prefix="fas" data-icon="folder-plus"
                className="svg-inline--fa fa-folder-plus fa-w-16 fa-inverse text-primary fa-stack-1x" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g
                transform="translate(256 256)"><g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)"><path
                fill="currentColor"
                d="M464,128H272L208,64H48A48,48,0,0,0,0,112V400a48,48,0,0,0,48,48H464a48,48,0,0,0,48-48V176A48,48,0,0,0,464,128ZM359.5,296a16,16,0,0,1-16,16h-64v64a16,16,0,0,1-16,16h-16a16,16,0,0,1-16-16V312h-64a16,16,0,0,1-16-16V280a16,16,0,0,1,16-16h64V200a16,16,0,0,1,16-16h16a16,16,0,0,1,16,16v64h64a16,16,0,0,1,16,16Z"
                transform="translate(-256 -256)"></path></g></g></svg></span>
                <div className="media-body"><h5 className="text-primary position-relative mb-0 media-heading"><span
                    className="bg-200 pr-3">Others</span><span
                    className="border position-absolute absolute-vertical-center w-100 z-index--1 l-0"></span></h5><p
                    className="mb-0">Get more awesome cards for showing your different types of content..</p></div>
            </div>
            <Row>
                <div className={"col-xxl-8 pr-xl-2 mb-3 col-12"}>





                        <div className={"mb-3 card"}>
                            <div className={"card-header"}>
                                <h5 className="mb-0">Add a new </h5>
                            </div>
                            <div className={"bg-light card-body"}>

                                <form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <div className="col-lg-6">
                                            <div className={"form-group"}>
                                                {this.renderSelect("gender", "Gender", [
                                                    {key: "Mr.", value: "mister"},
                                                    {key: "Mrs.", value: "lady"},
                                                    {key: "Miss.", value: "miss"},
                                                ])}
                                            </div>
                                        </div>

                                    </Row>
                                    <Row>
                                        <div className="col-lg-6">
                                            <div className={"form-group"}>
                                                {this.renderInput("firstName", "text", "First name")}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className={"form-group"}>
                                                {this.renderInput("lastName", "text", "Last name")}
                                            </div>
                                        </div>

                                    </Row>
                                    <Row>
                                        <div className="col-lg-6">
                                            <div className={"form-group"}>
                                                {this.renderInput("email", "email", "Email")}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className={"form-group"}>
                                                {this.renderInput("telephone", "tel", "Telephone")}
                                            </div>
                                        </div>

                                    </Row>

                                    {this.renderInput("zipCode", "text", "Zip code")}

                                    <div className="form-group">
                                        <label htmlFor="acceptedMarketingActivity">I accepted the marketing
                                            activity</label>
                                        <div>
                                            <div className="form-check form-check-inline">
                                                <input name="acceptedMarketingActivity" className="form-check-input"
                                                       type="radio"
                                                       id="acceptedMarketingActivityYes" value="true"
                                                       onChange={this.handleChange}/>
                                                <label className="form-check-label"
                                                       htmlFor="acceptedMarketingActivityYes">Yes</label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input name="acceptedMarketingActivity" className="form-check-input"
                                                       type="radio"
                                                       id="acceptedMarketingActivityNo" value="false"
                                                       onChange={this.handleChange}/>
                                                <label className="form-check-label"
                                                       htmlFor="acceptedMarketingActivityNo">No</label>
                                            </div>
                                        </div>

                                        {this.displayError("acceptedMarketingActivityNo") && (
                                            <div className="alert alert-danger">
                                                {this.displayError("acceptedMarketingActivityNo")}
                                            </div>
                                        )}

                                    </div>
                                    <div className={"d-flex justify-content-end col-12"}>
                                        {this.renderButton("Save")}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                <div className="col-xxl-4 pl-xl-2 mb-3 col-lg-6">
                    <div className="h-100 card">
                        <div className="card-header"><h5 className="mb-0">Danger Zone</h5></div>
                        <div className="bg-light card-body"><h5 className="fs-0">Transfer Ownership</h5><p
                            className="fs--1">Transfer this account to another user or to an organization where you have
                            the
                            ability to create repositories.</p><a className="btn btn-falcon-warning btn-block"
                                                                  href="/widgets#!">Transfer</a>
                            <hr className="border border-dashed my-4" /><h5 className="fs-0">Delete this account</h5><p
                                className="fs--1">Once you delete a account, there is no going back. Please be
                                certain.</p><a
                                className="btn btn-falcon-danger btn-block" href="/widgets#!">Deactivate Account</a>
                        </div>
                    </div>
                </div>
            </Row>
</React.Fragment>

        );
    }
    }

    export default BrochureRequestCreate;
