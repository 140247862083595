import React, {useState} from 'react';
import {FaFileExport} from "react-icons/all";
import {Button} from "react-bootstrap";
import axios from "axios";

const ExportButton = ({endpoint, filename, filters, ...props}) => {
    console.log('filters', filters);
    const downloadExport = () => {
        axios.get(`${process.env.REACT_APP_BASE_API}${endpoint}`, {
            params: filters,
            headers: {['Accept']: 'text/csv'},
            responseType: "blob"
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    };

    return (
        <Button onClick={() => downloadExport()}><FaFileExport/> Export</Button>
    );
};

export default ExportButton;