import React, { Component } from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {Link} from "react-router-dom";
import Date from "../../../components/DateTimeDisplay";
import RequestDetail from "../../../components/App/RequestDetail";

class QuoteRequestShow extends Component {
  state = {
    quoteRequest: {},
    errors: {},
    loaded: false,
  };

  apiPath = "/quote_requests";

  quoteRequestHelper;

  constructor() {
    super();
    this.quoteRequestHelper = new ApiResourceHelper(this.apiPath);
  }

  getPrevious = () => {
    const {history} = this.props;
    history.goBack();
  }

  componentDidMount = () => {
    this.getQuoteRequest();
  };

  getQuoteRequest = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: quoteRequest } = await this.quoteRequestHelper.findOne(
        `${this.apiPath}/${id}`
      );

      this.setState({ quoteRequest, loaded: true });
    } catch (ex) {}
  };

  render() {
    const { quoteRequest, loaded } = this.state;

    console.log("quoteRequest", quoteRequest);

    if (loaded) {
      return (
        <RequestDetail request={quoteRequest} getPrevious={this.getPrevious} form={"quote"} />
      );
    }

    return null;
  }
}

export default QuoteRequestShow;
