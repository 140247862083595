import React, { Component } from "react";
import ApiResourceHelper from "../../helpers/ApiResourceHelper";
import Date from "../../components/DateTimeDisplay";

class InformationRequestShow extends Component {
  state = {
    informationRequest: {},
    errors: {},
    loaded: false,
  };

  genders = {
    male: 'Homme',
    Mr: 'Homme',
    female: 'Femme',
    Mme: 'Femme',
  };

  apiPath = "/information_requests";

  informationRequestHelper;

  constructor(props) {
    super(props);
    this.informationRequestHelper = new ApiResourceHelper(this.apiPath);
  }

  componentDidMount = () => {
    this.getInformationRequest();
  };

  getInformationRequest = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: informationRequest } = await this.informationRequestHelper.findOne(
        `${this.apiPath}/${id}`
      );

      this.setState({ informationRequest, loaded: true });
    } catch (ex) {}
  };

  getPrevious = () => {
    const {history} = this.props;
    history.goBack();
  }

  render() {
    const { informationRequest, loaded } = this.state;

    console.log("informationRequest", informationRequest);

    if (loaded) {
      return (
          <div className="card" style={{width: "100%"}}>
            <div className="card-body">
              <h5 className="card-title" style={{textTransform: "capitalize"}}>Fiche De Précommande</h5>
              <table className="table">
                <tbody>
                <tr>
                  <th scope="col">Date</th>
                  <td>
                    <Date date={informationRequest.createdAt}/>
                  </td>
                </tr>
                <tr>
                  <th scope="col">Civilité</th>
                  <td>{this.genders[informationRequest.gender] ? this.genders[informationRequest.gender] : informationRequest.gender}</td>
                </tr>
                <tr>
                  <th scope="col">Nom</th>
                  <td>{informationRequest.firstName} {informationRequest.lastName} </td>
                </tr>

                <tr>
                  <th scope="col">Email</th>
                  <td>{informationRequest.email}</td>
                </tr>

                <tr>
                  <th scope="col">Marque</th>
                  <td>Alfa Romeo</td>
                </tr>

                <tr>
                  <th scope="col">Modèle</th>
                  <td>Tonale</td>
                </tr>

                </tbody>
              </table>
            </div>
            <div className="card-footer">
              <div className={"btn-group"}>
                <button onClick={() => this.getPrevious()} className="btn badge badge-secondary badge-pill">
                  Retour
                </button>
              </div>

            </div>
          </div>
      );
    }

    return null;
  }
}

export default InformationRequestShow;
