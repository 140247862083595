import React, {Component} from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {CsvToHtmlTable} from "react-csv-to-table";
import DateTimeDisplay from "../../../components/DateTimeDisplay";
import moment from "moment/moment";

class LeadGenShow extends Component {
    state = {
        leadGen: {},
        errors: {},
        loaded: false,
    };

    apiPath = "/spreadsheet_data_imports";

    LeadGenHelper;

    constructor(props) {
        super(props);
        this.LeadGenHelper = new ApiResourceHelper(this.apiPath);
    }

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    componentDidMount = () => {
        this.getLeadGen();
    };

    getLeadGen = async () => {
        try {
            const id = this.props.match.params.id;
            const {data: leadGen} = await this.LeadGenHelper.findOne(
                `${this.apiPath}/${id}`
            );

            this.setState({leadGen, loaded: true});
        } catch (ex) {
        }
    };


    render() {
        const {leadGen, loaded} = this.state;
        console.log("leadGen", leadGen);

        if (loaded) {
            return (
                <div className="card" style={{width: "100%"}}>
                    <div className="card-body">
                        <h5 className="card-title">Fiche d'injection lead gen</h5>
                            <table className="table">
                            <tbody>
                            <tr>
                                <th scope="col">Marque</th>
                                <td>{leadGen.campaign && leadGen.campaign.model && leadGen.campaign.model.brand  ? leadGen.campaign.model.brand.name:''}</td>
                            </tr>
                            <tr>
                                <th scope="col">Modèle</th>
                                <td>{leadGen.campaign && leadGen.campaign.model ? leadGen.campaign.model.name:''}</td>
                            </tr>
                            <tr>
                                <th scope="col">Campagne</th>
                                <td>{leadGen.campaign ? leadGen.campaign.title:''}</td>
                            </tr>
                            <tr>
                                <th scope="col">Importé le</th>
                                <td>{leadGen.importedAt && (<DateTimeDisplay date={leadGen.importedAt} />)}</td>
                            </tr>
                            <tr>
                                <th scope="col">Total leads</th>
                                <td>{leadGen.totalLeads}</td>
                            </tr>
                            <tr>
                                <th scope="col">Total leads existans (non importé)</th>
                                <td>{leadGen.totalExistedLeads}</td>
                            </tr>
                            </tbody>
                        </table>
                        {!leadGen.confirmedAt && (
                            <div>
                                <CsvToHtmlTable
                                    data={leadGen.csv ? leadGen.csv : ''}
                                    csvDelimiter=","
                                    tableClassName="table table-striped table-hover"
                                />

                                <button onClick={this.confirmLeadGen} className={"btn btn-primary"}>Importer</button>
                            </div>
                        )}

                    </div>
                </div>
            );
        }

        return null;
    }

    confirmLeadGen = async () => {
        console.log('Start importing leads.');
        console.log('Lead Gen', this.state.leadGen);
        const apiPath = "/spreadsheet_data_imports";

        const updatedData = {confirmed: true};
        const helper = new ApiResourceHelper(apiPath);
        const {data: leadGen} = await helper.update(`${apiPath}/${this.state.leadGen.id}`, updatedData);

        this.setState({leadGen});
    }
}

export default LeadGenShow;
