import React, {Component} from 'react';
import ReactECharts from "echarts-for-react";
import {themeColors} from "../../../falcon/src/helpers/utils";

class EChartLineMultiple extends Component {
    render() {
        const {title, subTitle, data, theme} = this.props;
        console.log('EChartLineMultiple.data', data);

        const option = {
            title: {
                text: title,
                subtext: subTitle,
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                textStyle: { color: '#ffffff' },
            },
            legend: {
                data: data.labels,
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
                type: 'scroll',
                orient: 'horizontal',
                top: 20,
                bottom: 20,
            },
            grid: {
                //left: '3%',
                //right: '4%',
                //bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {
                        title: 'Télécharger l\'image',
                        type: 'png'
                    }
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: data.categories,
                axisLabel: {
                    textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
                }
            },
            series: [
                {
                    name: 'Brochure',
                    type: 'line',
                    smooth: true,
                    data: data.brochures,
                },
                {
                    name: 'Devis',
                    type: 'line',
                    smooth: true,
                    data: data.quotes,
                },
                {
                    name: 'Essai',
                    type: 'line',
                    smooth: true,
                    data: data.testDrives,
                },
                {
                    name: 'Leads Media',
                    type: 'line',
                    smooth: true,
                    data: data.mediaLeads,
                },
                {
                    name: 'Leads LP',
                    type: 'line',
                    smooth: true,
                    data: data.lp,
                },
                {
                    name: 'Leads Ocarz',
                    type: 'line',
                    smooth: true,
                    data: data.ocarz,
                },
                {
                    name: 'Leads XS',
                    type: 'line',
                    smooth: true,
                    data: data.xs,
                }
            ]
        };

        return <ReactECharts
            option={option}
            style={{height: 420}}
            theme={theme}
        />;
    }
}

export default EChartLineMultiple;