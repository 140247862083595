import React, {useEffect, useState} from "react";
import NavbarItem from "../NavbarItem";
import {
    FaAd,
    FaBox, FaBuilding,
    FaCar, FaChartLine,
    FaChartPie,
    FaExclamation, FaFileInvoiceDollar,
    FaMoneyBill,
    FaMoneyCheck,
    FaRegNewspaper, FaRobot,
    FaUsers,
    FaWarehouse,
    FaFile
} from "react-icons/all";
import {Link} from "react-router-dom";

const SidebarTwo = ({user, brands, currentBrand, mobileNavbarOpen}) => {
    const [navOpen, setNavOpen] = useState(true);
    //const [leads, setLeads] = useState([]);

    useEffect(() => {
        toggleNavOpenClass();
        //getLeads();
    }, []);

    const toggleNav = () => {
        setNavOpen(!navOpen);
        toggleNavOpenClass();
    }

    const toggleNavOpenClass = () => {
        if (navOpen) {
            console.log('nav open');
            document.body.classList.remove('windows');
            document.body.classList.remove('chrome');
            document.body.classList.remove('navbar-vertical-collapsed');
        } else {
            console.log('nav closed');
            document.body.classList.add('windows');
            document.body.classList.add('chrome');
            document.body.classList.add('navbar-vertical-collapsed');
        }
    }

    const showNav = () => {
        if (!navOpen) {
            document.body.classList.add('navbar-vertical-collapsed-hover');
        }
    }

    const hideNav = () => {
        if (!navOpen) {
            document.body.classList.remove('navbar-vertical-collapsed-hover');
        }
    }

    const getCurrentBrand = () => {
        console.log('currentBrand', currentBrand);
        return currentBrand ? currentBrand : {name: 'Brand'};
    }

    /*const getLeads = async () => {
        const apiHelper = new ApiResourceHelper("/campaigns");

        const queryParams = [];
        //queryParams.push({key: 'lead.model.brand', value: iri})

        const {data: leads} = await apiHelper.findBy(queryParams);
        console.log("leads", leads);

        setLeads(leads);
    }

    const getMenuLeadsByBrand = (brandId, platform) => {
        const finalLeads = [];

        const sources = {
            lp: "website",
            fb: "fb",
        };

        leads.forEach((l) => {
            if (l.model.brand.id === brandId && sources[l.source] === platform) {
                finalLeads.push({
                    name: l.title,
                    to: `/leads?lead=${l.id}&platform=${platform}`
                });
            }
        });

        return finalLeads;
    }

    const getMenuOcarzLeadsByBrand = (brandId) => {
        const finalLeads = [];

        leads.forEach((l) => {
            if (l.model.brand.id === brandId && l.source === "ocarz") {
                finalLeads.push({
                    name: l.title,
                    to: `/ocarz_leads?campaign=${l.id}&platform=ocarz`
                });
            }
        });

        return finalLeads;
    }*/

    const getBrandNavItems = () => {
        const brandNavItems = [];

        brands.forEach((b) => {

            const subItems = [
                {to: `/brochure_requests?brand_id=${b.id}`, name: "Brochure", icon: <FaRegNewspaper/>},
                {to: `/quote_requests?brand_id=${b.id}`, name: "Devis", icon: <FaMoneyCheck/>},
                {to: `/test_drive_requests?brand_id=${b.id}`, name: "Essai", icon: <FaCar/>},
                {to: `/leads?brand_id=${b.id}&type=media_lead`, name: "Leads Médias", icon: <FaChartLine/>},
                {to: `/chatbot_leads?brand_id=${b.id}&type=chatbot`, name: "Chatbot Leads", icon: <FaChartLine/>},
                {to: `/bot_leads?brand_id=${b.id}`, name: "Leads Bot", icon: <FaRobot/>},
                {to: `/ocarz_leads?brand_id=${b.id}`, name: "Ocarz Leads", icon: <FaChartLine/>},
                {to: `/prebookings?brand_id=${b.id}`, name: "Prébooking", icon: <FaFileInvoiceDollar/>},
                {to: `/leads?brand_id=${b.id}&type=prebooking_contact`, name: "Prébooking contact", icon: <FaFileInvoiceDollar/>},

            ];

            if (user.roles.includes('ROLE_ADMIN') || user.roles.includes("ROLE_SUPER_ADMIN")) {
                subItems.push({to: `/abandons?brand_id=${b.id}`, name: "Abandonées", icon: <FaExclamation/>});
                subItems.push({to: `/models?brand_id=${b.id}`, name: "Modèles", icon: <FaBuilding/>});
            }

            brandNavItems.push({
                name: b.name,
                subItems: subItems,
            });
        });

        return brandNavItems;
    };

    return (
        <React.Fragment>
            {user && (
                <nav className="navbar-vertical navbar-glass navbar navbar-expand-xl navbar-light">
                    <div className="d-flex align-items-center">
                        <div className="toggle-icon-wrapper">
                            <button
                                type="button"
                                id="toggleNavigationTooltip"
                                className="navbar-toggler-humburger-icon navbar-vertical-toggle btn btn-link"
                                onClick={() => {
                                    toggleNav()
                                }}
                            >
                <span className="navbar-toggle-icon">
                  <span className="toggle-line"/>
                </span>
                            </button>
                        </div>
                        <Link className="text-decoration-none navbar-brand text-left" href="/">
                            <div className="d-flex align-items-center py-3">
                                <span className="text-sans-serif">FCA</span>
                            </div>
                        </Link>
                    </div>
                    <div className={`scrollbar collapse ${mobileNavbarOpen && 'show'} navbar-collapse`}>
                        <ul className="navbar-nav flex-column" onMouseEnter={() => showNav()}
                            onMouseLeave={() => hideNav()}>
                            <NavbarItem to="/" name="Dashboard" icon={<FaChartPie/>}/>

                            {user.roles && !user.roles.includes("ROLE_BRAND") && (
                                <NavbarItem
                                    to="#"
                                    name="Marques"
                                    icon={<FaBox/>}
                                    subItems={getBrandNavItems()}
                                />
                            )}

                            {user.roles && user.roles.includes("ROLE_BRAND") && (
                                <React.Fragment>
                                    <NavbarItem
                                        to="#"
                                        name={getCurrentBrand().name}
                                        icon={<FaBox/>}
                                        subItems={[
                                            {to: `/brochure_requests`, name: "Brochure", icon: <FaRegNewspaper/>},
                                            {to: `/quote_requests`, name: "Devis", icon: <FaMoneyCheck/>},
                                            {to: `/test_drive_requests`, name: "Essai", icon: <FaCar/>},
                                            {to: `/abandons`, name: "Abandonées", icon: <FaExclamation/>},
                                            {to: `/models`, name: "Modèles", icon: <FaBuilding/>},
                                        ]}
                                    />
                                </React.Fragment>
                            )}

                            {/* <NavbarItem
                                to="/prebookings"
                                name="Prebookings"
                                icon={<FaFileInvoiceDollar/>}
                            /> */}

                            {(user.roles && user.roles.includes("ROLE_SUPER_ADMIN")) && (
                                <NavbarItem
                                    to="/information_requests"
                                    name="Demandes information"
                                    icon={<FaFileInvoiceDollar/>}
                                />
                            )}

                            {user.roles && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_SUPER_ADMIN")) && (
                                <NavbarItem
                                    to="/xs_leads"
                                    name="XS Leads"
                                    icon={<FaBuilding/>}
                                />
                            )}

                            {user.roles && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_SUPER_ADMIN")) && (
                                <NavbarItem
                                    to="/showrooms"
                                    name="Showrooms"
                                    icon={<FaWarehouse/>}
                                />
                            )}

                            {user.roles && user.roles.includes("ROLE_SUPER_ADMIN") && (
                                <NavbarItem to="/campaigns" name="Ajouter une campagne" icon={<FaAd/>}/>
                            )}

                            {(user.roles && (user.roles.includes("ROLE_SUPER_ADMIN") || user.roles.includes("ROLE_LEAD"))) && (
                                <NavbarItem to="/lead_gens" name="Injection Leads Médias" icon={<FaChartLine/>}/>
                            )}

                            {user.roles && user.roles.includes("ROLE_SUPER_ADMIN") && (
                                <NavbarItem to="/users" name="Utilisateurs" icon={<FaUsers/>}/>
                            )}

                            {user.roles && user.roles.includes("ROLE_SUPER_ADMIN") && (
                                <NavbarItem to="/brands" name="Marques" icon={<FaBox/>}/>
                            )}

                            {user.roles && user.roles.includes("ROLE_SUPER_ADMIN") && (
                                <NavbarItem to="/logs_list" name="Logs" icon={<FaFile/>}/>
                            )}
                        </ul>
                    </div>
                </nav>
            )}
        </React.Fragment>
    );
};

export default SidebarTwo;
