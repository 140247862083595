import Joi from "joi-browser";
import BrochureRequestCreate from "../BrochureRequestCreate";

class BrochureRequestEdit extends BrochureRequestCreate {
  schema = { ...this.schema, id: Joi.number().required() };

  componentDidMount = () => {
    this.populateBrochureRequest();
  };

  populateBrochureRequest = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: brochureRequest } = await this.brochureRequestHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("brochureRequest", brochureRequest);

      this.setState({ data: this.mapToViewBrochureRequest(brochureRequest) });

      console.log('arrived here');
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.brochureRequestHelper.update(`${this.apiPath}/${id}`, this.state.data);
    this.props.history.replace("/brochure_requests");
  };

  mapToViewBrochureRequest = (brochureRequest) => {
    return {
      id: brochureRequest.id,
      firstName: brochureRequest.firstName,
      lastName: brochureRequest.lastName,
      email: brochureRequest.email,
      telephone: brochureRequest.telephone,
      zipCode: brochureRequest.zipCode,
      acceptedMarketingActivity: brochureRequest.acceptedMarketingActivity,
    };
  };
}

export default BrochureRequestEdit;
