import React, {Component} from "react";
import RequestList from "../../../components/App/RequestList";
import queryString from "query-string";

class QuoteRequestList extends Component {
    render() {
        const {location, user} = this.props;
        let params = queryString.parse(location.search);

        return (
            <RequestList user={user} title={"Liste des devis"} tableName={"devis"} form={"quote"} endpoint={"/quote_requests"} exportEndpoint={"/quote_request_exports"} exportFilename={"quotes.csv"} brandId={params.brand_id} location={location} />
        );
    }
}

export default QuoteRequestList;
