import Joi from "joi-browser";
import LeadSubmissionCreate from "../LeadCreate";

class LeadSubmissionEdit extends LeadSubmissionCreate {
  schema = { ...this.schema, id: Joi.number().required() };

  componentDidMount = () => {
    this.populateLeadSubmission();
  };

  populateLeadSubmission = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: LeadSubmission } = await this.LeadSubmissionHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("LeadSubmission", LeadSubmission);

      this.setState({ data: this.mapToViewLeadSubmission(LeadSubmission) });

      console.log('arrived here');
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.LeadSubmissionHelper.update(`${this.apiPath}/${id}`, this.state.data);
    this.props.history.replace("/leads");
  };

  mapToViewLeadSubmission = (LeadSubmission) => {
    return {
      id: LeadSubmission.id,
      firstName: LeadSubmission.firstName,
      lastName: LeadSubmission.lastName,
      email: LeadSubmission.email,
      telephone: LeadSubmission.telephone,
      zipCode: LeadSubmission.zipCode,
      acceptedMarketingActivity: LeadSubmission.acceptedMarketingActivity,
    };
  };
}

export default LeadSubmissionEdit;
