import React, { Component } from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";

class BrandShow extends Component {
  state = {
    brand: {},
    errors: {},
    loaded: false,
  };

  apiPath = "/brands";

  brandHelper;

  constructor() {
    super();
    this.brandHelper = new ApiResourceHelper(this.apiPath);
  }

  componentDidMount = () => {
    this.getBrand();
  };

  getBrand = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: brand } = await this.brandHelper.findOne(
        `${this.apiPath}/${id}`
      );

      this.setState({ brand, loaded: true });
    } catch (ex) {}
  };

  render() {
    const { brand, loaded } = this.state;

    console.log("brand", brand);

    if (loaded) {
      return (
        <div className="card" style={{ width: "100%" }}>
          <div className="card-body">
            <h5 className="card-title">{brand.name}</h5>

            <table className="table">
              <tbody>
                <tr>
                  <th scope="col">Name</th>
                  <td>{brand.name}</td>
                </tr>
              </tbody>
            </table>

            <a href={`/brands/${brand.id}/edit`} className="btn btn-primary">
              Edit brand
            </a>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default BrandShow;
