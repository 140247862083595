import React, {Component} from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import Input from "../../Form/Input";
import {FaUpload} from "react-icons/all";

class DocumentCreate extends Component {
    state = {
        selectedFile: null,
    };

    constructor(props) {
        super(props);
        this.apiHelper = new ApiResourceHelper("/documents");
    }

    componentDidMount = async () => {
        console.log("props", this.props);
    }

    onFileChange = async (e) => {
        console.log('document file change');
        const selectedFile = e.target.files[0];

        const formData = new FormData();

        formData.append("file", selectedFile, selectedFile.name);

        try {
            console.log('uploading document');
            const {data: document} = await this.apiHelper.create(formData);
            this.setState({document});

            const {uploadCallback} = this.props;
            uploadCallback(document);
        } catch (ex) {
            console.log('upload_ex', ex);
        }

    };

    render() {
        return (
            <label className="file btn btn-outline-dark">
                <Input label={<FaUpload/>} type="file" name="document" onChange={this.onFileChange}
                       customStyle={{display: 'none'}} containerCustomStyle={{marginBottom: 0}}
                       labelCustomStyle={{fontSize: '1.0rem'}}/>
            </label>
        );
    }
}

export default DocumentCreate;