import React, {Component} from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import Date from "../../../components/DateTimeDisplay";
import moment from "moment/moment";

class leadShow extends Component {
    state = {
        lead: {},
        itemLogs: [],
        errors: {},
        loaded: false,
        tab: 'info',
        isActive: false
    };

    apiPath = "/leads";

    leadHelper;
    itemLogHelper;

    constructor(props) {
        super(props);
        this.leadHelper = new ApiResourceHelper(this.apiPath);
        this.itemLogHelper = new ApiResourceHelper("/item_logs");
    }

    componentDidMount = () => {
        this.getlead();
    };

    getlead = async () => {
        try {
            const id = this.props.match.params.id;
            const {data: lead} = await this.leadHelper.findOne(
                `${this.apiPath}/${id}`
            );

            const {data: itemLogs} = await this.itemLogHelper.findBy(
                [{key: 'mediaLead', value: `${this.apiPath}/${id}`}]
            );

            this.setState({lead, loaded: true, itemLogs: itemLogs['hydra:member']});
        } catch (ex) {
            console.log('getlead ex', ex)
        }
    };

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    render() {
        const {lead, loaded, itemLogs} = this.state;
        const columns = [
            {
                path: "processedAt",
                label: "traité à",
                content: (request) =>
                    (moment(request.processedAt).format('lll'))
            },
            {
                path: "resContent",
                label: "Response status"
            },
            {
                path: "resContent",
                label: "Response Content"
            },
            {
                path: "webhelpStatus",
                label: "Webhelp Status"
            },
            {
                path: "validationLog",
                label: "validation Log",
            },
            {
                path: "logs",
                label: "Log"
            },
        ];


        if (loaded) {
            return (
                <div className="card" style={{width: "100%"}}>
                    <div className="card-body">
                        <h5 className="card-title" style={{textTransform: "capitalize"}}>Fiche de Lead</h5>
                        <div>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "info" && "active"}`} href="#"
                                       onClick={() => this.setState({tab: "info"})}>Info</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "feedback" && "active"}`} href="#"
                                       onClick={() => this.setState({tab: "feedback"})}>Feedback</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "log" && "active"}`} href="#"
                                       onClick={() => this.setState({tab: "log"})}>Log</a>
                                </li>
                            </ul>
                            {this.state.tab === "info" && <table className="table">
                                <tbody>
                                <tr>
                                    <th scope="col">Date</th>
                                    <td>
                                        <Date date={lead.createdAt}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col">Lead Date</th>
                                    <td>
                                        <Date date={lead.leadDate}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col">Civilité</th>
                                    <td>{lead.gender}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Nom</th>
                                    <td>{lead.firstName} {lead.lastName}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Email</th>
                                    <td>{lead.email}</td>
                                </tr>
                                {/*{form !== "brochure" && (*/}
                                <tr>
                                    <th scope="col">Téléphone</th>
                                    <td>{lead.telephone}</td>
                                </tr>
                                {/* )}*/}

                                {/*{form !== "brochure" && (*/}
                                <tr>
                                    <th scope="col">Ville</th>
                                    <td>{lead.city && lead.city.name}</td>
                                </tr>
                                {/*)}*/}

                                <tr>
                                    <th scope="col">Modèle</th>
                                    <td>{lead.lead.model && lead.lead.model.name}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Tag</th>
                                    <td>{lead.tag}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Version</th>
                                    <td>{lead.version}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Pack</th>
                                    <td>{lead.pack}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Couleur</th>
                                    <td>{lead.color}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Prix</th>
                                    <td>{lead.price}</td>
                                </tr>
                                </tbody>
                            </table>}
                            {this.state.tab === "feedback" && <div>
                                {lead.feedback && <table className="table">
                                    <tbody>
                                    <tr>
                                        <th scope="col">Webhelp ID</th>
                                        <td>{lead.feedback.webhelpId}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Commentaite</th>
                                        <td>{lead.feedback.comment}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Date d'appel</th>
                                        <td><Date date={lead.feedback.callDate}/></td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Heure d'appel</th>
                                        <td>{lead.feedback.callHour}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Statut d'appel</th>
                                        <td>{lead.feedback.callStatus}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Action</th>
                                        <td>{lead.feedback.action}</td>
                                    </tr>
                                    </tbody>
                                </table>}

                            </div>}
                            {this.state.tab === "log" && <table className="table">
                                <thead>
                                <tr>
                                    {
                                        columns.map((column) => (
                                            <th key={column.path || column.key}>{column.label}</th>
                                        ))
                                    }
                                </tr>

                                </thead>
                                <tbody>
                                {
                                    Array.isArray(itemLogs) && itemLogs.sort(
                                        (a, b) => {
                                            return new Date(a.processedAt) - new Date(b.processedAt)
                                        }).map((request) => (
                                        <tr key={request.id}>
                                            <td>{new Date(request.processedAt)}</td>
                                            <td>{request.resStatus}</td>
                                            <td>{request.resContent}</td>
                                            <td>{request.webhelpStatus}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{request.validationLog}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{request.log}</td>
                                        </tr>
                                    ))

                                }

                                </tbody>
                            </table>}
                        </div>

                    </div>
                    <div className="card-footer">
                        <div className={"btn-group"}>
                            <button onClick={() => this.getPrevious()} className="btn badge badge-secondary badge-pill">
                                Retour
                            </button>
                            {/*<Link href={`/quote_leads/${lead.id}/edit`} className="btn btn-primary">*/}
                            {/*    Edit Quote*/}
                            {/*</Link>*/}
                        </div>

                    </div>
                </div>
            );
        }

        return null;
    }
}

export default leadShow;
