import _ from "lodash";
import React, { Component } from "react";

class Body extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);
    else {
      return _.get(item, column.path);
    }
  };

  createKey = (item, column) => {
    return item.id + (column.path || column.key);
  };

  render() {
    const { data, columns, onDelete } = this.props;

    return (
      <tbody>
        {data.map((item) => (
          <tr className={"btn-reveal-trigger border-top border-200"} key={item.key ? item.key:item.id}>
            {columns.map((column) => (
              <td key={this.createKey(item, column)}>
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default Body;
