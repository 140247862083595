import React, {Component} from "react";
import Joi from "joi-browser";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import Form from "../../../components/Form";

class LeadGenEdit extends Form {
  state = {
    data: {
      name: "",
      document: "",
    },
    errors: {},
  };

  apiPath = "/spreadsheet_data_imports";
  
  schema = {
    name: Joi.string().required().email().max(100).label("Name"),
    document: Joi.string().required().regex(/^\/documents\/\d*$/i).label("Document"),
  };

  LeadGenHelper;

  constructor(props) {
    super(props);
    this.LeadGenHelper = new ApiResourceHelper(this.apiPath);
  }

  getPrevious = () => {
    const {history} = this.props;
    history.goBack();
  }

  componentDidMount = () => {
    this.populateLeadGen();
  };

  populateLeadGen = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: LeadGen } = await this.LeadGenHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("LeadGen", LeadGen);

      this.setState({ data: this.mapToViewLeadGen(LeadGen) });
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;

    const {data} = this.state;
    data.roles = [data.roles];
    console.log('data', data);

    console.log('data with password removed', data);

    await this.LeadGenHelper.update(`${this.apiPath}/${id}`, data);
    this.props.history.replace("/spreadsheet_data_imports");
  };

  mapToViewLeadGen = (LeadGen) => {
    return {
      firstName: LeadGen.firstName,
      lastName: LeadGen.lastName,
      email: LeadGen.email,
      roles: LeadGen.roles[0],
    };
  };

  render() {
    return (
        <div className={"no-gutters row mt-lg-5"}>

          <div className={"pr-lg-2 col-lg-8"}>

            <div className={"mb-3 card"}>
              <div className={"card-header"}>
                <h5 className="mb-0">Modification de lead gen</h5>
              </div>
              <div className={"bg-light card-body"}>

                <form onSubmit={this.handleSubmit}>
                  {this.renderInput("name", "name", "Name")}
                  {this.renderButton("Sauvegarder")}
                </form>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default LeadGenEdit;

