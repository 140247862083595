import React, { Component } from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";

class CampaignShow extends Component {
  state = {
    campaign: {},
    errors: {},
    loaded: false,
  };

  apiPath = "/campaigns";

  campaignHelper;

  constructor() {
    super();
    this.campaignHelper = new ApiResourceHelper(this.apiPath);
  }

  componentDidMount = () => {
    this.getCampaign();
  };

  getCampaign = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: campaign } = await this.campaignHelper.findOne(
        `${this.apiPath}/${id}`
      );

      this.setState({ campaign, loaded: true });
    } catch (ex) {}
  };

  render() {
    const { campaign, loaded } = this.state;

    console.log("campaign", campaign);

    if (loaded) {
      return (
        <div className="card" style={{ width: "100%" }}>
          <div className="card-body">
            <h5 className="card-title">{campaign.name}</h5>

            <table className="table">
              <tbody>
                <tr>
                  <th scope="col">Name</th>
                  <td>{campaign.name}</td>
                </tr>
              </tbody>
            </table>

            <a href={`/campaigns/${campaign.id}/edit`} className="btn btn-primary">
              Edit campaign
            </a>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default CampaignShow;
