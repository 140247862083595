import React from 'react';

 const DateTimeDisplay = ({date}) => {
     const theDate = new Date(date);
     const dateText = `${ (`0${theDate.getDate()}`).slice(-2) }/${ (`0${theDate.getMonth()+1}`).slice(-2) }/${theDate.getFullYear()} ${ (`0${theDate.getHours()}`).slice(-2) }:${ (`0${theDate.getMinutes()}`).slice(-2) }`;
    return (
        <React.Fragment>
            {dateText}
        </React.Fragment>
    );
}

export default DateTimeDisplay;
