import React from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";

class UserCreate extends Form {
    state = {
        data: {
            email: "",
            firstName: "",
            lastName: "",
            plainPassword: "",
            roles: "",

        },
        errors: {},
    };

    apiPath = "/users";

    schema = {
        email: Joi.string().required().min(2).max(100).label("Email"),
        firstName: Joi.string().required().min(2).max(30).label("Prénom"),
        lastName: Joi.string().required().min(2).max(30).label("Nom"),
        plainPassword: Joi.string().required().min(6).max(30).label("Mot de passe"),
        roles: Joi.string().required().min(2).max(30).label("Role"),
    };

    userHelper;

    constructor(props) {
        super(props);
        this.userHelper = new ApiResourceHelper(this.apiPath);
    }

    doSubmit = async () => {
        console.log("Submitted");

        const {data} = this.state;
        data.roles = [data.roles];
        await this.userHelper.create(data);
        this.props.history.replace("/users");
    };

    render() {
        return (
            <div className={"no-gutters row mt-lg-5"}>

                <div className={"pr-lg-2 col-lg-8"}>

                    <div className={"mb-3 card"}>
                        <div className={"card-header"}>
                            <h5 className="mb-0">Ajout d'utilisateur</h5>
                        </div>
                        <div className={"bg-light card-body"}>

                            <form onSubmit={this.handleSubmit}>
                                {this.renderInput("email", "email", "Email")}
                                {this.renderInput("lastName", "text", "Nom")}
                                {this.renderInput("firstName", "text", "Prénom")}
                                {this.renderInput("plainPassword", "password", "Mot de passe")}
                                {this.renderSelect("roles", "Role", [
                                    {key: "Lead", value: "ROLE_LEAD"},
                                    {key: "Brand", value: "ROLE_BRAND"},
                                    {key: "Admin", value: "ROLE_ADMIN"},
                                    {key: "Super Admin", value: "ROLE_SUPER_ADMIN"},
                                ])}
                                {this.renderButton("Sauvegarder")}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserCreate;
