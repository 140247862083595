import React, {Component} from "react";
import ApiResourceHelper from "../../helpers/ApiResourceHelper";
import Date from "../../components/DateTimeDisplay";
import moment from "moment";

class BotLeadShow extends Component {
    state = {
        lead: {}, errors: {}, loaded: false, isActive: false, itemLogs: []
    };

    apiPath = "/bot_leads";

    leadHelper;
    itemLogHelper;

    constructor(props) {
        super(props);
        this.leadHelper = new ApiResourceHelper(this.apiPath);
        this.itemLogHelper = new ApiResourceHelper("/item_logs");
    }

    componentDidMount = () => {
        this.getLead();
    };

    getLead = async () => {
        try {
            const id = this.props.match.params.id;
            const {data: lead} = await this.leadHelper.findOne(`${this.apiPath}/${id}`);

            const {data: itemLogs} = await this.itemLogHelper.findBy(
                [{key: 'botLead', value: `${this.apiPath}/${id}`}]
            );

            this.setState({lead, loaded: true, itemLogs: itemLogs['hydra:member']});
        } catch (ex) {
        }
    };

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    handleToggle = () => {
        this.setState({isActive: !this.state.isActive});
    };

    render() {
        const {lead, loaded, itemLogs} = this.state;
        const columns = [
            {
                path: "processedAt",
                label: "traité à",
                content: (request) =>
                    (moment(request.processedAt).format('lll'))
            },
            {
                path: "resContent",
                label: "Response status"
            },
            {
                path: "resContent",
                label: "Response Content"
            },
            {
                path: "webhelpStatus",
                label: "Webhelp Status"
            },
            {
                path: "validationLog",
                label: "validation Log",
            },
            {
                path: "logs",
                label: "Log"
            },
        ];

        console.log("lead", lead);

        if (loaded) {
            return (<div className="card" style={{width: "100%"}}>
                <div className="card-body">
                    <h5 className="card-title" style={{textTransform: "capitalize"}}>Fiche de Lead</h5>
                    <div>
                        <button className="btn btn-primary m-3"
                                onClick={this.handleToggle}> {!this.state.isActive ? 'Show logs' : 'Show leads info'} </button>

                        {!this.state.isActive ? (<table className="table">
                            <tbody>
                            <tr>
                                <th scope="col">Date</th>
                                <td>
                                    <Date date={lead.createdAt}/>
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">Nom</th>
                                <td>{lead.name} </td>
                            </tr>
                            <tr>
                                <th scope="col">Email</th>
                                <td>{lead.email}</td>
                            </tr>
                            <tr>
                                <th scope="col">Téléphone</th>
                                <td>{lead.telephone}</td>
                            </tr>

                            <tr>
                                <th scope="col">Ville</th>
                                <td>{lead.city}</td>
                            </tr>

                            <tr>
                                <th scope="col">Marque</th>
                                <td>{lead.brand && lead.brand.name}</td>
                            </tr>

                            <tr>
                                <th scope="col">Modèle</th>
                                <td>{lead.model}</td>
                            </tr>

                            <tr>
                                <th scope="col">Version</th>
                                <td>{lead.trim}</td>
                            </tr>

                            <tr>
                                <th scope="col">Langue</th>
                                <td>{lead.lang}</td>
                            </tr>

                            <tr>
                                <th scope="col">Source</th>
                                <td>{lead.source}</td>
                            </tr>

                            <tr>
                                <th scope="col">Demande</th>
                                <td>{lead.request}</td>
                            </tr>
                            <tr>
                                <th scope="col">Choix</th>
                                <td>
                                    <ul>
                                        {lead.choices && lead.choices.map((choice) => {
                                            return <li>{choice}</li>
                                        })}
                                    </ul>
                                </td>
                            </tr>

                            </tbody>
                        </table>) : (
                            <table className="table">
                                <thead>
                                <tr>
                                    {
                                        columns.map((column) => (
                                            <th key={column.path || column.key}>{column.label}</th>
                                        ))
                                    }
                                </tr>

                                </thead>
                                <tbody>
                                {
                                    itemLogs && itemLogs.map((request) => (
                                        <tr key={request.id}>
                                            <td>{moment(request.processedAt).format('lll')}</td>
                                            <td>{request.resStatus}</td>
                                            <td>{request.resContent}</td>
                                            <td>{request.webhelpStatus}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{request.validationLog}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{request.log}</td>
                                        </tr>
                                    ))
                                }

                                </tbody>
                            </table>
                        )
                        }
                    </div>
                </div>
                <div className="card-footer">
                    <div className={"btn-group"}>
                        <button onClick={() => this.getPrevious()} className="btn badge badge-secondary badge-pill">
                            Retour
                        </button>
                        {/*<Link href={`/quote_leads/${lead.id}/edit`} className="btn btn-primary">*/}
                        {/*    Edit Quote*/}
                        {/*</Link>*/}
                    </div>

                </div>
            </div>);
        }

        return null;
    }
}

export default BotLeadShow;
