import React, {Component} from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import moment from "moment";

// import Datetime from "../../../falcon/src/components/plugins/Datetime";

class LogShow extends Component {
    state = {
        lead: {}, errors: {}, loaded: false,
    };

    apiPath = "/logs";

    leadHelper;

    constructor(props) {
        super(props);
        this.leadHelper = new ApiResourceHelper(this.apiPath);
    }

    componentDidMount = () => {
        this.getlead();
    };

    getlead = async () => {
        try {
            const id = this.props.match.params.id;
            const {data: lead} = await this.leadHelper.findOne(`${this.apiPath}/${id}`);

            this.setState({lead, loaded: true});
        } catch (ex) {
        }
    };

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    render() {
        const {lead, loaded} = this.state;

        console.log("lead", lead);

        if (loaded) {
            return (
                <div className="card" style={{width: "100%"}}>
                    <div className="card-body">
                        <h5 className="card-title" style={{textTransform: "capitalize"}}>Fiche de Lead</h5>
                        <table className="table">
                            <tbody>
                            <tr>
                                <th scope="col">Command</th>
                                <td>
                                    {lead.command}
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">Args</th>
                                <td>{JSON.stringify(lead.args)}</td>
                            </tr>
                            <tr style={{overflow: "scroll", height: "200px !important"}}>
                                <th scope="col">Logs</th>
                                <div style={{height: "200px !important"}}>

                                    <td style={{whiteSpace: 'pre-wrap'}}>{lead.log}</td>
                                </div>
                            </tr>
                            <tr>
                                <th scope="col">Status</th>
                                <td>
                                    {lead.status > 0 ? (<span className="badge badge-success">SUCCESS</span>
                                    ) : lead.error > 0 ? (<span className="badge badge-danger">ERROR</span>) : (
                                        <span className="badge badge-warning"></span>)}
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">Success</th>
                                <td>{lead.success}</td>
                            </tr>
                            <tr>
                                <th scope="col">Failed</th>
                                <td>{lead.error ? lead.error : 0}</td>
                            </tr>
                            <tr>
                                <th scope="col">Start Time</th>
                                <td>
                                    {moment(lead.startTime).format('lll')}
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">End Time</th>
                                <td>
                                    {moment(lead.endTime).format('lll')}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer">
                        <div className={"btn-group"}>
                            <button onClick={() => this.getPrevious()} className="btn badge badge-secondary badge-pill">
                                Retour
                            </button>
                            {/*<Link href={`/quote_leads/${lead.id}/edit`} className="btn btn-primary">*/}
                            {/*    Edit Quote*/}
                            {/*</Link>*/}
                        </div>

                    </div>
                </div>
            );
        }

        return null;
    }
}

export default LogShow;
