import React from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";

class QuoteRequestCreate extends Form {
    state = {
        data: {
            gender: "",
            firstName: "",
            lastName: "",
            email: "",
            telephone: "",
            zipCode: 0,
            acceptedMarketingActivity: false,
        },
        errors: {},
    };

    apiPath = "/quote_requests";

    schema = {
        gender: Joi.string().required().min(2).max(30).label("Gender"),
        firstName: Joi.string().required().min(2).max(30).label("First name"),
        lastName: Joi.string().required().min(2).max(30).label("Last name"),
        email: Joi.string().required().email().label("Email"),
        telephone: Joi.string().required().min(10).max(14).label("Telephone"),
        zipCode: Joi.number().required().label("Zip code"),
        acceptedMarketingActivity: Joi.boolean().required().label("Marketing activity acceptance"),
    };

    quoteRequestHelper;

    constructor(props) {
        super(props);
        this.quoteRequestHelper = new ApiResourceHelper(this.apiPath);
    }

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    doSubmit = async () => {
        console.log("Submitted");
        console.log('data submitted', this.state.data);

        await this.quoteRequestHelper.create(this.state.data);
        this.props.history.replace("/quote_requests");
    };

    displayError = (field) => {
        const {errors} = this.state;

        if (errors === undefined || errors === null) return null;

        const copiedErrors = Object.keys(errors);
        //console.log("displayError", copiedErrors);

        if (copiedErrors.includes(field)) {
            console.log("error", errors[field]);
            return errors[field];
        }

        return null;
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {this.renderSelect("gender", "Gender", [
                    {key: "Mr.", value: "mister"},
                    {key: "Mrs.", value: "lady"},
                    {key: "Miss.", value: "miss"},
                ])}

                {this.renderInput("firstName", "text", "First name")}
                {this.renderInput("lastName", "text", "Last name")}
                {this.renderInput("email", "email", "Email")}
                {this.renderInput("telephone", "tel", "Telephone")}
                {this.renderInput("zipCode", "text", "Zip code")}

                <div className="form-group">
                    <label htmlFor="acceptedMarketingActivity">I accepted the marketing activity</label>
                    <div>
                        <div className="form-check form-check-inline">
                            <input name="acceptedMarketingActivity" className="form-check-input" type="radio"
                                   id="acceptedMarketingActivityYes" value="true" onChange={this.handleChange}/>
                            <label className="form-check-label" htmlFor="acceptedMarketingActivityYes">Yes</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input name="acceptedMarketingActivity" className="form-check-input" type="radio"
                                   id="acceptedMarketingActivityNo" value="false" onChange={this.handleChange}/>
                            <label className="form-check-label" htmlFor="acceptedMarketingActivityNo">No</label>
                        </div>
                    </div>

                    {this.displayError("acceptedMarketingActivityNo") && (
                        <div className="alert alert-danger">
                            {this.displayError("acceptedMarketingActivityNo")}
                        </div>
                    )}

                </div>

                {this.renderButton("Save")}
            </form>
        );
    }
}

export default QuoteRequestCreate;
