import React, {Component} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import _ from "lodash";
import Swal from "sweetalert2";
import Table from "../../../components/Table";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import paginate from "../../../utils/paginate";
import http from "../../../services/http";
import {Col, Dropdown, Row} from "react-bootstrap";
import queryString from "query-string";
import DateDisplay from "../../../components/DateDisplay";
import {FaEllipsisH, FaEdit, FaTrash} from "react-icons/all";

class ModelList extends Component {
    state = {
        models: [],
        currentPage: 1,
        pageSize: 30,
        sortColumn: {path: "name", order: "asc"},
        searchQuery: "",
        brand: {}
    };

    apiPath = "/models";

    columns = [
        {
            key: "image",
            path: "image",
            content: ((model) => {
                if (model.image) {
                    return <img src={`${http.endPoint}/uploads/images/${model.image.filePath}`} width={150}
                                alt={model.name}/>
                }

                return <React.Fragment/>
            }),
        },
        {
            path: "brand",
            label: "Brand",
            content: ((model) => (
                <span>{model.brand.name}</span>
            )),
        },
        {
            path: "name",
            label: "Name",
        },
        {
            path: "description",
            label: "Description",
        },
        {
            path: "dateSorted",
            label: "Date",
            content: (model) => (
                <DateDisplay date={model.createdAt}/>
            )
        },
        {
            key: "actions",
            content: (model) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}
                                         variant="transparent"
                        >
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link to={`/models/${model.id}`}
                                      className="btn btn-light btn-block shadow-sm text-left">
                                    <FaEdit/> Edit
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item className="p-0">
                                <button
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Are you sure?',
                                            text: 'You are about to delete this brand.',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Oui, je le supprime!',
                                            cancelButtonText: 'Non, je le garde!'
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                if (this.handleDelete(model)) {
                                                    Swal.fire('Model supprimé!', 'Le model a été bien supprimé.', 'success');
                                                } else {
                                                    Swal.fire('Model non supprimé!', 'On ne peut pas supprimer model à cause d\'une erreur système.', 'error');
                                                }
                                            } else {
                                            }
                                        });
                                    }}
                                    className={"btn btn-light btn-block shadow-sm text-left"}
                                >
                                    <FaTrash/> Delete
                                </button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    modelHelper;

    constructor(props) {
        super(props);
        this.modelHelper = new ApiResourceHelper(this.apiPath);
    }

    componentDidMount() {
        const {user} = this.props;
        console.log('componentDidMount.user', user);

        if (user.roles && user.roles.includes("ROLE_BRAND")) {
            this.getBrandByUser();

            if (this.state.brand) {
                this.getModelsByUser();
            }

        } else {
            this.getBrand();
            this.getModels();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location, user} = this.props;
        console.log('componentDidUpdate.user', user);

        if (prevProps.location.key !== location.key) {
            if (user.roles && user.roles.includes("ROLE_BRAND")) {
                this.getBrandByUser();

                if (this.state.brand) {
                    this.getModelsByUser();
                }

            } else {
                this.getBrand();
                this.getModels();
            }
        }
    }

    getBrandByUser = async () => {
        try {
            const path = "/brands";
            const brandHelper = new ApiResourceHelper(path);

            const {data: brands} = await brandHelper.findAll();
            console.log('brand', brands);

            this.setState({brand: brands[0]});
        } catch (ex) {
            console.log('ex', ex);
        }
    };

    getBrand = async () => {
        try {
            const path = "/brands";
            const brandHelper = new ApiResourceHelper(path);

            let params = queryString.parse(this.props.location.search);

            const {data: brand} = await brandHelper.findOne(
                `${path}/${params.brand_id}`
            );

            this.setState({brand});
        } catch (ex) {
        }
    };

    getModels = async () => {
        const {currentPage} = this.state;

        const queryParams = [];

        const {location} = this.props;
        const params = queryString.parse(location.search);
        console.log('params.brand_id', params.brand_id);
        queryParams.push({key: 'brand', value: `/brands/${params.brand_id}`});

        if (currentPage) {
            queryParams.push({key: 'page', value: currentPage});
        }

        const {data} = await this.modelHelper.findBy(queryParams);

        const finalModels = [];
        data["hydra:member"].forEach((m) => {
            const date = new Date(m.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalModels.push({
                ...m, dateSorted
            });
        });

        this.setState({models: finalModels});
    };

    getModelsByUser = async () => {
        const {user} = this.props;
        console.log('getModels.user', user);

        const queryParams = [];

        if (user && user.roles && user.roles.includes("ROLE_BRAND")) {
            queryParams.push({key: 'brand', value: `/brands/${this.state.brand.id}`})
        }

        const {data: models} = await this.modelHelper.findBy(queryParams);
        console.log("models", models);

        const finalModels = [];
        models.forEach((m) => {
            const date = new Date(m.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalModels.push({
                ...m, dateSorted
            });
        });

        this.setState({models: finalModels});
    };

    handleDelete = async (model) => {
        const originalModels = this.state.models;

        const models = originalModels.filter((b) => b.id !== model.id);
        this.setState({models});

        try {
            await this.modelHelper.remove(`${this.apiPath}/${model.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This model has already been removed.");

            this.setState({models: originalModels});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    getPagedData = () => {
        const {
            models: allModels,
            currentPage,
            pageSize,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = allModels;

        if (searchQuery) {
            filtered = allModels.filter((b) =>
                b.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const models = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: models};
    };

    getExportedData = () => {
        const {data} = this.getPagedData();

        const exportedData = [];

        data.forEach((item, index) => {

            exportedData.push({
                marque: item.brand.name,
                modele: item.name,
                image: item.image ? `${http.endPoint}/uploads/images/${item.image.filePath}` : '',
            })
        });

        return exportedData;
    }

    handleSearchChange = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    render() {
        const {models, total, currentPage, pageSize, sortColumn, brand} = this.state;

        const {user} = this.props;
        console.log("user", user);


        return (
            <React.Fragment>
                <Table
                    tableName={"models"}
                    tableTitle={`List of models under ${brand.name}`}
                    columns={this.columns}
                    data={models}
                    exportedData={[]}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    createLink={`/models/create`}
                    searchQuery={this.state.searchQuery}
                    onSearchChange={this.handleSearchChange}
                    totalCount={total}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                />
            </React.Fragment>
        );
    }
}

export default ModelList;
