import React, {Component} from "react";
import queryString from "query-string";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {toast} from "react-toastify";
import _ from "lodash";
import paginate from "../../../utils/paginate";
import DateDisplay from "../../../components/DateDisplay";
import {Dropdown} from "react-bootstrap";
import {FaEllipsisH, FaEye} from "react-icons/all";
import {Link} from "react-router-dom";
import Table from "../../../components/Table";
import {getDate} from "../../../utils/utils";

class XSLeadList extends Component {
    state = {
        loading: true,
        xsLeads: [],
        currentPage: 1,
        pageSize: 30,
        sortColumn: {path: "dateSorted", order: "desc"},
        searchQuery: "",
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()]
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getRequests();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;

        if (prevProps.location.key !== location.key) {
            this.getRequests();
        }

        if ((this.state.dateRange === null)
            || (!prevState.dateRange || (
                prevState.dateRange[0] !== this.state.dateRange[0]
                || prevState.dateRange[1] !== this.state.dateRange[1])
            )
            || prevState.currentPage !== this.state.currentPage
            || prevState.searchQuery !== this.state.searchQuery
        ) {
            this.getRequests();
        }
    }

    getRequests = async () => {
        this.setState({loading: true});
        const {dateRange, currentPage} = this.state;

        const apiHelper = new ApiResourceHelper(`/ocarz_leads`);

        const queryParams = [];

        queryParams.push({key: 'source', value: "xs"});

        if (dateRange && dateRange[0]) {
            queryParams.push({key: 'createdAt%5Bafter%5D', value: getDate(dateRange[0])});
        }

        if (dateRange && dateRange[1]) {
            queryParams.push({key: 'createdAt%5Bbefore%5D', value: `${getDate(dateRange[1])} 23:59`});
        }

        if (currentPage) {
            queryParams.push({key: 'page', value: currentPage});
        }

        const {data} = await apiHelper.findBy(queryParams);

        const finalRequests = [];
        data["hydra:member"].forEach((r) => {
            const date = new Date(r.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalRequests.push({
                ...r, dateSorted, nameSorted: `${r.firstName} ${r.lastName}`
            });
        });

        this.setState({xsLeads: finalRequests, loading: false, total: data["hydra:totalItems"]});
    };

    handleDelete = async (request) => {
        const originalRequests = this.state.xsLeads;

        const xsLeads = originalRequests.filter((b) => b.id !== request.id);
        this.setState({xsLeads});

        try {
            await this.apiHelper.remove(`${this.apiPath}/${request.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This request has already been removed.");

            this.setState({xsLeads: originalRequests});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    handleDateRangeChange = (dateRange) => {
        console.log("dateRange", dateRange);

        this.setState({
            searchQuery: "",
            currentPage: 1,
            dateRange
        });
    }

    getPagedData = () => {
        const {
            xsLeads: allRequests,
            currentPage,
            pageSize,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = allRequests;

        if (searchQuery) {
            filtered = allRequests.filter((b) =>
                b.email.toLowerCase().startsWith(searchQuery)
                || b.firstName.toLowerCase().startsWith(searchQuery)
                || b.lastName.toLowerCase().startsWith(searchQuery)
                || b.telephone.toLowerCase().startsWith(searchQuery)
                || (b.city && b.city.name.toLowerCase().startsWith(searchQuery))
            );
        }

        console.log('sortColumn', sortColumn);

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const xsLeads = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: xsLeads};
    };

    handleSearchChange = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    genders = {
        mister: 'Homme',
        lady: 'Femme',
        miss: 'Demoiselle',
    };

    columns = [
        {
            path: "dateSorted",
            label: "Date",
            content: (request) => (
                <DateDisplay date={request.createdAt}/>
            ),
        },
        {
            path: "email",
            label: "Email",
        },
        {
            path: 'nameSorted',
            content: (request) => <React.Fragment>{request.firstName} {request.lastName}</React.Fragment>,
            label: "Nom"
        },
        {
            path: 'telephone',
            label: "Téléphone",
            content: (request) => <React.Fragment>
                {request.telephone}{request.telephoneSecondary && `/${request.telephoneSecondary}`}
            </React.Fragment>,
        },
        {
            key: "actions",
            content: (request) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="transparent"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}>
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link to={`/xs_leads/${request.id}`}
                                      className={"btn btn-light btn-block shadow-sm text-left"}>
                                    <FaEye/> Voir
                                </Link>
                            </Dropdown.Item>

                            {/*<Dropdown.Item className="p-0">
                                <button
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Etes-vous sûr?',
                                            text: 'Vous êtes sur le point de supprimer cette demande.',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Oui, je le supprime!',
                                            cancelButtonText: 'Non, je le garde!'
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                if (this.handleDelete(request)) {
                                                    Swal.fire('Demande supprimée!', 'La demande a été bien supprimée.', 'success');
                                                } else {
                                                    Swal.fire('Demande non supprimée!', 'La demande n\'a pas été supprimée.', 'error');
                                                }
                                            } else {
                                            }
                                        });
                                    }}
                                    className={"btn btn-light btn-block shadow-sm text-left"}>
                                    <FaTrash/> Supprimer
                                </button>
                            </Dropdown.Item>*/}
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    getExportedData = () => {
        const {xsLeads} = this.state;
        const exportedData = [];

        xsLeads.forEach((item, index) => {
            const date = new Date(item.createdAt);

            const newItem = {
                date: `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${date.getHours()}:${date.getMinutes()}`,
                nom: item.lastName + ' ' + item.firstName,
                email: item.email,
                telephone: item.telephone,
                ville: item.city ? item.city.name : '',
            }

            exportedData.push(newItem)
        });

        return exportedData;
    }

    render() {
        const {xsLeads, total, currentPage, pageSize, sortColumn, dateRange, loading} = this.state;
        //const {totalCount, data: xsLeads} = this.getPagedData();

        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={xsLeads}
                    //exportedData={this.getExportedData()}
                    exportedData={[]}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    tableName={"xs leads"}
                    tableTitle={"XS leads"}
                    searchQuery={this.state.searchQuery}
                    onSearchChange={this.handleSearchChange}
                    totalCount={total}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    dateRange={dateRange}
                    handleDateRangeChange={this.handleDateRangeChange}
                    loading={loading}
                />
            </React.Fragment>
        );
    }
}

export default XSLeadList;
