import jwtDecode from "jwt-decode";
import http from "../../services/http";

const currentEndPoint = `${http.endPoint}/login_check`;
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(username, password) {
    const { data } = await http.post(currentEndPoint, {
        username,
        password,
    });

    //console.log("login_data", data.token);

    localStorage.setItem(tokenKey, data.token);

    return data;
}

export function loginWithJwt(token) {
    localStorage.setItem(tokenKey, token);
}

export async function logout() {
    localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem(tokenKey);
        return jwtDecode(jwt);
    } catch (ex) {
        return null;
    }
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}

export default {
    login,
    logout,
    getCurrentUser,
    loginWithJwt,
    getJwt,
};
