import React, {Component} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import _ from "lodash";
import Swal from "sweetalert2";
import Table from "../../../components/Table";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import paginate from "../../../utils/paginate";
import http from "../../../services/http";
import {Dropdown} from "react-bootstrap";
import queryString from "query-string";
import DateDisplay from "../../../components/DateDisplay";
import {FaEllipsisH, FaEdit, FaTrash} from "react-icons/all";

class BrandList extends Component {
    state = {
        brands: [],
        currentPage: 1,
        pageSize: 30,
        sortColumn: {path: "name", order: "asc"},
        searchQuery: "",
        brand: {}
    };

    apiPath = "/brands";

    columns = [
        {
            key: "image",
            path: "image",
            content: ((brand) => {
                if (brand.image) {
                    return <img src={`${http.endPoint}/uploads/images/${brand.image.filePath}`} width={150}
                                alt={brand.name}/>
                }

                return <React.Fragment/>
            }),
        },
        {
            path: "name",
            label: "Name",
        },
        {
            path: "dateSorted",
            label: "Date",
            content: (brand) => (
                <DateDisplay date={brand.createdAt}/>
            )
        },
        {
            key: "actions",
            content: (brand) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}
                                         variant="transparent"
                        >
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link to={`/brands/${brand.id}`}
                                      className="btn btn-light btn-block shadow-sm text-left">
                                    <FaEdit/> Edit
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item className="p-0">
                                <button
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Are you sure?',
                                            text: 'You are about to delete this brand.',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Oui, je le supprime!',
                                            cancelButtonText: 'Non, je le garde!'
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                if (this.handleDelete(brand)) {
                                                    Swal.fire('Brand supprimé!', 'Le brand a été bien supprimé.', 'success');
                                                } else {
                                                    Swal.fire('Brand non supprimé!', 'On ne peut pas supprimer brand à cause d\'une erreur système.', 'error');
                                                }
                                            } else {
                                            }
                                        });
                                    }}
                                    className={"btn btn-light btn-block shadow-sm text-left"}
                                >
                                    <FaTrash/> Delete
                                </button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    brandHelper;

    constructor(props) {
        super(props);
        this.brandHelper = new ApiResourceHelper(this.apiPath);
    }

    componentDidMount() {
        const {user} = this.props;
        console.log('componentDidMount.user', user);

        this.getBrands();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location, user} = this.props;
        console.log('componentDidUpdate.user', user);

        if (prevProps.location.key !== location.key) {
            this.getBrands();
        }
    }

    getBrands = async () => {
        const {currentPage} = this.state;
        const queryParams = [];

        if (currentPage) {
            queryParams.push({key: 'page', value: currentPage});
        }

        const {data} = await this.brandHelper.findBy(queryParams);

        const finalBrands = [];
        data["hydra:member"].forEach((m) => {
            const date = new Date(m.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalBrands.push({
                ...m, dateSorted
            });
        });

        this.setState({brands: finalBrands});
    };

    handleDelete = async (brand) => {
        const originalBrands = this.state.brands;

        const brands = originalBrands.filter((b) => b.id !== brand.id);
        this.setState({brands});

        try {
            await this.brandHelper.remove(`${this.apiPath}/${brand.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This brand has already been removed.");

            this.setState({brands: originalBrands});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    getPagedData = () => {
        const {
            brands: allBrands,
            currentPage,
            pageSize,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = allBrands;

        if (searchQuery) {
            filtered = allBrands.filter((b) =>
                b.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const brands = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: brands};
    };

    getExportedData = () => {
        const {data} = this.getPagedData();

        const exportedData = [];

        data.forEach((item, index) => {

            exportedData.push({
                marque: item.brand.name,
                brande: item.name,
                image: item.image ? `${http.endPoint}/uploads/images/${item.image.filePath}` : '',
            })
        });

        return exportedData;
    }

    handleSearchChange = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    render() {
        const {brands, total, currentPage, pageSize, sortColumn, brand} = this.state;

        const {user} = this.props;
        console.log("user", user);

        return (
            <React.Fragment>
                <Table
                    tableName={"brands"}
                    tableTitle={`List of brands`}
                    columns={this.columns}
                    data={brands}
                    exportedData={[]}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    createLink={`/brands/create`}
                    searchQuery={this.state.searchQuery}
                    onSearchChange={this.handleSearchChange}
                    totalCount={total}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                />
            </React.Fragment>
        );
    }
}

export default BrandList;
