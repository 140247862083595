import React from "react";

const Select = ({name, label, error, options, ...rest}) => {
    return (
        <div className="form-group mt-3 mb-3">
            {label && <label htmlFor={name}>{label}</label>}
            <select name={name} id={name} {...rest} className="form-control">
                {options.map((o) => (
                    <option value={o.value} key={o.value}>
                        {o.key}
                    </option>
                ))}
            </select>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
};

export default Select;
