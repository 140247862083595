import React from "react";
import {FaExclamation, FaExclamationTriangle} from "react-icons/all";

const Input = ({name, label, error, customStyle, containerCustomStyle, labelCustomStyle, ...rest}) => {
    return (

        <div className="form-group" style={containerCustomStyle}>
            <label htmlFor={name} style={labelCustomStyle}>{label}</label>
            <input {...rest} name={name} id={name} className={`form-control`} style={customStyle}/>
            {error && <div className="alert alert-danger d-flex align-items-center"><FaExclamationTriangle
                className="mr-3"/> {error}</div>}
        </div>
    );
};

export default Input;
