import React, {Component} from 'react';
import {Button, ButtonGroup, Card, Col, Container, FormGroup, Row} from "react-bootstrap";
import ApiResourceHelper from "../../helpers/ApiResourceHelper";
import ApiAshnooHelper from "../../helpers/ApiAshnooHelper";
import CardSummary from "../../falcon/src/components/dashboard/CardSummary";
import CardBody from "reactstrap/es/CardBody";
import EChartLineMultiple from "../../components/echarts/EChartLineMultiple";
import EChartLineHeat from "../../components/echarts/EChartLineHeat";
import EChartLeadsByCity from "../../components/echarts/EChartLeadsByCity";
import _ from "lodash";
import EChartLeadsBySource from "../../components/echarts/EChartLeadsBySource";
import EchartTotalLeadsByModel from "../../components/echarts/EchartTotalLeadsByModel";
import LeadsByModelAndPlatformChart from "../../components/echarts/LeadsByModelAndPlatformChart";
import EchartTotalLeadsByShowroom from "../../components/echarts/EchartTotalLeadsByShowroom";
import EChartLeadsByModelAndDay from "../../components/echarts/EChartLeadsByModelAndDay";
import * as echarts from 'echarts';
import MDateRangePicker from "../../components/MDateRangePicker/MDateRangePicker";
import {getDate} from "../../utils/utils";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import WidgetsSectionTitle from "../../falcon/src/components/widgets/WidgetsSectionTitle";

echarts.registerTheme('dark', {
    backgroundColor: '#071325'
});
var totalconversations = [];
class Home extends Component {

    state = {
        selectedBrand: 0,
        brand: {
            id: 0,
            name: "",
            slug: "",
        },
        brandsQuery: [],
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()],

        stats: [],
        metrics: [],
        topModels: [],
        topShowrooms: [],
        topCities: [],
        topUtmSources: [],
        metricByModelAndPlatforms: [],

        loaded: {
            stats: false,
            metrics: false,
            topModels: false,
            topShowrooms: false,
            topCities: false,
            topUtmSources: false,
            metricByModelAndPlatforms: false,
        },
    };
    
    defaultFrom = new Date();

    constructor(props) {
        super(props);
        this.defaultFrom.setMonth(0);
        this.defaultFrom.setDate(1);
    }

    componentDidMount() {
        this.getStats();
        this.getMetrics();
        this.getTopModels();
        this.getTopShowrooms();
        this.getTopCities();
        this.getUtmSources();
        this.getMetricByModelAndPlatforms();
        // this.getTotalConvos();
        this.getStatsConvos();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            (prevState.dateRange && (prevState.dateRange[0] !== this.state.dateRange[0] || prevState.dateRange[1] !== this.state.dateRange[1]
                || (prevState.selectedBrand !== this.state.selectedBrand)
            ))
        ) {
            this.getStats();
            this.getMetrics();
            this.getTopModels();
            this.getTopShowrooms();
            this.getTopCities();
            this.getUtmSources();
            this.getMetricByModelAndPlatforms();
            this.getStatsConvos();
        }
    }

    handleDateRangeChange = (dateRange) => {
        ////console.log("dateRange", dateRange);
        this.setState({
            loaded: {
                ...this.state.loaded,
                brochures: false,
                quotes: false,
                testDrives: false,
                leadSubmissions: false,
                ocarzLeads: false,
                xsLeads: false
            },
            dateRange
        });
    }

    dateQuery = () => {
        const {dateRange} = this.state;

        const queryParams = [];

        if (dateRange && dateRange[0]) {
            queryParams.push({key: 'from', value: getDate(dateRange[0])});
        }

        if (dateRange && dateRange[1]) {
            queryParams.push({key: 'to', value: `${getDate(dateRange[1])}`});
        }

        return queryParams;
    }

    getStats = async () => {
        const {loaded} = this.state;
        this.setState({loaded: {...this.state.loaded, stats: false}});
        const apiHelper = new ApiResourceHelper('/stats');
        const {data} = await apiHelper.findBy(this.dateQuery().concat(this.state.brandsQuery));
        this.setState({stats: data["hydra:member"], loaded: {...this.state.loaded, stats: true}});
    };

    getMetrics = async () => {
        const {loaded} = this.state;
        this.setState({loaded: {...this.state.loaded, metrics: false}});
        const apiHelper = new ApiResourceHelper('/metrics');
        const {data} = await apiHelper.findBy(this.dateQuery().concat(this.state.brandsQuery));
        this.setState({metrics: data["hydra:member"], loaded: {...this.state.loaded, metrics: true}});
    };

    getTopModels = async () => {
        const {loaded} = this.state;
        this.setState({loaded: {...this.state.loaded, topModels: false}});
        const apiHelper = new ApiResourceHelper('/top_models');
        const {data} = await apiHelper.findBy(this.dateQuery().concat(this.state.brandsQuery));
        this.setState({topModels: data["hydra:member"], loaded: {...this.state.loaded, topModels: true}});
    };

    getTopShowrooms = async () => {
        const {loaded} = this.state;
        this.setState({loaded: {...this.state.loaded, topShowrooms: false}});
        const apiHelper = new ApiResourceHelper('/top_showrooms');
        const {data} = await apiHelper.findBy(this.dateQuery().concat(this.state.brandsQuery));
        this.setState({topShowrooms: data["hydra:member"], loaded: {...this.state.loaded, topShowrooms: true}});
    };

    getTopCities = async () => {
        const {loaded} = this.state;
        this.setState({loaded: {...this.state.loaded, topCities: false}});
        const apiHelper = new ApiResourceHelper('/top_cities');
        const {data} = await apiHelper.findBy(this.dateQuery().concat(this.state.brandsQuery));
        this.setState({topCities: data["hydra:member"], loaded: {...this.state.loaded, topCities: true}});
    };

    getUtmSources = async () => {
        const {loaded} = this.state;
        this.setState({loaded: {...this.state.loaded, topUtmSources: false}});
        const apiHelper = new ApiResourceHelper('/top_utm_sources');
        const {data} = await apiHelper.findBy(this.dateQuery().concat(this.state.brandsQuery));
        this.setState({topUtmSources: data["hydra:member"], loaded: {...this.state.loaded, topUtmSources: true}});
    };

    getMetricByModelAndPlatforms = async () => {
        const {loaded} = this.state;
        this.setState({loaded: {...this.state.loaded, metricByModelAndPlatforms: false}});
        const apiHelper = new ApiResourceHelper('/metric_by_model_and_platforms');
        const {data} = await apiHelper.findBy(this.dateQuery().concat(this.state.brandsQuery));
        this.setState({metricByModelAndPlatforms: data["hydra:member"], loaded: {...this.state.loaded, metricByModelAndPlatforms: true}});
    };
    getStatsConvos = async () => {
        const {loaded} = this.state;
        this.setState({loaded: {...this.state.loaded, conversations: false}});
        const apiHelper = new ApiAshnooHelper('/total_conversations');
        const data = await apiHelper.findBy(this.dateQuery().concat(this.state.brandsQuery));
        this.setState({conversations: data, loaded: {...this.state.loaded, conversations: true}});
        totalconversations =[];
        totalconversations.push(data["hydra:member"].length > 0 ? data["hydra:member"][0].total : null);
    };
      
    render() {
        const {user, brands, theme} = this.props;
        const {
            selectedBrand,
            dateRange,
            loaded,
            stats,
            metrics,
            topShowrooms,
            topModels,
            topCities,
            topUtmSources,
            metricByModelAndPlatforms,
            conversations
        } = this.state;

        // console.log('loaded', loaded);

        let stat = null;

        if (stats.length) {
            stat = stats[0];
        }

        const labels = [];
        const brochures = [];
        const quotes = [];
        const testDrives = [];
        const lp = [];
        const fb = [];
        const ocarz = [];
        const xs = [];
        const linkedin = [];
        const moteur = [];
        const mediaLeads = [];
        const websiteLeads = [];
        const externalLeads = [];
        const totalLeads = [];
        const abandons = [];

        if (metrics.length) {
            metrics.forEach((i) => {
                // console.log('totalLeads', i.totalLeads);
                const date = new Date(i.date);
                labels.push(`${date.getMonth() + 1}/${date.getDate()}`);
                brochures.push(i.brochures);
                quotes.push(i.quotes);
                testDrives.push(i.testDrives);
                lp.push(i.lp);
                mediaLeads.push(i.mediaLeads);
                fb.push(i.fb);
                ocarz.push(i.ocarz);
                xs.push(i.xs);
                linkedin.push(i.linkedin);
                moteur.push(i.moteur);
                websiteLeads.push(i.websiteLeads);
                externalLeads.push(i.externalLeads);
                totalLeads.push(i.totalLeads);
                abandons.push(i.abandons);
            });
        }

        const showroomLabels = [];
        const showroomValues = [];
        if (topShowrooms.length) {
            topShowrooms.forEach((i) => {
                showroomLabels.push(i.name);
                showroomValues.push(i.total);
            });
        }

        const modelLabels = [];
        const modelValues = [];
        if (topModels.length) {
            topModels.forEach((i) => {
                modelLabels.push(i.name);
                modelValues.push(i.total);
            });
        }

        const cityLabels = [];
        const cityValues = [];
        if (topCities.length) {
            topCities.forEach((i) => {
                cityLabels.push(i.name);
                cityValues.push({name: i.name, value: i.total});
            });
        }

        const utmSourceLabels = [];
        const utmSourceValues = [];
        if (topUtmSources.length) {
            topUtmSources.forEach((i) => {
                utmSourceLabels.push(i.name);
                utmSourceValues.push({
                    name: i.name, value: i.total
                });
            });
        }

        const metricByModelAndPlatformCategories = [];
        const brochuresByModelAndPlatform = [];
        const quotesByModelAndPlatform = [];
        const testDrivesByModelAndPlatform = [];
        const lpByModelAndPlatform = [];
        const mediaLeadsByModelAndPlatform = [];
        const ocarzByModelAndPlatform = [];

        if (metricByModelAndPlatforms.length) {
            metricByModelAndPlatforms.forEach((i) => {
                metricByModelAndPlatformCategories.push(i.name);
                brochuresByModelAndPlatform.push(i.brochures);
                quotesByModelAndPlatform.push(i.quotes);
                testDrivesByModelAndPlatform.push(i.testDrives);
                lpByModelAndPlatform.push(i.lp);
                mediaLeadsByModelAndPlatform.push(i.mediaLeads);
                ocarzByModelAndPlatform.push(i.ocarz);
            });
        }

        return (
            <Container className="mt-5 mb-5">

                <WidgetsSectionTitle
                    title="Dashboard"
                    subtitle=""
                    className="mb-4 mt-3"
                    icon="percentage"
                />

                <Row>
                    <Col>
                        {brands.length > 1 && (
                            <Row className={'mb-1 mt-2'}>
                                <Col>
                                    <ButtonGroup className={'mt-3 mb-3 flex-wrap'}>
                                        <Button
                                            color="primary"
                                            className="btn btn-falcon-default btn-sm"
                                            active={selectedBrand === 0}
                                            onClick={() => this.setState({
                                                selectedBrand: 0,
                                                brand: {id: 0, name: '', slug: ''},
                                                brandsQuery: {},
                                                loaded: {...this.state.loaded, brochures: false}
                                            })}
                                        >
                                            All
                                        </Button>

                                        {brands.map(brand => (
                                            <Button
                                                key={brand.id}
                                                className="btn btn-falcon-default btn-sm"
                                                color="primary"
                                                active={selectedBrand === brand.id}
                                                onClick={() => this.setState({
                                                    selectedBrand: brand.id,
                                                    brandsQuery: {key: "brand", value: brand.id},
                                                    brand,
                                                    loaded: {...this.state.loaded, brochures: false},
                                                })}
                                            >
                                                {brand.name}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        )}
                    </Col>

                    <Col>
                        <MDateRangePicker
                            originalDateRange={dateRange}
                            handleDateRangeChange={this.handleDateRangeChange}
                        />
                    </Col>
                </Row>

                <React.Fragment>
                    {loaded.stats && stat.mediaLeadsGrouped ? (
                            <>
                                <Row>
                                    <Col md={4}>
                                        <CardSummary rate="" title="Total Leads" color="primary" bolder={true}>
                                            {stat !== null && stat.totalLeads}
                                        </CardSummary>
                                    </Col>
                                    <Col md={4}>
                                        <CardSummary rate="" title="Total Leads Site Web" color="secondary" bolder={true}>
                                            {stat !== null && stat.websiteLeads}
                                        </CardSummary>
                                    </Col>

                                    <Col md={4}>
                                        <CardSummary rate="" title="Total Leads Externes" color="info" bolder={true}>
                                            {stat !== null && stat.externalLeads}
                                        </CardSummary>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <CardSummary rate="" title="Total Brochures" color="secondary">
                                            {stat !== null && stat.brochures}
                                        </CardSummary>
                                    </Col>
                                    <Col md={4}>
                                        <CardSummary rate="" title="Total Devis" color="info">
                                            {stat !== null && stat.quotes}
                                        </CardSummary>
                                    </Col>
                                    <Col md={4}>
                                        <CardSummary rate="" title="Total Essais" color="warning">
                                            {stat !== null && stat.testDrives}
                                        </CardSummary>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={2}>
                                        <CardSummary rate="" title="LP" color="info">
                                            {stat !== null && stat.mediaLeadsGrouped.website ? stat.mediaLeadsGrouped.website : 0}
                                        </CardSummary>
                                    </Col>

                                    <Col md={2}>
                                        <CardSummary rate="" title="Ocarz" color="primary">
                                            {stat !== null && stat.mediaLeadsGrouped.ocarz ? stat.mediaLeadsGrouped.ocarz : 0}
                                        </CardSummary>
                                    </Col>

                                    <Col md={2}>
                                        <CardSummary rate="" title="Bot Leads" color="warning">
                                            {stat !== null && stat.botLeads}
                                        </CardSummary>
                                    </Col>
                                    <Col md={2}>
                                        <CardSummary rate="" title="Total conversations" color="warning">
                                            {totalconversations[0]}
                                        </CardSummary>
                                    </Col>

                                    <Col md={2}>
                                        <CardSummary rate="" title="Lead Media" color="secondary">
                                            {stat !== null && stat.mediaLeads}
                                        </CardSummary>
                                    </Col>

                                    <Col md={2}>
                                        <CardSummary rate="" title="Total envoyés à webhelp" color="secondary">
                                            {stat !== null && stat.webhelpSent}
                                        </CardSummary>
                                    </Col>
                                </Row>
                            </>
                        ) :
                        <div className={"d-flex justify-content-center"}>
                            <Loader
                                type="Bars"
                                color="#2c7be5"
                                height={100}
                                width={100}
                            />
                        </div>
                    }

                    {/* Top Models + Leads par jour */}
                    {/* Détails Leads */}
                    <WidgetsSectionTitle
                        title="Détails Leads"
                        subtitle=""
                        className="mb-4 mt-3"
                        icon="percentage"
                    />
                    <Row>
                        <Col lg={4} className={'pb-3'}>
                            <Card className="h-100 bg-gradient">
                                <CardBody className="text-white fs--1">
                                    <EChartLeadsByModelAndDay
                                        theme={theme}
                                        title={"Total leads par jour"}
                                        subTitle={""}
                                        data={{labels, values: totalLeads}}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={8} className={'pb-3'}>
                            <Card className="h-100 bg-gradient">
                                <CardBody className="text-white fs--1">
                                    <EChartLineMultiple
                                        theme={theme}
                                        title={"Leads par formulaire"}
                                        subTitle={""}
                                        data={{
                                            categories: labels,
                                            brochures,
                                            testDrives,
                                            quotes,
                                            lp,
                                            ocarz,
                                            mediaLeads,
                                            xs,
                                            labels: [
                                                'Brochure', 'Devis', 'Essai', 'Leads LP', 'Leads Media', 'Leads Ocarz', 'Leads XS'
                                            ]
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Leads par modèle */}
                    {selectedBrand > 0 || brands.length < 2 ? (
                        <Row className={''}>
                            <Col lg={4} className={'pb-3'}>
                                <Card className="h-100 bg-gradient">
                                    <CardBody className="text-white fs--1">
                                        {loaded.metrics ? (
                                            <EchartTotalLeadsByModel
                                                theme={theme}
                                                title={"Total leads par modèle"}
                                                subTitle={""}
                                                data={{labels: modelLabels, values: modelValues}}
                                            />
                                        ) : (
                                            <div className={"d-flex justify-content-center"}>
                                                <Loader
                                                    type="Bars"
                                                    color="#2c7be5"
                                                    height={100}
                                                    width={100}
                                                />
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={8} className={'pb-3'}>
                                <Card className="h-100 bg-gradient">
                                    <CardBody className="text-white fs--1">
                                        {loaded.metrics ? (
                                            <LeadsByModelAndPlatformChart
                                                theme={theme}
                                                title={"Leads par modèle"}
                                                subTitle={""}
                                                data={{
                                                    categories: metricByModelAndPlatformCategories,
                                                    quotes: quotesByModelAndPlatform,
                                                    brochures: brochuresByModelAndPlatform,
                                                    testDrives: testDrivesByModelAndPlatform,
                                                    lp: lpByModelAndPlatform,
                                                    mediaLeads: mediaLeadsByModelAndPlatform,
                                                    ocarz: ocarzByModelAndPlatform,
                                                }}
                                            />
                                        ) : <div className={"d-flex justify-content-center"}>
                                            <Loader
                                                type="Bars"
                                                color="#2c7be5"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) : (<React.Fragment/>)}

                    {/* Source de Leads */}
                    <WidgetsSectionTitle
                        title="Source de Leads"
                        subtitle=""
                        className="mb-4 mt-3"
                        icon="percentage"
                    />
                    {/* Leads par showroom */}
                    {user && user.roles && (user.roles.includes('ROLE_SUPER_ADMIN')
                        || user.roles.includes('ROLE_ADMIN')) && (
                        <Row>
                            <Col className={'pb-3'}>
                                <Card className="h-100 bg-gradient">
                                    <CardBody className="text-white fs--1">
                                        {loaded.topShowrooms ? (
                                            <EchartTotalLeadsByShowroom
                                                theme={theme}
                                                title={"Total leads par showroom"}
                                                subTitle={""}
                                                data={{labels: showroomLabels, values: showroomValues}}
                                            />
                                        ) : <div className={"d-flex justify-content-center"}>
                                            <Loader
                                                type="Bars"
                                                color="#2c7be5"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    )}

                    <Row className={''}>
                        {/* Leads par ville */}
                        <Col lg={6} className={'pb-3'}>
                            <Card className="h-100 bg-gradient">
                                <CardBody className="text-white fs--1">
                                    {loaded.topCities ? (
                                        <EChartLeadsByCity
                                            theme={theme}
                                            title={"Leads par ville"}
                                            subTitle={""}
                                            data={{labels: cityLabels, values: cityValues}}
                                        />
                                    ) : <div className={"d-flex justify-content-center"}>
                                        <Loader
                                            type="Bars"
                                            color="#2c7be5"
                                            height={100}
                                            width={100}
                                        />
                                    </div>}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} className={'pb-3'}>
                            <Card className="h-100 bg-gradient">
                                <CardBody className="text-white fs--1">
                                    {loaded.topUtmSources ? (
                                        <EChartLeadsBySource
                                            theme={theme}
                                            title={"Source des leads"}
                                            subTitle={""}
                                            data={{labels: utmSourceLabels, values: utmSourceValues}}
                                        />
                                    ) : <div className={"d-flex justify-content-center"}>
                                        <Loader
                                            type="Bars"
                                            color="#2c7be5"
                                            height={100}
                                            width={100}
                                        />
                                    </div>}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        {/* Abandons par jour */}
                        {user && user.roles && (user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN')) && (
                            <Col lg={6} className={'pb-3'}>
                                <Card className="h-100 bg-gradient">
                                    <CardBody className="text-white fs--1">
                                        {loaded.metrics ? (
                                            <EChartLineHeat
                                                theme={theme}
                                                title={"Abandonnés par jour"}
                                                subTitle={""}
                                                data={{labels, values: abandons}}
                                            />
                                            ) :
                                            <div className={"d-flex justify-content-center"}>
                                                <Loader
                                                    type="Bars"
                                                    color="#2c7be5"
                                                    height={100}
                                                    width={100}
                                                />
                                            </div>}
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </React.Fragment>

            </Container>
        );
    }
}

export default Home;
