import http from "../../services/http";

               
class ApiAshnooHelper {
    currentEndPoint = "https://api.ashnoo.ai/";

    constructor(path) {
        this.currentEndPoint = "https://api.ashnoo.ai".concat(`${path}`);
    }
    fetchApi =  async (uri) => {
        
        try {
            var myHeaders = new Headers();
            var configHeader = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };


          const response = await fetch(uri,configHeader);         
          if (!response.ok) {
       
            throw new Error(`Error! status: ${response.status}`);
          }
      
          const result = await response.json();
          return result;
        } catch (err) {
          console.log(err);
        }
      }

    findAll() {
        
        return this.fetchApi();
    }

    findBy(params) {
        const query = params
            .reduce((acc, p) => acc + `${p.key}=${p.value}&`, "?parent=fca&")
            .slice(0, -1);

        const uri = this.currentEndPoint + query;

        //console.log('uri', uri);

        return this.fetchApi(uri)
    }

    create(body) {
        return http.post(this.currentEndPoint, body);
    }

    findOne(iri) {
        return http.get(http.endPoint + iri);
    }

    update(iri, body) {
        console.log('update body', body);
        return http.put(http.endPoint + iri, body);
    }

    patch(iri, body) {
        return http.patch(http.endPoint + iri, body);
    }

    remove(iri) {
        return http.delete(http.endPoint + iri);
    }
}

export default ApiAshnooHelper;
