import React, {Component} from "react";
import queryString from "query-string";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {toast} from "react-toastify";
import DateDisplay from "../../../components/DateDisplay";
import {Dropdown} from "react-bootstrap";
import {FaEllipsisH, FaEye} from "react-icons/all";
import {Link} from "react-router-dom";
import Table from "../../../components/Table";
import {getDate} from "../../../utils/utils";
import {sourceOptions} from "../../../constant/leadSources";

class LeadList extends Component {
    state = {
        selectedCampaign: null,
        loading: true,
        leadSubmissions: [],
        total: 0,
        currentPage: 1,
        pageSize: 30,
        brand: null,
        models: [],
        campaigns: [],
        spreadsheetDataImports: [],
        selectedSpreadsheetDataImport: [],
        selectedModel: null,
        type: null,
        selectedPlatform: null,
        selectedLead: null,
        searchQuery: "",
        sortColumn: {path: "dateSorted", order: "desc"},
        selectedStatus: null,
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()],
        search: {}
    };

    constructor(props) {
        super(props);

    
    }



    componentDidMount() {
        //this.getModels();
        this.getModels();
        this.getRequests();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;
       

        let params = queryString.parse(location.search);

        if (prevProps.location.key !== location.key) {
            //this.getModels();
            this.getRequests();
        }


        console.log('prevState.dateRange', prevState.dateRange);
        console.log('state.dateRange', this.state.dateRange);

        console.log('ttt',this.state)


        if ((this.state.dateRange === null)
            || (!prevState.dateRange || (
                prevState.dateRange[0] !== this.state.dateRange[0]
                || prevState.dateRange[1] !== this.state.dateRange[1]))
            || (this.state.selectedPlatform !== prevState.selectedPlatform)
            || (this.state.selectedCampaign !== prevState.selectedCampaign)
            || (this.state.selectedStatus !== prevState.selectedStatus)
            || (this.state.currentPage !== prevState.currentPage)
            || (this.state.search !== prevState.search)
            || (this.state.selectedSpreadsheetDataImport !== prevState.selectedSpreadsheetDataImport)
            || (this.state.selectedModel !== prevState.selectedModel)
        ) {
            this.getRequests();
        }
    }

    

    getRequests = async () => {

        this.setState({loading: true});
        const {dateRange, currentPage, search, selectedModel, selectedPlatform, selectedCampaign ,type , selectedStatus, selectedSpreadsheetDataImport, models} = this.state;

        const {location} = this.props;

        let params = queryString.parse(location.search);

        const apiHelper = new ApiResourceHelper(`/leads`);


        const queryParams = this.getFilters(params.brand_id, params.type, selectedModel, selectedPlatform, selectedCampaign, dateRange, currentPage, search, selectedStatus, selectedSpreadsheetDataImport);



        const {data} = await apiHelper.findBy(queryParams);

        console.log('this para data',data)


        const leadSubmissions = data["hydra:member"];



        const finalRequests = [];
        leadSubmissions.forEach((r) => {
            const date = new Date(r.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalRequests.push({
                ...r, dateSorted, nameSorted: `${r.firstName} ${r.lastName}`
            });
        });

        this.setState({leadSubmissions: finalRequests, total: data["hydra:totalItems"], loading: false});
    };

    getFilters(brandId, type, selectedModel, selectedPlatform, selectedCampaign, dateRange, currentPage, search, webHelpStatus, selectedSpreadsheetDataImport) {
        const queryParams = [];

        
        if (brandId) {
            queryParams.push({key: "lead.model.brand", value: brandId});
        }

        if (selectedPlatform) {
            queryParams.push({key: "platform", value: selectedPlatform});
        }
        console.log('selectedModel:', selectedModel)
        if (selectedModel) {
            queryParams.push({key: "lead.model", value: selectedModel});
        }

        if (type) {
            queryParams.push({key: "type", value: type});
        }

        if (webHelpStatus) {
            queryParams.push({key: 'latestItemLog.status', value: webHelpStatus});
        }

        if (selectedCampaign) {
            queryParams.push({key: 'lead', value: selectedCampaign});
        }
        if (selectedSpreadsheetDataImport) {
            queryParams.push({key: 'spreadsheetSource', value: selectedSpreadsheetDataImport});
        }

        if (dateRange && dateRange[0]) {
            queryParams.push({key: 'createdAt%5Bafter%5D', value: getDate(dateRange[0])});
        }

        if (dateRange && dateRange[1]) {
            queryParams.push({key: 'createdAt%5Bbefore%5D', value: `${getDate(dateRange[1])} 23:59`});
        }

        if (currentPage) {
            queryParams.push({key: 'page', value: currentPage});
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                if (filter[1] !== '') {
                    queryParams.push({key: filter[0], value: filter[1]})
                }
            })
        }
        return queryParams;
    }

    getExportFilters(brandId,type, selectedModel, selectedPlatform, selectedCampaign, dateRange, currentPage, search, selectedSpreadsheetDataImport) {
        const queryParams = {};

        if(type) {
            queryParams['type'] = type; 
        }
        
        if (brandId) {
            queryParams['brand'] = brandId;
        }

        if (selectedPlatform) {
            queryParams['platform'] = selectedPlatform;
        }

        if (selectedModel) {

            if(selectedModel !== '---')
            queryParams['model'] = selectedModel.match(/\d+/)[0];
        }

        if (selectedSpreadsheetDataImport) {
            queryParams['spreadsheet_data_import'] = selectedSpreadsheetDataImport;
        }

        if (selectedCampaign && selectedCampaign !== "---" ) {
            queryParams['campaign'] = selectedCampaign;
        }

        if (dateRange && dateRange[0]) {
            queryParams['from'] = getDate(dateRange[0]);
        }

        if (dateRange && dateRange[1]) {
            queryParams['to'] = `${getDate(dateRange[1])} 23:59`;
        }

        if (currentPage) {
            queryParams['page'] = currentPage;
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams[filter[0]] = filter[1];
            });
        }
        return queryParams;
    }

    ;

 

    getModels = async () => {
        try {

            const {location} = this.props;
            let params = queryString.parse(location.search);

            const apiPath = `/models?brand=/brands/${params.brand_id}`;
            
            const helper = new ApiResourceHelper(apiPath);
            
            const {data} = await helper.findAll();

            const modelsList = data['hydra:member'];

            this.setState({models: modelsList});

            
        } catch (ex) {};
    };



    handleDelete = async (request) => {
        const originalRequests = this.state.leadSubmissions;

        const leadSubmissions = originalRequests.filter((b) => b.id !== request.id);
        this.setState({leadSubmissions});

        try {
            await this.apiHelper.remove(`${this.apiPath}/${request.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This request has already been removed.");

            this.setState({leadSubmissions: originalRequests});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    handleDateRangeChange = (dateRange) => {
        console.log("dateRange", dateRange);

        this.setState({
            searchQuery: "",
            currentPage: 1,
            dateRange
        });
    }

    handleSearchChange = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    genders = {
        mister: 'Homme',
        lady: 'Femme',
        miss: 'Demoiselle',
    };

    columns = [
        {
            path: "dateSorted",
            label: "Date",
            content: (request) => (
                <DateDisplay date={request.createdAt}/>
            ),
        },
        {
            path: "gender",
            label: "Civilité",
            content: (request) => (<span>{this.genders[request.gender]}</span>)
        },
        {
            path: "email",
            label: "Email",
        },
        {
            path: 'nameSorted',
            content: (request) => <React.Fragment>{request.firstName} {request.lastName}</React.Fragment>,
            label: "Nom"
        },
        {
            path: 'telephone',
            label: "Téléphone",
            content: (request) => <React.Fragment>
                {request.telephone}{request.telephoneSecondary && `/${request.telephoneSecondary}`}
            </React.Fragment>,
        },
        {
            path: 'utmCampaign',
            label: "UTM Campaign",
        },
        {
            path: 'utmSource',
            label: "UTM Source",
        },
        {
            path: 'utmMedium',
            label: "UTM Medium",
        },
        {
            label: "webhelp status",
            content: (request) => (
                request.latestItemLog && request.latestItemLog.status === "ok"
                    ? <span className="badge badge-success">OK</span> :
                    request.latestItemLog && request.latestItemLog.status === "error" ?
                        <span className="badge badge-danger">ERROR</span> :
                        <span className="badge badge-warning">not processed or no log available</span>
            )
        },
        {
            key: "actions",
            content: (request) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="transparent"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}>
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link to={`/leads/${request.id}`}
                                      className={"btn btn-light btn-block shadow-sm text-left"}>
                                    <FaEye/> Voir
                                </Link>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    getCampaigns = async (source) => {
        try {
            const apiPath = "/campaigns";
            const helper = new ApiResourceHelper(apiPath);
            const params = [];

            const {location} = this.props;
            const urlParams = queryString.parse(location.search);
            params.push({key: "model.brand", value: urlParams.brand_id});

            if (source) {

                params.push({key: "source", value: source === 'website' ? 'lp':source});
            }

            const {data} = await helper.findBy(params);
            this.setState({campaigns: data["hydra:member"]});
        } catch (ex) {
        }
    };

    getSpreadsheetDataImports = async (campaign) => {
        if (null === campaign) return;
        try {
            const apiPath = "/spreadsheet_data_imports";
            const helper = new ApiResourceHelper(apiPath);
            const params = [];

            params.push({key: "campaign", value: campaign});

            const {data} = await helper.findBy(params);
            this.setState({spreadsheetDataImports: data["hydra:member"]});
        } catch (ex) {
            console.error(ex)
        }
    };

    getCampaignList = () => {
        const {campaigns} = this.state;

        const campaignList = [];
        campaigns.map((campaign) => {
            campaignList.push({
                key: `${campaign.title}`,
                value: `/campaigns/${campaign.id}`,
            });

            return true;
        });


        return campaignList;

    
    };

    getModelsList = () => {
       const {models} = this.state;
       console.log('log', models)

       const modelsList = [];

       models.map((models) => {       
            modelsList.push({
               key: `${models.name}`,
               value: `/models/${models.id}`,
            });

           return true;
       });

       console.log('modelsList',modelsList)

       return modelsList;
    };

    getSpreadsheetDataImportList = () => {
        const {spreadsheetDataImports} = this.state;

        const spreadsheetDataImportList = [];

        spreadsheetDataImports.map((spreadsheetDataImport) => {
            spreadsheetDataImportList.push({
                key: `${spreadsheetDataImport.document ? spreadsheetDataImport.document.source : spreadsheetDataImport.importedAt}`,
                value: `/spreadsheet_data_imports/${spreadsheetDataImport.id}`,
            });

            return true;
        });

        return spreadsheetDataImportList;
    };

    onPlatformChange = ( event ) => {
        console.log('event.target.value', event.target.value);
        //const value = event.target.value !== '' ? event.target.value : null;
        this.setState({selectedPlatform: event.target.value});
        this.getCampaigns(event.target.value);
    }

    onCampaignChange = ( event ) => {
        this.setState({selectedCampaign: event.target.value});
        this.getSpreadsheetDataImports(event.target.value);
    }

    onModelChange = ( event ) => {
        this.setState({selectedModel: event.target.value});
    }

    onSpreadsheetDataImportChange = ( event ) => {
        this.setState({selectedSpreadsheetDataImport: event.target.value});
    }

    setPage = (page) => {
        this.setState({currentPage: page});
    }

    onTextChange = ( event ) => {

        console.log('on.event', event);
        this.setState({search: {...this.state.search, [event.name]: event.value}});
       
        console.log('on.search', Object.entries(this.state.search));
    }

    onStatusChange = (event) => {
        this.setState({selectedStatus: event.target.value});
    }

    render() {
        const {leadSubmissions, total, currentPage, pageSize, sortColumn, dateRange, loading, search, selectedModel, selectedPlatform, selectedCampaign,selectedSpreadsheetDataImport} = this.state;

        const {location} = this.props;

        let params = queryString.parse(location.search);

        let title = 'Leads Médias';
        let tableName = 'leads';

        console.warn('params', params.type);

        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={leadSubmissions}
                    //exportedData={this.getExportedData()}
                    exportedData={[]}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    tableName={tableName}
                    tableTitle={title}
                    searchQuery={this.state.searchQuery}
                    onSearchChange={this.handleSearchChange}
                    totalCount={total}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    dateRange={dateRange}
                    handleDateRangeChange={this.handleDateRangeChange}
                    loading={loading}
                    extraFilters={[
                        /*{name: "model", label: "Modèle", options: [{key: "Modèle", value: "---"}].concat(this.getModelList()), onChange: this.onModelChange},*/
                        {name: "platform", type: "select", label: false, options: sourceOptions, onChange: this.onPlatformChange},
                        {
                            name: "status",
                            type: "select",
                            label: false,
                            options: [{key: "webhelpStatus", value: ""},
                                {key: "Success", value: "ok"},
                                {key: "Error", value: "error"},],
                            onChange: this.onStatusChange
                        },
                        {name: "campaign", type: "select", label: false, options: [{key: "Campagne", value: "---"}].concat(this.getCampaignList()), onChange: this.onCampaignChange},
                        {name: "models", type: "select", label: false, options: [{key: "Models", value: "---"}].concat(this.getModelsList()), onChange: this.onModelChange},
                        {name: "spreadsheetDataImport", type: "select", label: false, options: [{key: "Injection", value: "---"}].concat(this.getSpreadsheetDataImportList()), onChange: this.onSpreadsheetDataImportChange},
                        {name: "email", type: "text", label: "Email", onChange: this.onTextChange},
                        {name: "firstName", type: "text", label: "First name", onChange: this.onTextChange},
                        {name: "lastName", type: "text", label: "Last name", onChange: this.onTextChange},
                        {name: "telephone", type: "text", label: "Téléphone", onChange: this.onTextChange},
                        {name: "utmCampaign", type: "text", label: "UTM Campaign", onChange: this.onTextChange},
                    ]}
                    exportEndpoint={"/lead_exports"}
                    exportFilename={"lead_medias.csv"}
                    exportFilters={this.getExportFilters(params.brand_id,params.type, selectedModel, selectedPlatform, selectedCampaign, dateRange, currentPage, search, selectedSpreadsheetDataImport)}
                />
            </React.Fragment>
        );
    }
}

export default LeadList;
