import React, {Component} from 'react';
import ReactECharts from "echarts-for-react";
import {themeColors} from "../../../falcon/src/helpers/utils";

class EchartTotalLeadsByModel extends Component {
    render() {
        const {title, subTitle, data, theme} = this.props;
        console.log('EchartTotalLeadsByModel.data', data);

        const option = {
            title: {
                text: title,
                subtext: subTitle,
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                textStyle: { color: '#ffffff' },
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: {show: false},
                    axisLabel: {rotate: 30, interval: 0, textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' }},
                    axisLine: {show: false},
                    data: data.labels,
                    z: 10
                },

            ],
            yAxis: {
                type: 'value',
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                axisLine: {show: false},
                axisLabel: {textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' }},
            },
            series: [
                {
                    type: 'bar',
                    barGap: 0,
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.values
                }
            ],
            grid: { containLabel: true },
        };

        return <ReactECharts
            option={option}
            style={{height: 420}}
            theme={theme}
        />;
    }
}

export default EchartTotalLeadsByModel;