import React, {Component} from "react";
import {FaSortDown, FaSortUp} from "react-icons/all";

class Header extends Component {
    raiseSort = (path) => {
        const {sortColumn, onSort} = this.props;

        if (sortColumn.path) {
            if (sortColumn.path === path) {
                sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
            } else {
                sortColumn.path = path;
                sortColumn.order = "asc";
            }

            onSort(sortColumn);
        }
    };

    renderSortIcon = (column) => {
        const {sortColumn} = this.props;

        if (column.path !== sortColumn.path) return null;

        if (sortColumn.order === "asc") return <FaSortUp className={"text-muted"} />; return <FaSortDown className={"text-muted"} />;
    };

    render() {
        const {columns} = this.props;

        return (
            <thead>
            <tr className="bg-200 text-900 border-y border-200">
                {columns.map(column => (
                    <th
                        key={column.path || column.key}
                        onClick={() => {
                            if (column.path) this.raiseSort(column.path)
                        }}
                        className="clickable border-0"
                    >
                        {column.label} {this.renderSortIcon(column)}
                    </th>
                ))}
            </tr>
            </thead>
        );
    }
}

export default Header;
