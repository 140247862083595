import React, {Component} from "react";
import Joi from "joi-browser";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import Form from "../../../components/Form";
import imageStyles from "../../../assets/styles/image.module.css";
import Input from "../../../components/Form/Input";
import {FaUpload} from "react-icons/all";
import {Row} from "react-bootstrap";
import {sources, sourceOptions} from "../../../constant/leadSources";

class LeadGenEdit extends Form {
    state = {
        data: {
            source: "",
            brand: "",
            model: "",
            campaign: "",
            document: "",
        },
        errors: {},
        selectedFile: null,

        brands: [],
        models: [],
        campaigns: [],

        selectedDocument: {},
        selectedBrand: null,
        selectedModel: null,
        selectedCampaign: null,

        leadGen: {}
    };

    apiPath = "/spreadsheet_data_imports";

    schema = {
        source: Joi.string().required().min(2).max(30).label("Source"),
        //brand: Joi.string().required().regex(/^\/brands\/\d*$/i).label("Marque"),
        brand: Joi.any().when('source', { is: "fb", then: Joi.string().required().regex(/^\/brands\/\d*$/i).label("Marque"), otherwise: Joi.optional() }),
        model: Joi.any().when('source', { is: "fb", then: Joi.string().required().regex(/^\/models\/\d*$/i).label("Modèle"), otherwise: Joi.optional() }),
        campaign: Joi.any().when('source', { is: "fb", then: Joi.string().required().regex(/^\/campaigns\/\d*$/i).label("Campagne"), otherwise: Joi.optional() }),
        document: Joi.string().required().regex(/^\/documents\/\d*$/i).label("Document"),
    };

    LeadGenHelper;

    constructor(props) {
        super(props);
        this.LeadGenHelper = new ApiResourceHelper(this.apiPath);
    }

    componentDidMount = () => {
        this.getBrands();
    };

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    getBrands = async () => {
        try {
            const apiPath = "/brands";
            const helper = new ApiResourceHelper(apiPath);
            const {data} = await helper.findAll();
            this.setState({brands: data["hydra:member"]});
        } catch (ex) {
        }
    };

    getBrandList = () => {
        const {brands} = this.state;

        const brandList = [];
        brands.map((brand) => {
            brandList.push({
                key: `${brand.name}`,
                value: brand["@id"],
            });

            return true;
        });

        return brandList;
    };

    getModels = async (brandIri) => {
        try {
            const apiPath = "/models";
            const helper = new ApiResourceHelper(apiPath);
            const {data} = await helper.findBy([{key: "brand", value: brandIri}]);
            this.setState({models: data["hydra:member"]});
        } catch (ex) {
        }
    };

    getModelList = () => {
        const {models} = this.state;

        const modelList = [];
        models.map((model) => {
            modelList.push({
                key: `${model.name}`,
                value: model["@id"],
            });

            return true;
        });

        return modelList;
    };

    getCampaigns = async (modelIri) => {
        try {
            const apiPath = "/campaigns";
            const helper = new ApiResourceHelper(apiPath);
            const {data} = await helper.findBy([
                {key: "model", value: modelIri},
                {key: "source", value: this.state.data.source}
            ]);
            this.setState({campaigns: data["hydra:member"]});
        } catch (ex) {
        }
    };

    getCampaignList = () => {
        const {campaigns} = this.state;

        const campaignList = [];
        campaigns.map((campaign) => {
            campaignList.push({
                key: `${campaign.title}`,
                value: campaign["@id"],
            });

            return true;
        });

        return campaignList;
    };

    onFileChange = async (e) => {
        console.log('document file change');
        const selectedFile = e.target.files[0];
        const formData = new FormData();

        formData.append("file", selectedFile, selectedFile.name);

        const apiHelper = new ApiResourceHelper("/documents");

        try {
            console.log('uploading document');
            const {data: selectedDocument} = await apiHelper.create(formData);

            const {data} = this.state;
            data.document = `/documents/${selectedDocument.id}`;

            this.setState({data, selectedDocument});
        } catch (ex) {
            console.log('upload_ex', ex);
        }

    };

    onBrandSelect = (brandIri) => {
        this.getModels(brandIri);
    };

    onModelSelect = (modelIri) => {
        this.getCampaigns(modelIri);
    };

    doSubmit = async () => {
        console.log("Submitted");

        const {data: originalData} = this.state;

        const data = {
            document: `/documents/${this.state.selectedDocument.id}`,
            source: originalData.source
        };

        if (sources.includes(originalData.source)) {
            data.campaign = originalData.campaign
        }

        const helper = new ApiResourceHelper("/spreadsheet_data_imports");
        const {data: leadGen} = await helper.create(data);
        this.setState({leadGen});

        //this.getPrevious();
        console.log('Facebook Leads created.');

        this.props.history.replace(`/lead_gens/${leadGen.id}`);
    };

    render() {
        const {selectedDocument: document, errors, data} = this.state;
        return (
            <div className={"no-gutters row mt-lg-5"}>

                <div className={"pr-lg-2 col-lg-8"}>

                    <div className={"mb-3 card"}>
                        <div className={"card-header"}>
                            <h5 className="mb-0">Ajout d'un lead gen</h5>
                        </div>
                        <div className={"bg-light card-body"}>
                            <form onSubmit={this.handleSubmit}>

                                <Row>
                                    <div className="col-lg-12">
                                        <div className={"form-group"}>
                                            {this.renderSelect("source", "Source", sourceOptions)}
                                        </div>
                                    </div>
                                </Row>

                                {sources.includes(data.source) && (
                                    <React.Fragment>
                                        <Row>
                                            <div className="col-lg-12">
                                                <div className={"form-group"}>
                                                    {this.renderSelect("brand", "Marque", [{
                                                        key: "Séléctionnez la marque",
                                                        value: ""
                                                    }].concat(this.getBrandList()), this.onBrandSelect)}
                                                </div>
                                            </div>
                                        </Row>

                                        <Row>
                                            <div className="col-lg-12">
                                                <div className={"form-group"}>
                                                    {this.renderSelect("model", "Modèle", [{
                                                        key: "Séléctionnez le modèle",
                                                        value: ""
                                                    }].concat(this.getModelList()), this.onModelSelect)}
                                                </div>
                                            </div>
                                        </Row>

                                        <Row>
                                            <div className="col-lg-12">
                                                <div className={"form-group"}>
                                                    {this.renderSelect("campaign", "Campagne", [{
                                                        key: "Séléctionnez la campagne",
                                                        value: ""
                                                    }].concat(this.getCampaignList()))}
                                                </div>
                                            </div>
                                        </Row>
                                    </React.Fragment>
                                )}

                                <Row>
                                    <div className={"col-lg-12"}>
                                        <label>Document CSV</label>
                                        <div className={"form-group bg-white border border-secondary"}>
                                            <label
                                                className={`${imageStyles['btn']} text-center btn-block py-3`}>
                                                Télécharger un document CSV
                                                <Input label={<FaUpload/>} type="file" name="image"
                                                       onChange={this.onFileChange}
                                                       customStyle={{display: 'none'}}
                                                       containerCustomStyle={{marginBottom: 0}}
                                                       labelCustomStyle={{fontSize: '1.5rem'}}
                                                       accept={"text/csv"}/>

                                                {document && document.source && (
                                                    <span>{document.source}</span>
                                                )}
                                            </label>
                                        </div>
                                        {errors.document &&
                                        <div className="alert alert-danger">{errors.document}</div>}
                                    </div>
                                </Row>

                                <div className="btn-group">
                                    {/*<button onClick={() => history.goBack()} className="btn btn-secondary mt-3">
                                            Annuler
                                        </button>*/}
                                    {this.renderButton("Sauvegarder")}
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeadGenEdit;

