import React, {Component} from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";

class UserShow extends Component {
    state = {
        user: {},
        errors: {},
        loaded: false,
    };

    apiPath = "/users";

    userHelper;

    roles = {
        'ROLE_BRAND': 'Brand',
        'ROLE_ADMIN': 'Admin',
        'ROLE_SUPER_ADMIN': 'Super admin'
    };

    constructor(props) {
        super(props);
        this.userHelper = new ApiResourceHelper(this.apiPath);
    }

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    componentDidMount = () => {
        this.getUser();
    };

    getUser = async () => {
        try {
            const id = this.props.match.params.id;
            const {data: user} = await this.userHelper.findOne(
                `${this.apiPath}/${id}`
            );

            this.setState({user, loaded: true});
        } catch (ex) {
        }
    };

    render() {
        const {user, loaded} = this.state;

        console.log("user", user);

        if (loaded) {
            return (
                <div className="card" style={{width: "100%"}}>
                    <div className="card-body">
                        <h5 className="card-title">{user.name}</h5>

                        <table className="table">
                            <tbody>
                            <tr>
                                <th scope="col">Prénom</th>
                                <td>{user.firstName}</td>
                            </tr>
                            <tr>
                                <th scope="col">Nom</th>
                                <td>{user.lastName}</td>
                            </tr>
                            <tr>
                                <th scope="col">Email</th>
                                <td>{user.email}</td>
                            </tr>
                            <tr>
                                <th scope="col">Role</th>
                                <td>{this.roles[user.roles[0]]}</td>
                            </tr>

                            </tbody>
                        </table>

                        <a href={`/users/${user.id}/edit`} className="btn btn-primary">
                            Editer utilisateur
                        </a>
                    </div>
                </div>
            );
        }

        return null;
    }
}

export default UserShow;
