import React, {Component} from "react";
import Joi from "joi-browser";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import Form from "../../../components/Form";

class UserEdit extends Form {
  state = {
    data: {
      email: "",
      firstName: "",
      lastName: "",
      plainPassword: "",
      roles: "",
    },
    errors: {},
  };

  apiPath = "/users";

  emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  schema = {
    email: Joi.string().required().email().max(120).label("Email"),
    firstName: Joi.string().required().min(2).max(30).label("Prénom"),
    lastName: Joi.string().required().min(2).max(30).label("Nom"),
    plainPassword: Joi.string().allow(null, '').min(6).max(30).label("Mot de passe"),
    roles: Joi.string().required().min(2).max(30).label("Role"),
  };

  userHelper;

  constructor(props) {
    super(props);
    this.userHelper = new ApiResourceHelper(this.apiPath);
  }

  getPrevious = () => {
    const {history} = this.props;
    history.goBack();
  }

  componentDidMount = () => {
    this.populateUser();
  };

  populateUser = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: user } = await this.userHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("user", user);

      this.setState({ data: this.mapToViewUser(user) });
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;

    const {data} = this.state;
    data.roles = [data.roles];
    console.log('data', data);

    if (data.plainPassword === "") {
      console.log('delete plainPassword from post');
      delete data.plainPassword;
    }

    console.log('data with password removed', data);

    await this.userHelper.update(`${this.apiPath}/${id}`, data);
    this.props.history.replace("/users");
  };

  mapToViewUser = (user) => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      roles: user.roles[0],
    };
  };

  render() {
    return (
        <div className={"no-gutters row mt-lg-5"}>

          <div className={"pr-lg-2 col-lg-8"}>

            <div className={"mb-3 card"}>
              <div className={"card-header"}>
                <h5 className="mb-0">Modification d'utilisateur</h5>
              </div>
              <div className={"bg-light card-body"}>

                <form onSubmit={this.handleSubmit}>
                  {this.renderInput("email", "email", "Email")}
                  {this.renderInput("lastName", "text", "Nom")}
                  {this.renderInput("firstName", "text", "Prénom")}
                  {this.renderInput("plainPassword", "password", "Mot de passe")}
                  {this.renderSelect("roles", "Role", [
                    {key: "Lead", value: "ROLE_LEAD"},
                    {key: "Brand", value: "ROLE_BRAND"},
                    {key: "Admin", value: "ROLE_ADMIN"},
                    {key: "Super Admin", value: "ROLE_SUPER_ADMIN"},
                  ])}
                  {this.renderButton("Sauvegarder")}
                </form>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default UserEdit;

