import React from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {Row} from "react-bootstrap";

class BrandCreate extends Form {
    state = {
        data: {
            name: "",
        },
        errors: {},
    };

    apiPath = "/brands";

    schema = {
        name: Joi.string().required().min(2).max(30).label("Name"),
        shouldClientConsent: Joi.boolean().required().label("Confirmation du contact")
    };

    brandHelper;

    constructor(props) {
        super(props);
        this.brandHelper = new ApiResourceHelper(this.apiPath);
    }

    doSubmit = async () => {
        console.log("Submitted");

        await this.brandHelper.create(this.state.data);
        this.props.history.replace("/brands");
    };

    render() {
        return (
            <div className={"no-gutters row mt-lg-5"}>

                <div className={"pr-lg-2 col-lg-8"}>

                    <div className={"mb-3 card"}>
                        <div className={"card-header"}>
                            <h5 className="mb-0">{this.state.formTitle}</h5>
                        </div>
                        <div className={"bg-light card-body"}>
                            <form onSubmit={this.handleSubmit}>

                                <Row>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            {this.renderInput("name", "text", "Name")}
                                        </div>
                                    </div>
                                </Row>

                                <Row>
                                    <div className="col-lg-12">
                                        <div className={"form-group"}>
                                            {this.renderSelect("shouldClientConsent", "Le client doît confirmer avant d'être contacté?", [
                                                {key: "Choisir une option", value: null},
                                                {key: "Non", value: false},
                                                {key: "Oui", value: true}
                                            ])}
                                        </div>
                                    </div>
                                </Row>

                                {this.renderButton("Save")}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BrandCreate;
