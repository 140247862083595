import http from "../../services/http";

class ApiResourceHelper {
    currentEndPoint = "";

    constructor(path) {
        this.currentEndPoint = `${http.endPoint}${path}`;
    }

    findAll() {
        return http.get(this.currentEndPoint);
    }

    findBy(params) {
        const query = params
            .reduce((acc, p) => acc + `${p.key}=${p.value}&`, "?")
            .slice(0, -1);

        const uri = this.currentEndPoint + query;

        //console.log('uri', uri);

        return http.get(uri);
    }

    create(body) {
        return http.post(this.currentEndPoint, body);
    }

    findOne(iri) {
        return http.get(http.endPoint + iri);
    }

    update(iri, body) {
        console.log('update body', body);
        return http.put(http.endPoint + iri, body);
    }

    patch(iri, body) {
        return http.patch(http.endPoint + iri, body);
    }

    remove(iri) {
        return http.delete(http.endPoint + iri);
    }
}

export default ApiResourceHelper;
