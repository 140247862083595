import Joi from "joi-browser";
import {Component} from "react";

class InformationRequestEdit extends Component{
  componentDidMount = () => {
    this.populatePrePurchaseRequest();
  };

  populatePrePurchaseRequest = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: prePurchaseRequest } = await this.prePurchaseRequestHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("prePurchaseRequest", prePurchaseRequest);

      this.setState({ data: this.mapToViewPrePurchaseRequest(prePurchaseRequest) });

      console.log('arrived here');
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.prePurchaseRequestHelper.update(`${this.apiPath}/${id}`, this.state.data);
    this.props.history.replace("/pre_purchase_requests");
  };

  mapToViewPrePurchaseRequest = (prePurchaseRequest) => {
    return {
      id: prePurchaseRequest.id,
      name: prePurchaseRequest.name,
      email: prePurchaseRequest.email,
      telephone: prePurchaseRequest.telephone,
    };
  };
}

export default InformationRequestEdit;
