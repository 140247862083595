import React, { Component } from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import RequestDetail from "../../../components/App/RequestDetail";
import Date from "../../../components/DateTimeDisplay";

class XSLeadShow extends Component {
  state = {
    lead: {},
    errors: {},
    loaded: false,
  };

  apiPath = "/xs_leads";

  leadHelper;

  constructor(props) {
    super(props);
    this.leadHelper = new ApiResourceHelper(this.apiPath);
  }

  componentDidMount = () => {
    this.getlead();
  };

  getlead = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: lead } = await this.leadHelper.findOne(
        `${this.apiPath}/${id}`
      );

      this.setState({ lead, loaded: true });
    } catch (ex) {}
  };

  getPrevious = () => {
    const {history} = this.props;
    history.goBack();
  }

  render() {
    const { lead, loaded } = this.state;

    console.log("lead", lead);

    if (loaded) {
      return (
          <div className="card" style={{width: "100%"}}>
            <div className="card-body">
              <h5 className="card-title" style={{textTransform: "capitalize"}}>Fiche de Lead</h5>
              <table className="table">
                <tbody>
                <tr>
                  <th scope="col">Date</th>
                  <td>
                    <Date date={lead.createdAt}/>
                  </td>
                </tr>
                <tr>
                  <th scope="col">Civilité</th>
                  <td>{lead.gender}</td>
                </tr>

                <tr>
                  <th scope="col">Nom</th>
                  <td>{lead.firstName} {lead.lastName}</td>
                </tr>

                <tr>
                  <th scope="col">Email</th>
                  <td>{lead.email}</td>
                </tr>
                {/*{form !== "brochure" && (*/}
                <tr>
                  <th scope="col">Téléphone</th>
                  <td>{lead.telephone}</td>
                </tr>
                {/* )}*/}

                {/*{form !== "brochure" && (*/}
                <tr>
                  <th scope="col">Ville</th>
                  <td>{lead.city && lead.city.name}</td>
                </tr>
                {/*)}*/}

                <tr>
                  <th scope="col">Modèle</th>
                  <td>{lead.campaign.model && lead.campaign.model.name}</td>
                </tr>

                </tbody>
              </table>
            </div>
            <div className="card-footer">
              <div className={"btn-group"}>
                <button onClick={() => this.getPrevious()} className="btn badge badge-secondary badge-pill">
                  Retour
                </button>
                {/*<Link href={`/quote_leads/${lead.id}/edit`} className="btn btn-primary">*/}
                {/*    Edit Quote*/}
                {/*</Link>*/}
              </div>

            </div>
          </div>
      );
    }

    return null;
  }
}

export default XSLeadShow;
