import React, {Component} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import _ from "lodash";
import Swal from "sweetalert2";
import Table from "../../../components/Table";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import paginate from "../../../utils/paginate";
import {Dropdown} from "react-bootstrap";
import DateDisplay from "../../../components/DateDisplay";
import {FaEllipsisH, FaEdit, FaTrash} from "react-icons/all";
import {getDate} from "../../../utils/utils";

class CampaignList extends Component {
    state = {
        campaigns: [],
        currentPage: 1,
        pageSize: 6,
        sortColumn: {path: "name", order: "asc"},
        searchQuery: "",
        model: {},
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()]
    };

    apiPath = "/campaigns";

    columns = [
        {
            path: "model.brand",
            label: "Marque",
            content: ((campaign) => (
                <span>{campaign.model.brand.name}</span>
            )),
        },
        {
            path: "model",
            label: "Modèle",
            content: ((campaign) => (
                <span>{campaign.model.name}</span>
            )),
        },
        {
            path: "title",
            label: "Nom",
        },
        {
            path: "source",
            label: "Source",
        },
        {
            path: "dateSorted",
            label: "Date",
            content: (campaign) => (
                <DateDisplay date={campaign.createdAt}/>
            )
        },
        {
            key: "actions",
            content: (campaign) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}
                                         variant="transparent"
                        >
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link to={`/campaigns/${campaign.id}/edit`}
                                      className="btn btn-light btn-block shadow-sm text-left">
                                    <FaEdit/> Edit
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item className="p-0">
                                <button
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Are you sure?',
                                            text: 'You are about to delete this model.',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Oui, je le supprime!',
                                            cancelButtonText: 'Non, je le garde!'
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                if (this.handleDelete(campaign)) {
                                                    Swal.fire('Campaign supprimé!', 'Le campaign a été bien supprimé.', 'success');
                                                } else {
                                                    Swal.fire('Campaign non supprimé!', 'On ne peut pas supprimer campaign à cause d\'une erreur système.', 'error');
                                                }
                                            } else {
                                            }
                                        });
                                    }}
                                    className={"btn btn-light btn-block shadow-sm text-left"}
                                >
                                    <FaTrash/> Delete
                                </button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    campaignHelper;

    constructor(props) {
        super(props);
        this.campaignHelper = new ApiResourceHelper(this.apiPath);
    }

    componentDidMount() {
        const {user} = this.props;
        console.log('componentDidMount.user', user);
        this.getCampaigns();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location, user} = this.props;
        console.log('componentDidUpdate.user', user);

        if (prevProps.location.key !== location.key) {
            this.getCampaigns();
        }

        if ((this.state.dateRange === null)
            || (!prevState.dateRange || (
                prevState.dateRange[0] !== this.state.dateRange[0]
                || prevState.dateRange[1] !== this.state.dateRange[1]))) {
            this.getCampaigns();
        }
    }

    getCampaigns = async () => {
        const queryParams = [];
        const {dateRange} = this.state;

        if (dateRange && dateRange[0]) {
            queryParams.push({key: 'createdAt%5Bafter%5D', value: getDate(dateRange[0])});
        }

        if (dateRange && dateRange[1]) {
            queryParams.push({key: 'createdAt%5Bbefore%5D', value: `${getDate(dateRange[1])} 23:59`});
        }

        const {data} = await this.campaignHelper.findBy(queryParams);

        const finalCampaigns = [];
        data["hydra:member"].forEach((c) => {
            const date = new Date(c.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()+1}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalCampaigns.push({
                ...c, dateSorted
            });
        });

        this.setState({campaigns: finalCampaigns});
    };

    handleDelete = async (campaign) => {
        const originalCampaigns = this.state.campaigns;

        const campaigns = originalCampaigns.filter((b) => b.id !== campaign.id);
        this.setState({campaigns});

        try {
            await this.campaignHelper.remove(`${this.apiPath}/${campaign.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This campaign has already been removed.");

            this.setState({campaigns: originalCampaigns});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    getPagedData = () => {
        const {
            campaigns: allCampaigns,
            currentPage,
            pageSize,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = allCampaigns;

        if (searchQuery) {
            filtered = allCampaigns.filter((b) =>
                b.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const campaigns = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: campaigns};
    };

    getExportedData = () => {
        const {data} = this.getPagedData();

        const exportedData = [];

        data.forEach((item, index) => {

            exportedData.push({
                marque: item.model.brand.name,
                modele: item.model.name,
                campaign: item.title,
            })
        });

        return exportedData;
    }

    handleSearchChange = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    handleDateRangeChange = (dateRange) => {
        console.log("dateRange", dateRange);

        this.setState({
            searchQuery: "",
            currentPage: 1,
            dateRange
        });
    }

    render() {
        const {currentPage, pageSize, sortColumn, model, dateRange} = this.state;
        const {totalCount, data: campaigns} = this.getPagedData();

        const {user} = this.props;
        console.log("user", user);

        return (
            <React.Fragment>
                <Table
                    tableName={"campagnes"}
                    tableTitle={`Liste des campagnes`}
                    columns={this.columns}
                    data={campaigns}
                    exportedData={this.getExportedData()}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    createLink={`/campaigns/create`}
                    searchQuery={this.state.searchQuery}
                    onSearchChange={this.handleSearchChange}
                    totalCount={totalCount}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    dateRange={dateRange}
                    handleDateRangeChange={this.handleDateRangeChange}
                />
            </React.Fragment>
        );
    }
}

export default CampaignList;
