import React, { Component } from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import RequestDetail from "../../../components/App/RequestDetail";
import Date from "../../../components/DateTimeDisplay";
import moment from "moment/moment";

class OcarzLeadShow extends Component {
  state = {
    lead: {},
    errors: {},
    loaded: false,
    tab: 'info',
    isActive: false
  };

  apiPath = "/ocarz_leads";

  leadHelper;

  constructor(props) {
    super(props);
    this.leadHelper = new ApiResourceHelper(this.apiPath);
  }

  componentDidMount = () => {
    this.getlead();
  };

  getlead = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: lead } = await this.leadHelper.findOne(
        `${this.apiPath}/${id}`
      );

      this.setState({ lead, loaded: true });
    } catch (ex) {}
  };

  getPrevious = () => {
    const {history} = this.props;
    history.goBack();
  }

  handleToggle = () => {
    this.setState({isActive: !this.state.isActive});
  };
  render() {
    const { lead, loaded } = this.state;


    const columns = [
      {
        path: "processedAt",
        label: "traité à",
        content: (request) =>
            (moment(request.processedAt).format('lll'))
      },
      {
        path: "resContent",
        label: "Response status"
      },
      {
        path: "resContent",
        label: "Response Content"
      },
      {
        path: "webhelpStatus",
        label: "Webhelp Status"
      },
      {
        path: "validationLog",
        label: "validation Log",
      },
      {
        path: "logs",
        label: "Log"
      },
    ];

    console.log("lead", lead);

    if (loaded) {
      return (
          <div className="card" style={{width: "100%"}}>
            <div className="card-body">
              <h5 className="card-title" style={{textTransform: "capitalize"}}>Fiche de Lead</h5>
              <div>
                <ul className="nav">
                  <li className="nav-item">
                    <a className={`nav-link ${this.state.tab === "info" && "active"}`} href="#" onClick={() => this.setState({tab: "info"})}>Info</a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${this.state.tab === "feedback" && "active"}`} href="#" onClick={() => this.setState({tab: "feedback"})}>Feedback</a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${this.state.tab === "log" && "active"}`} href="#" onClick={() => this.setState({tab: "log"})}>Log</a>
                  </li>
                </ul>
              </div>
              {this.state.tab === "info" && <table className="table">
                <tbody>
                <tr>
                  <th scope="col">Date</th>
                  <td>
                    <Date date={lead.createdAt}/>
                  </td>
                </tr>
                <tr>
                  <th scope="col">Civilité</th>
                  <td>{lead.gender}</td>
                </tr>

                <tr>
                  <th scope="col">Nom</th>
                  <td>{lead.firstName} {lead.lastName}</td>
                </tr>

                <tr>
                  <th scope="col">Email</th>
                  <td>{lead.email}</td>
                </tr>
                {/*{form !== "brochure" && (*/}
                <tr>
                  <th scope="col">Téléphone</th>
                  <td>{lead.telephone}</td>
                </tr>
                {/* )}*/}

                {/*{form !== "brochure" && (*/}
                <tr>
                  <th scope="col">Ville</th>
                  <td>{lead.city && lead.city.name}</td>
                </tr>
                {/*)}*/}

                <tr>
                  <th scope="col">Modèle</th>
                  <td>{lead.campaign.model && lead.campaign.model.name}</td>
                </tr>

                <tr style={{overflow: "scroll", height: "200px !important"}}>
                  <th scope="col">Logs</th>
                  <div style={{height: "200px !important"}}>

                    <td style={{whiteSpace: 'pre-wrap'}}>{lead.log}</td>
                  </div>
                </tr>

                </tbody>
              </table>}
              {this.state.tab === "feedback" && <div>
                {lead.feedback && <table className="table">
                  <tbody>
                  <tr>
                    <th scope="col">Webhelp ID</th>
                    <td>{lead.feedback.webhelpId}</td>
                  </tr>
                  <tr>
                    <th scope="col">Commentaite</th>
                    <td>{lead.feedback.comment}</td>
                  </tr>
                  <tr>
                    <th scope="col">Date d'appel</th>
                    <td><Date date={lead.feedback.callDate} /></td>
                  </tr>
                  <tr>
                    <th scope="col">Heure d'appel</th>
                    <td>{lead.feedback.callHour}</td>
                  </tr>
                  <tr>
                    <th scope="col">Statut d'appel</th>
                    <td>{lead.feedback.callStatus}</td>
                  </tr>
                  <tr>
                    <th scope="col">Action</th>
                    <td>{lead.feedback.action}</td>
                  </tr>
                  </tbody>
                </table>}

              </div>}
            </div>

            {this.state.tab === "log" &&   <table className="table">
              <thead>
              <tr>
                {
                  columns.map((column) => (
                      <th key={column.path || column.key}>{column.label}</th>
                  ))
                }
              </tr>

              </thead>
              <tbody>
              {
                  lead && lead.itemLogs.map((request) => (
                      <tr key={request.id}>
                        <td>{moment(request.processedAt).format('lll')}</td>
                        <td>{request.resStatus}</td>
                        <td>{request.resContent}</td>
                        <td>{request.webhelpStatus}</td>
                        <td style={{whiteSpace: 'pre-wrap'}}>{request.validationLog}</td>
                        <td style={{whiteSpace: 'pre-wrap'}}>{request.log}</td>
                      </tr>
                  ))
              }

              </tbody>
            </table>}
            <div className="card-footer">
              <div className={"btn-group"}>
                <button onClick={() => this.getPrevious()} className="btn badge badge-secondary badge-pill">
                  Retour
                </button>
                {/*<Link href={`/quote_leads/${lead.id}/edit`} className="btn btn-primary">*/}
                {/*    Edit Quote*/}
                {/*</Link>*/}
              </div>

            </div>
          </div>
      );
    }

    return null;
  }
}

export default OcarzLeadShow;
