import React, {Component} from 'react';
import ReactECharts from "echarts-for-react";

class EChartLeadsBySource extends Component {
    render() {
        const {title, subTitle, data, theme} = this.props;

        console.log('EChartLeadsBySource data', data);

        const option = {
            title: {
                text: title,
                subtext: subTitle,
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: 10,
                top: 30,
                //data: data.labels,
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            },
            series: [
                {
                    name: 'Source',
                    type: 'pie',
                    radius: ['30%', '50%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '40',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: data.values
                }
            ]
        };

        return <ReactECharts
            option={option}
            style={{height: 420}}
            theme={theme}
        />;
    }
}

export default EChartLeadsBySource;