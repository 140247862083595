import React, {Component} from "react";
import queryString from "query-string";
import ApiResourceHelper from "../../helpers/ApiResourceHelper";
import {toast} from "react-toastify";
import DateDisplay from "../../components/DateDisplay";
import {Dropdown} from "react-bootstrap";
import {FaEllipsisH, FaEye} from "react-icons/all";
import {Link} from "react-router-dom";
import Table from "../../components/Table";
import {getDate} from "../../utils/utils";

class ChatbotList extends Component {
    state = {
        loading: true,
        currentPage: 1,
        pageSize: 30,
        brand: null,
        chatbotLeads: [],
        total: 0,
        search: {},
        sortColumn: {path: "dateSorted", order: "desc"},
        selectStatus: null,
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()]
    };

    genders = {
        male: 'Homme',
        Mr: 'Homme',
        female: 'Femme',
        Mme: 'Femme',
    };

    columns = [
        {
            path: "dateSorted",
            label: "Date",
            content: (request) => (
                <DateDisplay date={request.createdAt}/>
            ),
        },
        {
            path: 'gender',
            content: (request) =>
                <React.Fragment>{this.genders[request.gender] ? this.genders[request.gender] : request.gender}</React.Fragment>,
            label: "Civilité"
        },
        {
            path: 'nameSorted',
            content: (request) => <React.Fragment>{request.firstName} {request.lastName}</React.Fragment>,
            label: "Nom"
        },
        {
            path: "email",
            label: "Email",
        },
        {
            path: 'telephone',
            label: "Téléphone",
            content: (request) => <React.Fragment>{request.telephone}</React.Fragment>,
        },
        {
            path: "trim",
            label: "Version",
        },
        {
            path: "utmCampaign",
            label: "UTM Campaign",
            content: (request) => <span>{request.utmCampaign ? request.utmCampaign : 'direct'}</span>
        },
        {
            path: "utmSource",
            label: "UTM Source",
            content: (request) => <span>{request.utmSource ? request.utmSource : 'direct'}</span>
        },
        {
            path: "utmMedium",
            label: "UTM Source",
            content: (request) => <span>{request.utmMedium ? request.utmMedium : 'direct'}</span>
        },
        {
            path: "transationId",
            label: "Transaction ID",
            content: (request) => <span>{request.transactionId && request.transactionId}</span>
        },
        {
            label: "webhelp status",
            content: (request) => (
                request.latestItemLog && request.latestItemLog.status === "ok"
                    ? <span className="badge badge-success">OK</span> :
                    request.latestItemLog && request.latestItemLog.status === "error" ?
                        <span className="badge badge-danger">ERROR</span> :
                        <span className="badge badge-warning">not processed or no log available</span>
            )
        },
        {
            key: "actions",
            content: (request) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="transparent"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}>
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link to={`/chatbot_leads/${request.id}`}
                                      className={"btn btn-light btn-block shadow-sm text-left"}>
                                    <FaEye/> Voir
                                </Link>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {location} = this.props;
        let params = queryString.parse(location.search);
        //this.getModels();
        this.getChatbotLeads(params.type);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;

        let params = queryString.parse(location.search);

        if (prevProps.location.key !== location.key) {
            //this.getModels();
            this.getChatbotLeads(params.type);
        }

        // console.log('prevState.dateRange', prevState.dateRange);
        // console.log('state.dateRange', this.state.dateRange);
        console.log('render.data', this.state.chatbotLeads);
        console.log('render.params', queryString.parse(location.search));

        if ((this.state.dateRange === null)
            || (!prevState.dateRange || (
                prevState.dateRange[0] !== this.state.dateRange[0]
                || prevState.dateRange[1] !== this.state.dateRange[1]))
            || (this.state.selectedPlatform !== prevState.selectedPlatform)
            || (this.state.selectedCampaign !== prevState.selectedCampaign)
            || (this.state.currentPage !== prevState.currentPage)
            || (this.state.selectedStatus !== prevState.selectedStatus)
            || (this.state.search !== prevState.search)
        ) {
            this.getChatbotLeads(params.type);
        }
    }

    getChatbotLeads = async (type) => {
        this.setState({loading: true});
        const {dateRange, currentPage, search, selectedStatus} = this.state;
        const {location} = this.props;

        let params = queryString.parse(location.search);
        const apiHelper = new ApiResourceHelper(`/leads`);
        const queryParams = this.getFilters(params.brand_id, dateRange, currentPage, search, selectedStatus, type);
        const {data} = await apiHelper.findBy(queryParams);
        const chatbotLeads = data["hydra:member"];
        const finalRequests = [];
        chatbotLeads.forEach((r) => {
            const date = new Date(r.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalRequests.push({
                ...r, dateSorted, nameSorted: `${r.firstName} ${r.lastName}`
            });
        });

        this.setState({chatbotLeads: finalRequests, total: data["hydra:totalItems"], loading: false});
    };

    getFilters(brandId, dateRange, currentPage, search, webhelpStatus, type) {
        const queryParams = [];

        if (type) {
            queryParams.push({key: 'type', value: type});
        }
        if (currentPage) {
            queryParams.push({key: 'page', value: currentPage});
        }
        if (brandId) {
            queryParams.push({key: "campaign.model.brand", value: brandId});
        }
        if (webhelpStatus) {
            queryParams.push({key: "latestItemLog.status", value: webhelpStatus});
        }

        if (dateRange && dateRange[0]) {
            queryParams.push({key: 'createdAt%5Bafter%5D', value: getDate(dateRange[0])});
        }

        if (dateRange && dateRange[1]) {
            queryParams.push({key: 'createdAt%5Bbefore%5D', value: `${getDate(dateRange[1])} 23:59`});
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams.push({key: filter[0], value: filter[1]})
            })
        }
        return queryParams;
    }

    getExportFilters(brandId, dateRange, search) {
        const queryParams = {};

        if (brandId) {
            queryParams['brand'] = brandId;
        }

        if (dateRange && dateRange[0]) {
            queryParams['from'] = getDate(dateRange[0]);
        }

        if (dateRange && dateRange[1]) {
            queryParams['to'] = `${getDate(dateRange[1])} 23:59`;
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams[filter[0]] = filter[1];
            });
        }
        return queryParams;
    }

    handleDelete = async (request) => {
        const originalRequests = this.state.chatbotLeads;

        const chatbotLeads = originalRequests.filter((b) => b.id !== request.id);
        this.setState({chatbotLeads});

        try {
            await this.apiHelper.remove(`${this.apiPath}/${request.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This request has already been removed.");

            this.setState({chatbotLeads: originalRequests});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    handleDateRangeChange = (dateRange) => {
        console.log("dateRange", dateRange);

        this.setState({
            currentPage: 1,
            dateRange
        });
    }

    setPage = (page) => {
        this.setState({currentPage: page});
    }

    onTextChange = (event) => {
        console.log('on.event', event);

        this.setState({search: {...this.state.search, [event.name]: event.value}});
        console.log('on.search', Object.entries(this.state.search));
    }

    onRequestChange = (event) => {
        console.log('onRequestChange.event', event);
        const value = event.target.value !== '---' ? event.target.value : null;
        this.setState({search: {...this.state.search, request: value}});
    }

    onStatusChange = (event) => {
        this.setState({selectedStatus: event.target.value});
    }

    render() {
        const {chatbotLeads, total, currentPage, pageSize, sortColumn, dateRange, loading, search} = this.state;

        let title = 'Chatbot leads';
        let tableName = 'Chatbot leads';
        const {location} = this.props;
        let params = queryString.parse(location.search);
        console.log("render.paramsfilter", params);
        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={chatbotLeads}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    tableName={tableName}
                    tableTitle={title}
                    onSearchChange={this.handleSearchChange}
                    totalCount={total}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    dateRange={dateRange}
                    handleDateRangeChange={this.handleDateRangeChange}
                    loading={loading}
                    extraFilters={[
                        {
                            name: "status",
                            type: "select",
                            label: false,
                            options: [{key: "webhelpStatus", value: ""},
                                {key: "Success", value: "ok"},
                                {key: "Error", value: "error"},],
                            onChange: this.onStatusChange
                        },
                        {name: "trim", type: "text", label: "Version", onChange: this.onTextChange},
                        {name: "email", type: "text", label: "Email", onChange: this.onTextChange},
                        {name: "firstName", type: "text", label: "Prénom", onChange: this.onTextChange},
                        {name: "lastName", type: "text", label: "Nom", onChange: this.onTextChange},
                        {name: "telephone", type: "text", label: "Téléphone", onChange: this.onTextChange},
                    ]}
                    exportEndpoint={"/leads_exports"}
                    exportFilename={"prebookings.csv"}
                    exportFilters={this.getExportFilters(params.brand_id, dateRange, search)}
                />
            </React.Fragment>
        );
    }
}

export default ChatbotList;
