import React from "react";

const Search = ({ value, onChange, name, label }) => {
  return (
    <React.Fragment>
      <input
        name={name}
        placeholder={label}
        value={value}
        onChange={(e) => onChange(e.currentTarget)}
        className="form-control mt-3 mb-3 shadow-sm rounded-1"
      />
    </React.Fragment>
  );
};

export default Search;
