import React, {Component} from "react";
import RequestList from "../../../components/App/RequestList";
import queryString from "query-string";

class TestDriveRequestList extends Component {
    render() {
        const {location, user} = this.props;
        let params = queryString.parse(location.search);

        return (
            <RequestList user={user} title={"Liste des essais"} tableName={"essais"} form={"test_drive"} endpoint={"/test_drive_requests"} exportEndpoint={"/test_drive_request_exports"}  exportFilename={"test_drives.csv"} brandId={params.brand_id} location={location} />
        );
    }
}

export default TestDriveRequestList;
