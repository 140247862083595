import React from "react";


const ButtonWithoutIcon  = ({ type, label, cssClass, ...rest }) => {
    return (
        <button className={cssClass} rest>
            {label}
        </button>
    );
};

export default ButtonWithoutIcon;