import ModelCreate from "../ModelCreate";

class ModelEdit extends ModelCreate {
  componentDidMount = () => {
    this.setState({formTitle: "Edit model"});
    this.getBrands();
    this.populateModel();
  };

  populateModel = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: model } = await this.modelHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("model", model);

      this.setState({ data: this.mapToViewModel(model) });
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  getPrevious = () => {
    const {history} = this.props;
    history.goBack();
  }

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.modelHelper.update(`${this.apiPath}/${id}`, this.state.data);

    this.getPrevious();
  };

  mapToViewModel = (model) => {
    return {
      name: model.name,
      brand: `/brands/${model.brand.id}`,
      description: `${model.description}`,
    };
  };
}

export default ModelEdit;
