import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const NavbarItem = ({ name, to, icon, subItems }) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleItemClick = (e) => {
    console.log("item clicked");
    e.preventDefault();
    setCollapsed(!collapsed);
  };

  return (
    <React.Fragment>
      <li className="nav-item">

        {subItems && (
            <React.Fragment>
              <Link
                  aria-expanded="false"
                  className={`${
                      subItems ? "dropdown-indicator cursor-pointer" : ""
                  } nav-link`}
                  onClick={handleItemClick}
                  to={to}
              >
                <div className="d-flex align-items-center" title={name}>
                  {icon && <span className="nav-link-icon">{icon}</span>}
                  <span className="nav-link-text">{name}</span>
                </div>
              </Link>

              <div className={`collapse ${!collapsed ? "show" : ""}`}>
                <ul className="nav">
                  {subItems.map((i) => (
                        <li className="nav-item">
                          {!i.subItems && (
                              <Link
                                  aria-current="page"
                                  className="nav-link"
                                  name={i.name}
                                  to={i.to}
                              >
                                <div className="d-flex align-items-center" title={i.name}>
                                  {i.icon && <span className="nav-link-icon">{i.icon}</span>}
                                  <span className="nav-link-text">{i.name}</span>
                                </div>
                              </Link>
                          )}

                          {i.subItems && (
                              <NavbarItem to={i.to} name={i.name} icon={i.icon} subItems={i.subItems} />
                          )}
                        </li>
                      )
                    )}
                </ul>
              </div>
            </React.Fragment>
        )}

        {!subItems && (
              <Link
                  aria-expanded="false"
                  className={`${
                      subItems ? "dropdown-indicator cursor-pointer" : ""
                  } nav-link`}
                  to={to}
              >
                <div className="d-flex align-items-center" title={name}>
                  {icon && <span className="nav-link-icon">{icon}</span>}
                  <span className="nav-link-text">{name}</span>
                </div>
              </Link>
        )}

      </li>
    </React.Fragment>
  );
};

export default NavbarItem;
