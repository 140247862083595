import React, {Component} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import _ from "lodash";
import Swal from "sweetalert2";
import Table from "../../../components/Table";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import paginate from "../../../utils/paginate";
import {Col, Dropdown, Row} from "react-bootstrap";
import queryString from "query-string";
import DateDisplay from "../../../components/DateDisplay";
import {FaEllipsisH, FaEye, FaTrash} from "react-icons/all";
import {getDate} from "../../../utils/utils";

class RequestList extends Component {
    state = {
        loading: true,
        requests: [],
        currentPage: 1,
        pageSize: 30,
        sortColumn: {path: "dateSorted", order: "desc"},
        searchQuery: "",
        search: {},
        brand: {},
        selectedStatus: null,
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()],
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {user} = this.props;

        if (user.roles && !user.roles.includes("ROLE_BRAND")) {
            this.getBrand();
        } else {
            this.getBrandByUser();
        }

        this.getRequests();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location, user} = this.props;

        if (prevProps.location.key !== location.key) {
            if (!user.roles.includes("ROLE_BRAND")) {
                this.getBrand();
            } else {
                this.getBrandByUser();
            }
            this.getRequests();
        }

        if ((this.state.dateRange === null)
            || (!prevState.dateRange || (
                prevState.dateRange[0] !== this.state.dateRange[0]
                || prevState.dateRange[1] !== this.state.dateRange[1]))
            || prevState.currentPage !== this.state.currentPage
            || (this.state.selectedStatus !== prevState.selectedStatus)
            || prevState.search !== this.state.search
        ) {
            this.getRequests();
        }
    }

    getBrand = async () => {
        try {
            const path = "/brands";
            const brandHelper = new ApiResourceHelper(path);

            let params = queryString.parse(this.props.location.search);
            console.log('params', params);
            console.log('params.brand', params.brand_id);

            const {data: brand} = await brandHelper.findOne(
                `${path}/${params.brand_id}`
            );

            console.log('brand', brand);

            this.setState({brand});
        } catch (ex) {
        }
    };

    getBrandByUser = async () => {
        try {
            const path = "/brands";
            const brandHelper = new ApiResourceHelper(path);

            const {data: brands} = await brandHelper.findAll();
            console.log('brand', brands);

            this.setState({brand: brands[0]});
        } catch (ex) {
            console.log('ex', ex);
        }
    };

    getRequests = async () => {
        this.setState({loading: true});

        const {user, endpoint, brandId} = this.props;
        const {dateRange, currentPage, search, selectedStatus} = this.state;
        const apiHelper = new ApiResourceHelper(endpoint);

        const queryParams = this.getFilters(user, brandId, dateRange, currentPage, search, selectedStatus);

        const {data} = await apiHelper.findBy(queryParams);

        const finalRequests = [];
        data["hydra:member"].forEach((r) => {
            const date = new Date(r.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalRequests.push({
                ...r, dateSorted, nameSorted: `${r.firstName} ${r.lastName}`
            });
        });

        this.setState({requests: finalRequests, loading: false, total: data["hydra:totalItems"]});
    };

    getFilters(user, brandId, dateRange, currentPage, search, webHelpStatus) {
        const queryParams = [];

        if (brandId) {
            queryParams.push({key: 'model.brand', value: `/brands/${brandId}`});
        }

        if (dateRange && dateRange[0]) {
            queryParams.push({key: 'createdAt%5Bafter%5D', value: getDate(dateRange[0])});
        }

        if (webHelpStatus) {
            queryParams.push({key: 'latestItemLog.status', value: webHelpStatus});
        }

        if (dateRange && dateRange[1]) {
            queryParams.push({key: 'createdAt%5Bbefore%5D', value: `${getDate(dateRange[1])} 23:59`});
        }

        if (currentPage) {
            queryParams.push({key: 'page', value: currentPage});
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams.push({key: filter[0], value: filter[1]})
            })
        }

        console.log('queryParams', queryParams);

        return queryParams;
    }

    getExportFilters(user, brandId, dateRange, search) {
        const queryParams = {};

        if (brandId) {
            queryParams['brand'] = brandId;
        }

        if (dateRange && dateRange[0]) {
            queryParams['from'] = getDate(dateRange[0]);
        }

        if (dateRange && dateRange[1]) {
            queryParams['to'] = `${getDate(dateRange[1])}`;
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams[filter[0]] = filter[1];
            });
        }

        return queryParams;
    }

    handleDelete = async (request) => {
        const originalRequests = this.state.requests;

        const requests = originalRequests.filter((b) => b.id !== request.id);
        this.setState({requests});

        try {
            await this.apiHelper.remove(`${this.apiPath}/${request.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This request has already been removed.");

            this.setState({requests: originalRequests});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    /*getPagedData = () => {
        const {
            requests: allRequests,
            currentPage,
            pageSize,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = allRequests;

        if (searchQuery) {
            filtered = allRequests.filter((b) =>
                b.email.toLowerCase().startsWith(searchQuery)
                || b.firstName.toLowerCase().startsWith(searchQuery)
                || b.lastName.toLowerCase().startsWith(searchQuery)
                || b.telephone.toLowerCase().startsWith(searchQuery)
                || (b.city && b.city.name.toLowerCase().startsWith(searchQuery))
            );
        }

        console.log('sortColumn', sortColumn);

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const requests = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: requests};
    };*/

    handleSearchChange = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    genders = {
        mister: 'Homme',
        lady: 'Femme',
        miss: 'Demoiselle',
    };

    columns = [
        {
            path: "dateSorted",
            label: "Date",
            content: (request) => (
                <DateDisplay date={request.createdAt}/>
            ),
        },
        {
            path: "gender",
            label: "Civilité",
            content: (request) => (<span>{this.genders[request.gender]}</span>)
        },
        {
            path: "email",
            label: "Email",
        },
        {
            path: 'nameSorted',
            content: (request) => <React.Fragment>{request.firstName} {request.lastName}</React.Fragment>,
            label: "Nom"
        },
        {
            path: 'telephone',
            label: "Téléphone",
            content: (request) => <React.Fragment>
                {request.telephone}{request.telephoneSecondary && `/${request.telephoneSecondary}`}
            </React.Fragment>,
        },
        {
            path: 'utmCampaign',
            label: "UTM Campaign",
            content: (request) => <React.Fragment>
                {request.utmCampaign}
            </React.Fragment>,
        },
        {
            path: 'utmSource',
            label: "UTM Source",
            content: (request) => <React.Fragment>
                {request.utmSource}
            </React.Fragment>,
        },
        {
            path: 'utmMedium',
            label: "UTM Medium",
            content: (request) => <React.Fragment>
                {request.utmMedium}
            </React.Fragment>,
        },
        {
            label: "webhelp status",
            content: (request) => (
                request.latestItemLog && request.latestItemLog.status === "ok"
                    ? <span className="badge badge-success">OK</span> :
                    request.latestItemLog && request.latestItemLog.status === "error" ?
                        <span className="badge badge-danger">ERROR</span> :
                        <span className="badge badge-warning">not processed or no log available</span>
            )
        },
        {
            key: "actions",
            content: (request) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="transparent"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}>
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link to={`${this.props.endpoint}/${request.id}`}
                                      className={"btn btn-light btn-block shadow-sm text-left"}>
                                    <FaEye/> Voir
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item className="p-0">
                                <button
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Etes-vous sûr?',
                                            text: 'Vous êtes sur le point de supprimer cette demande.',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Oui, je le supprime!',
                                            cancelButtonText: 'Non, je le garde!'
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                if (this.handleDelete(request)) {
                                                    Swal.fire('Demande supprimée!', 'La demande a été bien supprimée.', 'success');
                                                } else {
                                                    Swal.fire('Demande non supprimée!', 'La demande n\'a pas été supprimée.', 'error');
                                                }
                                            } else {
                                            }
                                        });
                                    }}
                                    className={"btn btn-light btn-block shadow-sm text-left"}>
                                    <FaTrash/> Supprimer
                                </button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    /*getExportedData = () => {
        const {requests} = this.state;
        console.log('getExportedData', requests);

        const exportedData = [];

        requests.forEach((item, index) => {
            const date = new Date(item.createdAt);

            const newItem = {
                date: `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${date.getHours()}:${date.getMinutes()}`,
                civilite: this.genders[item.gender],
                nom: item.lastName + ' ' + item.firstName,
                email: item.email,
                telephone: item.telephone,
                marque: item.model && item.model.brand ? item.model.brand.name : '',
                modele: item.model ? item.model.name : '',
                ville: item.city ? item.city.name : '',
                showroom: item.showroom ? item.showroom.name : '',
                utm_source: item.utmSource,
                utm_campaign: item.utmCampaign,
                utm_medium: item.utmMedium,
                jaccepte_activite_marketing: item.acceptedMarketingActivity ? 'oui' : 'non',
            };

            if (!newItem.showroom) delete newItem.showroom;

            exportedData.push(newItem)
        });

        return exportedData;
    }*/

    handleDateRangeChange = (dateRange) => {
        console.log("dateRange", dateRange);

        this.setState({
            searchQuery: "",
            currentPage: 1,
            dateRange
        });
    }

    onTextChange = ( event ) => {
        console.log('on.event', event);

        this.setState({search: {...this.state.search, [event.name]: event.value}});
        console.log('on.search', Object.entries(this.state.search));
    }
    onStatusChange = (event) => {
        this.setState({selectedStatus: event.target.value});
    }

    render() {
        const {requests, total, currentPage, pageSize, sortColumn, dateRange, loading, search} = this.state;
        const {title, tableName, exportEndpoint, exportFilename, user, brandId} = this.props;

        return (
            <Table
                columns={this.columns}
                data={requests}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                tableName={tableName}
                tableTitle={title}
                onSearchChange={this.handleSearchChange}
                totalCount={total}
                onPageChange={this.handlePageChange}
                currentPage={currentPage}
                pageSize={pageSize}
                dateRange={dateRange}
                handleDateRangeChange={this.handleDateRangeChange}
                loading={loading}
                extraFilters={[
                    {name: "email", type: "text", label: "Email", onChange: this.onTextChange},
                    {name: "firstName", type: "text", label: "First name", onChange: this.onTextChange},
                    {name: "lastName", type: "text", label: "Last name", onChange: this.onTextChange},
                    {name: "telephone", type: "text", label: "Téléphone", onChange: this.onTextChange},
                    {
                        name: "status",
                        type: "select",
                        label: false,
                        options: [{key: "webhelpStatus", value: ""},
                            {key: "Success", value: "ok"},
                            {key: "Error", value: "error"},],
                        onChange: this.onStatusChange
                    },
                ]}
                exportEndpoint={exportEndpoint}
                exportFilename={exportFilename}
                exportFilters={this.getExportFilters(user, brandId, dateRange, search)}
            />
        );
    }
}

export default RequestList;