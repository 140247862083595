import React from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {Row} from "react-bootstrap";

class ModelCreate extends Form {
    state = {
        formTitle: "Add a new model",
        data: {
            name: "",
            description: "",
            brand: "",
        },
        errors: {},
        brands: {},
        brandsLoaded: false
    };

    apiPath = "/models";

    schema = {
        name: Joi.string().required().min(2).max(30).label("Name"),
        description: Joi.string().allow('', null).min(2).max(60).label("Description"),
        brand: Joi.string().required().label("brand"),
    };

    modelHelper;

    constructor(props) {
        super(props);
        this.modelHelper = new ApiResourceHelper(this.apiPath);
    }

    componentDidMount() {
        this.getBrands();
    }

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    getBrands = async () => {
        try {
            const brandApiPath = "/brands";
            const brandHelper = new ApiResourceHelper(brandApiPath);
            const {data} = await brandHelper.findAll();
            this.setState({brands: data["hydra:member"], brandsLoaded: true});
        } catch (ex) {
        }
    };

    getBrandList = () => {
        const {brands, brandsLoaded} = this.state;

        const brandList = [];
        if (brandsLoaded) {
            brands.map((brand) => {
                brandList.push({
                    key: `${brand.name}`,
                    value: `/brands/${brand.id}`,
                });

                return true;
            });
        }

        return brandList;
    };

    doSubmit = async () => {
        console.log("Submitted");
        const {data:model} = await this.modelHelper.create(this.state.data);
        this.props.history.replace(`/models/${model.id}`);
        //this.getPrevious();
    };

    render() {
        const {history} = this.props;

        return (
            <div className={"no-gutters row mt-lg-5"}>

                <div className={"pr-lg-2 col-lg-8"}>

                    <div className={"mb-3 card"}>
                        <div className={"card-header"}>
                            <h5 className="mb-0">{this.state.formTitle}</h5>
                        </div>
                        <div className={"bg-light card-body"}>
                            <form onSubmit={this.handleSubmit}>
                                <Row>
                                    <div className="col-lg-12">
                                        <div className={"form-group"}>
                                            {this.renderInput("name", "text", "Nom")}
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-lg-12">
                                        <div className={"form-group"}>
                                            {this.renderInput("description", "text", "Description")}
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-lg-12">
                                        <div className={"form-group"}>
                                            {this.renderSelect("brand", "Marque", [{key: "Séléctionnez la marque", value: ""}].concat(this.getBrandList()))}
                                        </div>
                                    </div>
                                </Row>

                                <div className="btn-group">
                                    <button onClick={() => history.goBack()} className="btn btn-secondary mt-3">
                                        Annuler
                                    </button>
                                    {this.renderButton("Save")}
                                </div>

                            </form>
                        </div>
                        <div className="card-footer">

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ModelCreate;
