import React, {Component} from "react";
import Input from "../../Form/Input";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {FaExclamationTriangle, FaUpload} from "react-icons/all";
import imageStyles from "../../../assets/styles/image.module.css";
import Swal from "sweetalert2";

class ImageCreate extends Component {
    state = {
        selectedFile: null,
    };

    apiHelper;

    constructor(props) {
        super(props);
        this.apiHelper = new ApiResourceHelper("/images");
    }

    componentDidMount = async () => {
    };

    onFileChange = async (e) => {
        console.log('photo file change');
        const selectedFile = e.target.files[0];
        console.log('sf', selectedFile);
        this.setState({selectedFile});

        const {minWidth, minHeight} = this.props;
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        const objectUrl = _URL.createObjectURL(selectedFile);
        console.log('objectUrl', objectUrl);

        let self = this;

        img.onload = function () {
            console.log('this', this);
            console.log('img', img);
            console.log('min size', minWidth, minHeight);
            console.log('img size', this.width, this.height);

            if (this.width === minWidth && this.height === minHeight) {
                self.saveFileToServer();
            } else {
                const error = `Largeur/hauteur incorrecte (La largeur doît être égal à ${minWidth}px et la hauteur à ${minHeight}px. ))`;
                self.displaySwal('Error', error, 'error');
            }

            _URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl;
        console.log('img', img);
        console.log('img.width', img.width);
    };

    saveFileToServer = async () => {
        const {selectedFile} = this.state;

        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        console.log('fd', formData);

        try {
            const {data: photo} = await this.apiHelper.create(formData);
            const {uploadCallback} = this.props;
            uploadCallback(photo);
        } catch (ex) {
            console.log('upload_ex', ex);
        }
    }

    displaySwal = (title, text, icon) => {
        Swal.fire({title, text, icon}).then(r => {
            console.log('response', r)
        });
    }

    render() {
        return (
            <React.Fragment>
                <label className={imageStyles['btn']}>
                    <div className="file btn btn-success">
                        <Input label={<FaUpload/>} type="file" name="image" onChange={this.onFileChange}
                               customStyle={{display: 'none'}} containerCustomStyle={{marginBottom: 0}}
                               labelCustomStyle={{fontSize: '1.5rem'}}/>
                    </div>
                </label>
            </React.Fragment>
        );
    }
}

export default ImageCreate;
