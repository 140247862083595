import React, {Component} from 'react';
import falcon from "../../../falcon/src/assets/img/illustrations/falcon.png";
import team1 from "../../../falcon/src/assets/img/team/1.jpg";
import team3 from "../../../falcon/src/assets/img/team/3.jpg";
import {FaBell, FaChevronRight, FaCircle, FaMoon, FaShoppingCart, FaSun, FaUser} from "react-icons/all";
import {Link} from "react-router-dom";

class HorizontalNavbar extends Component {
    state = {
        isProfileOpen: false
    };

    toggleProfile = (isProfileOpen) => {
        this.setState({isProfileOpen});
    }

    render() {
        const {theme, onThemeChange} = this.props;
        const {isProfileOpen} = this.state;

        return (
            <nav className="navbar navbar-light navbar-glass navbar-top navbar-expand justify-content-end mb-4">
                <button
                    onClick={() => this.props.setMobileNavbarOpen()}
                    className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button"
                    data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse"
                    aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation">
                    <span className="navbar-toggle-icon">
                        <span className="toggle-line"/>
                    </span>
                </button>
                <a className="navbar-brand me-1 me-sm-3" href="/">
                    <div className="d-flex align-items-center">
                        <span className="font-sans-serif">FCA</span>
                    </div>
                </a>
                <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
                    <li className="nav-item">
                        <div className="theme-control-toggle fa-icon-wait px-2">
                            {theme === "dark" && (
                                <label
                                    className="mb-0 theme-control-toggle-label theme-control-toggle-light"
                                    htmlFor="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"
                                    title data-bs-original-title="Switch to light theme" aria-label="Switch to light theme"
                                    onClick={() => onThemeChange("light")}>
                                    <FaSun/>
                                </label>
                            )}
                            {theme !== "dark" && (
                                <label
                                    className="mb-0 theme-control-toggle-label theme-control-toggle-dark"
                                    htmlFor="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"
                                    title data-bs-original-title="Switch to dark theme" aria-label="Switch to dark theme"
                                    onClick={() => onThemeChange("dark")}>
                                    <FaMoon/>
                                </label>
                            )}

                        </div>
                    </li>
                    {/*<li className="nav-item">
                        <a className="nav-link px-0 notification-indicator notification-indicator-warning notification-indicator-fill fa-icon-wait"
                           href="../../app/e-commerce/shopping-cart.html">
                            <FaShoppingCart/>
                            <span className="notification-indicator-number">1</span></a>
                    </li>*/}
                    {/*<li className="nav-item dropdown">
                        <a className="nav-link notification-indicator notification-indicator-primary px-0 fa-icon-wait"
                           id="navbarDropdownNotification" href="#" role="button" data-bs-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                            <FaBell/>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification"
                             aria-labelledby="navbarDropdownNotification">
                            <div className="card card-notification shadow-none">
                                <div className="card-header">
                                    <div className="row justify-content-between align-items-center">
                                        <div className="col-auto">
                                            <h6 className="card-header-title mb-0">Notifications</h6>
                                        </div>
                                        <div className="col-auto ps-0 ps-sm-3">
                                            <a className="card-link fw-normal" href="#">Mark all as read</a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="scrollbar-overlay os-host os-theme-dark os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-scrollbar-vertical-hidden os-host-transition"
                                    style={{maxHeight: '19rem'}}>
                                    <div className="os-resize-observer-host observed">
                                        <div className="os-resize-observer" style={{left: '0px', right: 'auto'}}/>
                                    </div>
                                    <div className="os-size-auto-observer observed"
                                         style={{height: 'calc(100% + 1px)', float: 'left'}}>
                                        <div className="os-resize-observer"/>
                                    </div>
                                    <div className="os-content-glue" style={{margin: '0px'}}/>
                                    <div className="os-padding">
                                        <div className="os-viewport os-viewport-native-scrollbars-invisible">
                                            <div className="os-content"
                                                 style={{padding: '0px', height: '100%', width: '100%'}}>
                                                <div className="list-group list-group-flush fw-normal fs--1">
                                                    <div className="list-group-title border-bottom">NEW</div>
                                                    <div className="list-group-item">
                                                        <a className="notification notification-flush notification-unread"
                                                           href="#!">
                                                            <div className="notification-avatar">
                                                                <div className="avatar avatar-2xl me-3">
                                                                    <img className="rounded-circle"
                                                                         src="../../assets/img/team/1-thumb.png"
                                                                         alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className="notification-body">
                                                                <p className="mb-1"><strong>Emma Watson</strong> replied
                                                                    to your comment : "Hello world 😍"</p>
                                                                <span className="notification-time">
                                                                    <span className="me-2" role="img"
                                                                          aria-label="Emoji">💬</span>Just now
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="list-group-item">
                                                        <a className="notification notification-flush notification-unread"
                                                           href="#!">
                                                            <div className="notification-avatar">
                                                                <div className="avatar avatar-2xl me-3">
                                                                    <div className="avatar-name rounded-circle">
                                                                        <span>AB</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="notification-body">
                                                                <p className="mb-1"><strong>Albert
                                                                    Brooks</strong> reacted to <strong>Mia
                                                                    Khalifa's</strong> status</p>
                                                                <span className="notification-time"><svg
                                                                    className="svg-inline--fa fa-gratipay fa-w-16 me-2 text-danger"
                                                                    aria-hidden="true" focusable="false"
                                                                    data-prefix="fab" data-icon="gratipay" role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 496 512" data-fa-i2svg><path
                                                                    fill="currentColor"
                                                                    d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm114.6 226.4l-113 152.7-112.7-152.7c-8.7-11.9-19.1-50.4 13.6-72 28.1-18.1 54.6-4.2 68.5 11.9 15.9 17.9 46.6 16.9 61.7 0 13.9-16.1 40.4-30 68.1-11.9 32.9 21.6 22.6 60 13.8 72z"/></svg>
                                                                    9hr</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="list-group-title border-bottom">EARLIER</div>
                                                    <div className="list-group-item">
                                                        <a className="notification notification-flush" href="#!">
                                                            <div className="notification-avatar">
                                                                <div className="avatar avatar-2xl me-3">
                                                                    <img className="rounded-circle"
                                                                         src="../../assets/img/icons/weather-sm.jpg"
                                                                         alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className="notification-body">
                                                                <p className="mb-1">The forecast today shows a low of
                                                                    20℃ in California. See today's weather.</p>
                                                                <span className="notification-time">
                                                                    <span className="me-2" role="img"
                                                                          aria-label="Emoji">🌤️</span>1d</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="list-group-item">
                                                        <a className="border-bottom-0 notification-unread  notification notification-flush"
                                                           href="#!">
                                                            <div className="notification-avatar">
                                                                <div className="avatar avatar-xl me-3">
                                                                    <img className="rounded-circle"
                                                                         src="../../assets/img/logos/oxford.png"
                                                                         alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className="notification-body">
                                                                <p className="mb-1"><strong>University of
                                                                    Oxford</strong> created an event : "Causal Inference
                                                                    Hilary 2019"</p>
                                                                <span className="notification-time"><span
                                                                    className="me-2" role="img"
                                                                    aria-label="Emoji">✌️</span>1w</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="list-group-item">
                                                        <a className="border-bottom-0 notification notification-flush"
                                                           href="#!">
                                                            <div className="notification-avatar">
                                                                <div className="avatar avatar-xl me-3">
                                                                    <img className="rounded-circle"
                                                                         src="../../assets/img/team/10.jpg" alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className="notification-body">
                                                                <p className="mb-1"><strong>James
                                                                    Cameron</strong> invited to join the group: United
                                                                    Nations International Children's Fund</p>
                                                                <span className="notification-time"><span
                                                                    className="me-2" role="img"
                                                                    aria-label="Emoji">🙋‍</span>2d</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
                                        <div className="os-scrollbar-track os-scrollbar-track-off">
                                            <div className="os-scrollbar-handle"
                                                 style={{transform: 'translate(0px, 0px)'}}/>
                                        </div>
                                    </div>
                                    <div
                                        className="os-scrollbar os-scrollbar-vertical os-scrollbar-unusable os-scrollbar-auto-hidden">
                                        <div className="os-scrollbar-track os-scrollbar-track-off">
                                            <div className="os-scrollbar-handle"
                                                 style={{transform: 'translate(0px, 0px)'}}/>
                                        </div>
                                    </div>
                                    <div className="os-scrollbar-corner"/>
                                </div>
                                <div className="card-footer text-center border-top">
                                    <a className="card-link d-block" href="/">View all</a>
                                </div>
                            </div>
                        </div>
                    </li>*/}
                    <li className={`nav-item dropdown ${isProfileOpen && "show"}`} onMouseEnter={() => this.toggleProfile(true)} onMouseLeave={() => this.toggleProfile(false)}>
                        <Link className="nav-link pe-0" id="navbarDropdownUser" to="#" role="button"
                           data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={(e) => {
                               e.preventDefault();
                        }}>
                            <div className="avatar">
                                {/*<img className="rounded-circle" src={team3} alt=""/>*/}
                                <FaUser  />
                            </div>
                        </Link>
                        <div className={`dropdown-menu dropdown-menu-end py-0 ${isProfileOpen && "show"}`} aria-labelledby="navbarDropdownUser">
                            <div className="bg-white dark__bg-1000 rounded-2 py-2">
                                <div className="dropdown-divider"/>
                                {/*<Link className="dropdown-item" href="#!">Set status</Link>
                                <Link className="dropdown-item" href="/">Profile &amp; account</Link>
                                <Link className="dropdown-item" href="#!">Feedback</Link>
                                <div className="dropdown-divider"/>
                                <Link className="dropdown-item" href="/">Settings</Link>*/}
                                <Link className="dropdown-item" to="/logout">Se déconnecter</Link>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default HorizontalNavbar;