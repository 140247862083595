import Joi from "joi-browser";
import BrandCreate from "../BrandCreate";

class BrandEdit extends BrandCreate {
  schema = { ...this.schema, id: Joi.number().required() };

  componentDidMount = () => {
    this.populateBrand();
  };

  populateBrand = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: brand } = await this.brandHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("brand", brand);

      this.setState({ data: this.mapToViewModel(brand) });
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.brandHelper.update(`${this.apiPath}/${id}`, this.state.data);
    this.props.history.replace("/brands");
  };

  mapToViewModel = (brand) => {
    return {
      id: brand.id,
      name: brand.name,
      shouldClientConsent: brand.shouldClientConsent,
    };
  };
}

export default BrandEdit;
