import React from "react";
import Form from "../../../components/Form";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {Row} from "react-bootstrap";
import Joi from "joi-browser";
import {sourceOptions} from "../../../constant/leadSources";

class CampaignCreate extends Form {
    state = {
        data: {
            title: "",
            source: "",
            brand: "",
            model: "",
        },
        errors: {},
        brands: [],
        models: [],
        campaign: {}
    };

    formTitle = "Ajout d'une campagne";

    apiPath = "/campaigns";

    schema = {
        title: Joi.string().required().min(2).max(100).label("Nom"),
        source: Joi.string().required().min(2).max(30).label("Source"),
        brand: Joi.string().required().regex(/^\/brands\/\d*$/i).label("Marque"),
        model: Joi.string().required().regex(/^\/models\/\d*$/i).label("Modèle"),
    };

    campaignHelper;

    constructor(props) {
        super(props);
        this.campaignHelper = new ApiResourceHelper(this.apiPath);
    }

    componentDidMount = () => {
        this.getBrands();
    };

    getBrands = async () => {
        try {
            const apiPath = "/brands";
            const helper = new ApiResourceHelper(apiPath);
            const {data} = await helper.findAll();
            this.setState({brands: data["hydra:member"]});
        } catch (ex) {}
    };

    getBrandList = () => {
        const {brands} = this.state;

        const brandList = [];
        brands.map((brand) => {
            brandList.push({
                key: `${brand.name}`,
                value: `/brands/${brand.id}`,
            });

            return true;
        });

        return brandList;
    };

    getModels = async (brandIri) => {
        try {
            const apiPath = "/models";
            const helper = new ApiResourceHelper(apiPath);
            const {data} = await helper.findBy([{key: "brand", value: brandIri}]);
            this.setState({models: data["hydra:member"]});
        } catch (ex) {}
    };

    getModelList = () => {
        const {models} = this.state;

        const modelList = [];
        models.map((model) => {
            modelList.push({
                key: `${model.name}`,
                value: `/models/${model.id}`,
            });

            return true;
        });

        return modelList;
    };

    doSubmit = async () => {
        console.log("Submitted");

        await this.campaignHelper.create(this.state.data);
        this.props.history.replace("/campaigns");
    };

    onBrandSelect = (brandIri) => {
        this.getModels(brandIri);
    };

    render() {
        return (
            <div className={"no-gutters row mt-lg-5"}>
                <div className={"pr-lg-2 col-lg-8"}>

                    <div className={"mb-3 card"}>
                        <div className={"card-header"}>
                            <h5 className="mb-0">{this.formTitle}</h5>
                        </div>
                        <div className={"bg-light card-body"}>
                            <form onSubmit={this.handleSubmit}>
                                <Row>
                                    <div className="col-lg-12">
                                        <div className={"form-group"}>
                                            {this.renderSelect("brand", "Marque", [{
                                                key: "Séléctionnez la marque",
                                                value: ""
                                            }].concat(this.state.brands.length ? this.getBrandList() : []), this.onBrandSelect)}
                                        </div>
                                    </div>
                                </Row>

                                <Row>
                                    <div className="col-lg-12">
                                        <div className={"form-group"}>
                                            {this.renderSelect("model", "Modèle", [{
                                                key: "Séléctionnez le modèle",
                                                value: ""
                                            }].concat(this.state.models.length ? this.getModelList() : []))}
                                        </div>
                                    </div>
                                </Row>

                                <Row>
                                    <div className="col-lg-12">
                                        <div className={"form-group"}>
                                            {this.renderSelect("source", "Source", sourceOptions)}
                                        </div>
                                    </div>
                                </Row>

                                <Row>
                                    <div className="col-lg-12">
                                        <div className={"form-group"}>
                                            {this.renderInput("title", "text", "Nom")}
                                        </div>
                                    </div>
                                </Row>

                                {this.renderButton("Save")}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CampaignCreate;
