import React, { Component } from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import RequestDetail from "../../../components/App/RequestDetail";

class TestDriveRequestShow extends Component {
  state = {
    testDriveRequest: {},
    errors: {},
    loaded: false,
    itemLogs: []
  };

  apiPath = "/test_drive_requests";

  testDriveRequestHelper;
  itemLogHelper;

  constructor(props) {
    super(props);
    this.testDriveRequestHelper = new ApiResourceHelper(this.apiPath);
    this.itemLogHelper = new ApiResourceHelper("/item_logs");
  }

  componentDidMount = () => {
    this.getTestDriveRequest();
  };

  getTestDriveRequest = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: testDriveRequest } = await this.testDriveRequestHelper.findOne(
        `${this.apiPath}/${id}`
      );

      const {data: itemLogs} = await this.itemLogHelper.findBy(
          [{key: 'testDriveRequest', value: `${this.apiPath}/${id}`}]
      );

      this.setState({ testDriveRequest, loaded: true, itemLogs: itemLogs['hydra:member']});
    } catch (ex) {}
  };

  getPrevious = () => {
    const {history} = this.props;
    history.goBack();
  }

  render() {
    const { testDriveRequest, loaded, itemLogs } = this.state;

    console.log("testDriveRequest", testDriveRequest);

    if (loaded) {
      return (
          <RequestDetail request={testDriveRequest} getPrevious={this.getPrevious} form={"test drive"} itemLogs={itemLogs}/>
      );
    }

    return null;
  }
}

export default TestDriveRequestShow;
