import React from 'react';
import ReactECharts from "echarts-for-react";
import { themeColors } from '../../../falcon/src/helpers/utils';
import {LinearGradient} from "echarts/lib/util/graphic";

const EChartLeadsByModelAndDay = ({title, subTitle, data, theme}) => {
    console.log('EChartLeadsByModelAndDay.data', data);
    const option = {
        title: {
            text: title,
            subtext: subTitle,
            textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: { color: '#ffffff' },
        },
        xAxis: {
            type: 'category',
            data: data.labels,
            axisLabel: {
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            }
        },
        series: [
            {
                data: data.values,
                type: 'line',
                smooth: true,
                itemStyle: { color: themeColors.info },
                areaStyle: {
                    color: new LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: themeColors.primary
                    }, {
                        offset: 1,
                        color: themeColors.primary
                    }])
                },
            }
        ]
    };

    return <ReactECharts
        option={option}
        style={{height: 420}}
        theme={theme}
    />;
}

export default EChartLeadsByModelAndDay;