import React, {Component} from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";

class ShowroomShow extends Component {
    state = {
        showroom: {},
        errors: {},
        loaded: false,
    };

    apiPath = "/showrooms";

    showroomHelper;

    constructor(props) {
        super(props);
        this.showroomHelper = new ApiResourceHelper(this.apiPath);
    }

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    componentDidMount = () => {
        this.getShowroom();
    };

    getShowroom = async () => {
        try {
            const id = this.props.match.params.id;
            const {data: showroom} = await this.showroomHelper.findOne(
                `${this.apiPath}/${id}`
            );

            this.setState({showroom, loaded: true});
        } catch (ex) {
        }
    };

    render() {
        const {showroom, loaded} = this.state;
        console.log("showroom", showroom);

        if (loaded) {
            return (
                <div className="card" style={{width: "100%"}}>
                    <div className="card-body">
                        <h5 className="card-title">{showroom.name}</h5>

                        <table className="table">
                            <tbody>
                            <tr>
                                <th scope="col">Nom</th>
                                <td>{showroom.name}</td>
                            </tr>

                            <tr>
                                <th scope="col">Ville</th>
                                <td>{showroom.city.name}</td>
                            </tr>

                            <tr>
                                <th scope="col">Téléphone</th>
                                <td>{showroom.telephone}{showroom.telephoneSecondary && `/${showroom.telephoneSecondary}`}</td>
                            </tr>

                            <tr>
                                <th scope="col">Adresse</th>
                                <td>{showroom.address}</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                  <div className="card-footer">
                      <div className="btn-group">
                          <button onClick={() => this.getPrevious()} className="btn btn-secondary">
                              Retour
                          </button>

                          <a href={`/showrooms/${showroom.id}/edit`} className="btn btn-primary">
                              Editer showroom
                          </a>
                      </div>
                  </div>
                </div>
            );
        }

        return null;
    }
}

export default ShowroomShow;
