import React, {Component} from "react";
import queryString from "query-string";
import ApiResourceHelper from "../../helpers/ApiResourceHelper";
import {toast} from "react-toastify";
import DateDisplay from "../../components/DateDisplay";
import {Dropdown} from "react-bootstrap";
import {FaEllipsisH, FaEye} from "react-icons/all";
import {Link} from "react-router-dom";
import Table from "../../components/Table";
import {getDate} from "../../utils/utils";

class InformationRequestList extends Component {
    state = {
        loading: true,
        currentPage: 1,
        pageSize: 30,
        brand: null,
        informationRequests: [],
        total: 0,
        search: {},
        sortColumn: {path: "dateSorted", order: "desc"},
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()]
    };

    genders = {
        male: 'Homme',
        Mr: 'Homme',
        female: 'Femme',
        Mme: 'Femme',
    };

    columns = [
        {
            path: "dateSorted",
            label: "Date",
            content: (request) => (
                <DateDisplay date={request.createdAt}/>
            ),
        },
        {
            path: 'gender',
            content: (request) => <React.Fragment>{this.genders[request.gender] ? this.genders[request.gender] : request.gender}</React.Fragment>,
            label: "Civilité"
        },
        {
            path: 'nameSorted',
            content: (request) => <React.Fragment>{request.firstName} {request.lastName}</React.Fragment>,
            label: "Nom"
        },
        {
            path: "email",
            label: "Email",
        },
        {
            key: "actions",
            content: (request) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="transparent"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}>
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link to={`/information_requests/${request.id}`}
                                      className={"btn btn-light btn-block shadow-sm text-left"}>
                                    <FaEye/> Voir
                                </Link>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //this.getModels();
        this.getInformationRequests();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;

        let params = queryString.parse(location.search);

        if (prevProps.location.key !== location.key) {
            //this.getModels();
            this.getInformationRequests();
        }

        console.log('prevState.dateRange', prevState.dateRange);
        console.log('state.dateRange', this.state.dateRange);

        if ((this.state.dateRange === null)
            || (!prevState.dateRange || (
                prevState.dateRange[0] !== this.state.dateRange[0]
                || prevState.dateRange[1] !== this.state.dateRange[1]))
            || (this.state.selectedPlatform !== prevState.selectedPlatform)
            || (this.state.selectedCampaign !== prevState.selectedCampaign)
            || (this.state.currentPage !== prevState.currentPage)
            || (this.state.search !== prevState.search)
        ) {
            this.getInformationRequests();
        }
    }

    getInformationRequests = async () => {
        this.setState({loading: true});
        const {dateRange, currentPage, search} = this.state;
        const {location} = this.props;

        let params = queryString.parse(location.search);
        const apiHelper = new ApiResourceHelper(`/information_requests`);
        const queryParams = this.getFilters(params.brand_id, dateRange, currentPage, search);
        const {data} = await apiHelper.findBy(queryParams);
        const informationRequests = data["hydra:member"];

        const finalRequests = [];
        informationRequests.forEach((r) => {
            const date = new Date(r.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalRequests.push({
                ...r, dateSorted, nameSorted: `${r.firstName} ${r.lastName}`
            });
        });

        this.setState({informationRequests: finalRequests, total: data["hydra:totalItems"], loading: false});
    };

    getFilters(brandId, dateRange, currentPage, search) {
        const queryParams = [];

        if (brandId) {
            queryParams.push({key: "brand", value: brandId});
        }

        if (dateRange && dateRange[0]) {
            queryParams.push({key: 'createdAt%5Bafter%5D', value: getDate(dateRange[0])});
        }

        if (dateRange && dateRange[1]) {
            queryParams.push({key: 'createdAt%5Bbefore%5D', value: `${getDate(dateRange[1])} 23:59`});
        }

        if (currentPage) {
            queryParams.push({key: 'page', value: currentPage});
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams.push({key: filter[0], value: filter[1]})
            })
        }
        return queryParams;
    }

    getExportFilters(dateRange, search) {
        const queryParams = {};

        if (dateRange && dateRange[0]) {
            queryParams['from'] = getDate(dateRange[0]);
        }

        if (dateRange && dateRange[1]) {
            queryParams['to'] = `${getDate(dateRange[1])} 23:59`;
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams[filter[0]] = filter[1];
            });
        }
        return queryParams;
    }

    handleDelete = async (request) => {
        const originalRequests = this.state.informationRequests;

        const informationRequests = originalRequests.filter((b) => b.id !== request.id);
        this.setState({informationRequests});

        try {
            await this.apiHelper.remove(`${this.apiPath}/${request.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This request has already been removed.");

            this.setState({informationRequests: originalRequests});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    handleDateRangeChange = (dateRange) => {
        console.log("dateRange", dateRange);

        this.setState({
            currentPage: 1,
            dateRange
        });
    }

    setPage = (page) => {
        this.setState({currentPage: page});
    }

    onTextChange = ( event ) => {
        console.log('on.event', event);

        this.setState({search: {...this.state.search, [event.name]: event.value}});
        console.log('on.search', Object.entries(this.state.search));
    }

    onRequestChange = ( event ) => {
        console.log('onRequestChange.event', event);
        const value = event.target.value !== '---' ? event.target.value : null;
        this.setState({search: {...this.state.search, request: value}});
    }

    render() {
        const {informationRequests, total, currentPage, pageSize, sortColumn, dateRange, loading, search} = this.state;

        let title = 'Demande information';
        let tableName = 'Demande information';

        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={informationRequests}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    tableName={tableName}
                    tableTitle={title}
                    onSearchChange={this.handleSearchChange}
                    totalCount={total}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    dateRange={dateRange}
                    handleDateRangeChange={this.handleDateRangeChange}
                    loading={loading}
                    extraFilters={[
                        {name: "email", type: "text", label: "Email", onChange: this.onTextChange},
                        {name: "firstName", type: "text", label: "Prénom", onChange: this.onTextChange},
                        {name: "lastName", type: "text", label: "Nom", onChange: this.onTextChange},
                    ]}
                    exportEndpoint={"/information_request_exports"}
                    exportFilename={"demandes_information.csv"}
                    exportFilters={this.getExportFilters(dateRange, search)}
                />
            </React.Fragment>
        );
    }
}

export default InformationRequestList;
