import React from 'react';
import ReactECharts from "echarts-for-react";
import { themeColors } from '../../../falcon/src/helpers/utils';
import {LinearGradient} from "echarts/lib/util/graphic";

const EChartLineHeat = ({title, subTitle, data, theme}) => {
    console.log('title', title)
    console.log('theme', theme);
    console.log('data', data);

    /* legends: brochure, devis, essai,
     * categories: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
     * series: [
            {
                name: '2011年',
                type: 'bar',
                data: [18203, 23489, 29034, 104970, 131744, 630230]
            },
            {
                name: '2012年',
                type: 'bar',
                data: [19325, 23438, 31000, 121594, 134141, 681807]
            }
        ]
     */
    const option = {
        title: {
            text: title,
            subtext: subTitle,
            textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: { color: '#ffffff' },
        },
        xAxis: {
            type: 'category',
            data: data.labels,
            axisLabel: {
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            },
            boundaryGap: [0, '100%']
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            }
        },
        series: [
            {
                data: data.values,
                type: 'line',
                smooth: true,
                itemStyle: { color: 'rgb(255, 70, 131)' },
                areaStyle: {
                    color: new LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: 'rgb(255, 158, 68)'
                    }, {
                        offset: 1,
                        color: 'rgb(255, 70, 131)'
                    }])
                },
            }
        ]
    };

    return <ReactECharts
        option={option}
        style={{height: 420}}
        theme={theme}
    />;
}

export default EChartLineHeat;