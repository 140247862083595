import React, {useEffect, useState} from "react";
import TableHeader from "./Header";
import TableBody from "./Body";
import {Card, Col} from "react-bootstrap";
import CardBody from "reactstrap/es/CardBody";
import {Link} from "react-router-dom";
import {FaPlus} from "react-icons/all";
import {Row} from "reactstrap";
import Search from "../Form/Search";
import Pagination from "../Pagination";
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import "../../assets/styles/DateRangePicker.css";
import "../../assets/styles/Calendar.css";
import {useCompare} from "../../hooks/custom_hooks";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Select from "../Form/Select";
import ExportButton from "../App/ExportButton";

const Table = ({
                   data,
                   columns,
                   sortColumn,
                   onSort,
                   createLink,
                   onSearchChange,
                   totalCount,
                   tableName,
                   tableTitle,
                   onPageChange,
                   currentPage,
                   pageSize,
                   hideHelper,
                   handleDateRangeChange,
                   dateRange: originalDateRange,
                   loading,
                   extraFilters,
                   exportEndpoint,
                   exportFilename,
                   exportFilters,
                   ...props
               }) => {

        const [dateRange, onDateRangeChange] = useState(originalDateRange);

        const hasItemIdChanged = useCompare(dateRange);

        useEffect(() => {
            if (hasItemIdChanged) {
                console.log('Table.dateRange', dateRange)
                handleDateRangeChange(dateRange)
            }
        }, [hasItemIdChanged]);

        return (
            <React.Fragment>
                <Card className={"mb-2"}>
                    <CardBody>
                        <Row>
                            <Col>
                                <h3>{tableTitle}</h3>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={3} md={3} xs={6}>
                                <div className="form-group mt-3 mb-3">
                                    {/*<label>Date</label><br/>*/}
                                    <DateRangePicker
                                        onChange={onDateRangeChange}
                                        value={dateRange}
                                        locale={"fr-FR"}
                                        maxDate={new Date()}
                                        clearIcon={null}
                                    />
                                </div>
                            </Col>

                            {extraFilters && extraFilters.map((filter) => <Col lg={2} md={3} xs={6}>
                                {filter.type === "select" && (<Select name={filter.name} label={filter.label} options={filter.options} onChange={filter.onChange}/>)}
                                {filter.type === "text" && (<Search name={filter.name} label={filter.label} onChange={filter.onChange}/>)}
                            </Col>)}

                        </Row>

                        {!loading && (
                            <React.Fragment>
                                {!hideHelper && (
                                    <Row>
                                        <Col>
                                            <p className="shadow-sm p-3 mb-3 bg-light rounded">
                                                Il y a <strong
                                                className="text-primary"> {totalCount} {tableName} </strong> dans la base
                                                de données.
                                            </p>
                                        </Col>
                                    </Row>
                                )}

                                <Row className={'d-flex justify-content-end mb-3'}>

                                    {exportEndpoint && <ExportButton endpoint={exportEndpoint} filename={exportFilename} filters={exportFilters}/>}

                                    {createLink && (
                                        <Link to={createLink} className="btn btn-falcon-default btn-sm mr-2">
                                            <FaPlus/> Ajouter
                                        </Link>
                                    )}
                                </Row>
                                <table className="table table-striped table-sm">
                                    <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort}/>
                                    <TableBody data={data} columns={columns}/>
                                </table>
                            </React.Fragment>
                        )}

                        {loading && (
                            <React.Fragment>
                                <div className={"d-flex justify-content-center"}>
                                    <Loader
                                        type="Bars"
                                        color="#2c7be5"
                                        height={100}
                                        width={100}
                                    />
                                </div>
                            </React.Fragment>
                        )}

                    </CardBody>
                </Card>

                {!loading && (
                    <Row>
                        <Col>
                            <Pagination
                                itemsCount={totalCount}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onPageChange={onPageChange}
                            />
                        </Col>
                    </Row>
                )}

            </React.Fragment>
        );
    }
;

export default Table;
