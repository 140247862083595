import React, {Component} from "react";
import {Link} from "react-router-dom";
import Table from "../../../components/Table";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import queryString from "query-string";
import _ from "lodash";
import paginate from "../../../utils/paginate";
import DateDisplay from "../../../components/DateDisplay";
import {getDate} from "../../../utils/utils";

class AbandonedRequestList extends Component {
    state = {
        requests: [],
        total: 0,
        currentPage: 1,
        pageSize: 30,
        sortColumn: {path: "createdAt", order: "desc"},
        searchQuery: "",
        brand: {},
        isBrandLoaded: false,
        brochures: [],
        areBrochuresLoaded: false,
        quotes: [],
        areQuotesLoaded: false,
        testDrives: [],
        areTestDrivesLoaded: false,
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()],
    };

    componentDidMount() {
        this.getBrand();

        this.getTestDrives();
        this.getQuotes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.key !== this.props.location.key) {
            this.getBrand();
            this.getTestDrives();
            this.getQuotes();
        }

        if ((this.state.dateRange === null)
            || (!prevState.dateRange || (
                prevState.dateRange[0] !== this.state.dateRange[0]
                || prevState.dateRange[1] !== this.state.dateRange[1]))) {

            this.getTestDrives();
            this.getQuotes();
        }
    }

    getBrand = async () => {
        try {
            const params = queryString.parse(this.props.location.search);
            const id = params.brand_id;

            const apiHelper = new ApiResourceHelper("/brands");
            const {data: brand} = await apiHelper.findOne(
                `/brands/${id}`
            );

            this.setState({brand, isBrandLoaded: true});
        } catch (ex) {
            //console.log('getbrandEx', ex);
        }
    };

    /* get test drives */
    getTestDrives = async () => {
        try {
            const params = queryString.parse(this.props.location.search);
            const id = params.brand_id;

            const testDriveHelper = new ApiResourceHelper("/test_drive_requests");

            const queryParams = [
                {key: "model.brand", value: id},
                {key: "showroom", value: "null"},
            ];

            const {dateRange} = this.state;
            if (dateRange && dateRange[0]) {
                queryParams.push({key: 'createdAt%5Bafter%5D', value: getDate(dateRange[0])});
            }

            if (dateRange && dateRange[1]) {
                queryParams.push({key: 'createdAt%5Bbefore%5D', value: `${getDate(dateRange[1])} 23:59`});
            }

            const {data} = await testDriveHelper.findBy(queryParams);
            this.setState({testDrives: data["hydra:member"], areTestDrivesLoaded: true, total: this.state.total + data["hydra:totalItems"]});
        } catch (ex) {
            //console.log('getTestDrives Ex', ex);
        }
    }

    /* get quotes */
    getQuotes = async () => {
        try {
            const params = queryString.parse(this.props.location.search);
            const id = params.brand_id;

            const quoteHelper = new ApiResourceHelper("/quote_requests");
            const {data} = await quoteHelper.findBy([
                {key: "model.brand", value: id},
                {key: "showroom", value: "null"},
            ]);
            this.setState({quotes: data["hydra:member"], areQuotesLoaded: true, total: this.state.total + data["hydra:totalItems"]});
        } catch (ex) {
            //console.log('getQuotes Ex', ex);
        }
    }

    getAllAbandons = () => {
        const {
            quotes, testDrives,
        } = this.state;

        const items = [];

        quotes.forEach((item) => {
            const date = new Date(item.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;
            items.push({
                ...item,
                key: `quote-${item.id}`,
                form: "devis",
                dateSorted, nameSorted: `${item.firstName} ${item.lastName}`
            });
        });

        testDrives.forEach((item) => {
            const date = new Date(item.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;
            items.push({
                ...item,
                key: `testdrive-${item.id}`,
                form: "essai",
                dateSorted, nameSorted: `${item.firstName} ${item.lastName}`
            });
        });

        return _.orderBy(items, ["createdAt"], ["desc"]);
    }

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    handleSearchChange = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    getPagedData = () => {
        const {
            currentPage,
            pageSize,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = this.getAllAbandons();
        console.log('all_abandons', filtered);

        if (searchQuery) {
            filtered = this.getAllAbandons().filter((b) =>
                b.firstName.toLowerCase().startsWith(searchQuery)
                || b.lastName.toLowerCase().startsWith(searchQuery)
                || b.email.toLowerCase().startsWith(searchQuery)
                || b.telephone.toLowerCase().startsWith(searchQuery)
                || b.form.toLowerCase().startsWith(searchQuery)
            );
        }

        //console.log('sortColumn', sortColumn);

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const abandons = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: abandons};
    };

    getExportedData = () => {
        const items = this.getAllAbandons();
        const exportedData = [];

        items.forEach((item, index) => {
            const date = new Date(item.createdAt);

            const newItem = {
                date: `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${date.getHours()}:${date.getMinutes()}`,
                civilite: this.genders[item.gender],
                nom: `${item.firstName} ${item.lastName}`,
                email: item.email,
                telephone: item.telephone,
                marque: item.model && item.model.brand ? item.model.brand.name : '',
                modele: item.model ? item.model.name : '',
                ville: item.city.name,
                showroom: item.showroom ? item.showroom.name : '',
                form: item.form,
                utm_source: item.utmSource,
                utm_campaign: item.utmCampaign,
                utm_medium: item.utmMedium,
                jaccepte_activite_marketing: item.acceptedMarketingActivity ? 'oui' : 'non',
            }

            exportedData.push(newItem)
        });

        return exportedData;
    }

    genders = {
        mister: 'Homme',
        lady: 'Femme',
        miss: 'Demoiselle',
    };

    columns = [
        {
            path: "dateSorted",
            label: "Date",
            content: (item) => <DateDisplay date={item.createdAt}/>
        },
        {
            path: "nameSorted",
            label: "Nom",
            content: (item) => (<span>{`${item.firstName} ${item.lastName}`}</span>)
        },
        {
            path: "email",
            label: "Email",
        },
        {
            path: "telephone",
            label: "Téléphone",
        },
        {
            path: "gender",
            label: "Civilité",
            content: (item) => (<span>{this.genders[item.gender]}</span>)
        },
        {
            path: "form",
            label: "Formulaire",
        },
        {
            key: "actions",
            content: (abandon) => (
                <React.Fragment>
                    <Link to={`/quote_requests/${abandon.id}`}
                          className="btn btn-light shadow-sm btn-sm">
                        Voir
                    </Link>
                </React.Fragment>
            ),
        },
    ];

    handleDateRangeChange = (dateRange) => {
        console.log("dateRange", dateRange);

        this.setState({
            searchQuery: "",
            currentPage: 1,
            dateRange
        });
    }

    render() {
        const {total, currentPage, pageSize, sortColumn, dateRange} = this.state;
        const {user} = this.props;

        //console.log("user", user);

        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={this.getAllAbandons()}
                    exportedData={[]}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    tableTitle={"Liste des abandonés"}
                    tableName={"abandonés"}
                    totalCount={total}
                    searchQuery={this.state.searchQuery}
                    onSearchChange={this.handleSearchChange}
                    itemsCount={total}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                    dateRange={dateRange}
                    handleDateRangeChange={this.handleDateRangeChange}
                />
            </React.Fragment>
        );
    }
}

export default AbandonedRequestList;
