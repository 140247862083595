import Joi from "joi-browser";
import {Component} from "react";

class ChatbotEdit extends Component{
  componentDidMount = () => {
    this.populatechatbotLead();
  };

  populatechatbotLead = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: chatbotLead } = await this.chatbotLeadHelper.findOne(
        `/leads//${id}`
      );
      console.log("chatbotLead", chatbotLead);

      this.setState({ data: this.mapToViewChatbotLead(chatbotLead) });

      console.log('arrived here');
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.chatbotLeadHelper.update(`/leads/${id}`, this.state.data);
    this.props.history.replace("/leads");
  };

  mapToViewChatbotLead = (chatbotLead) => {
    return {
      id: chatbotLead.id,
      name: chatbotLead.name,
      email: chatbotLead.email,
      telephone: chatbotLead.telephone,
    };
  };
}

export default ChatbotEdit;
