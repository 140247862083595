import React from 'react';

const DateDisplay = ({date}) => {
    const theDate = new Date(date);
    const dateText = `${ (`0${theDate.getDate()}`).slice(-2) }/${ (`0${theDate.getMonth()+1}`).slice(-2) }/${theDate.getFullYear()}`;

    return (
        <React.Fragment>
            {dateText}
        </React.Fragment>
    );
}

export default DateDisplay;
