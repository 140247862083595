import React, {useEffect} from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";
import {Col, Row} from "react-bootstrap";
import "../../assets/styles/DateRangePicker.css";
import "../../assets/styles/Calendar.css";
import {useState} from "react";
import {useCompare} from "../../hooks/custom_hooks";

const MDateRangePicker = ({originalDateRange, handleDateRangeChange}) => {
    const [dateRange, onDateRangeChange] = useState(originalDateRange);
    const hasItemIdChanged = useCompare(dateRange);

    useEffect(() => {
        console.log('MDateRangePicker.dateRange', dateRange);
        if(hasItemIdChanged) {
            console.log('MDateRangePicker.dateRange', dateRange)
            handleDateRangeChange(dateRange)
        }
    }, [hasItemIdChanged]);

    return (
        <Row>
            <Col style={{display: "flex", justifyContent: "end"}}>
                <div style={{backgroundColor: "#fff"}}>
                    <DateRangePicker
                        onChange={onDateRangeChange}
                        value={dateRange}
                        locale={"fr-FR"}
                        maxDate={new Date()}
                        clearIcon={null}
                    />
                </div>
            </Col>
        </Row>
    );
}

export default MDateRangePicker;