import React, {Component} from "react";
import ApiResourceHelper from "../../helpers/ApiResourceHelper";
import Date from "../../components/DateTimeDisplay";
import moment from "moment/moment";

class PrePurchaseRequestShow extends Component {
    state = {
        request: {},
        errors: {},
        loaded: false,
        campaign: null,
        tab: 'info',
        itemLogs: []
    };

    genders = {
        male: 'Homme',
        Mr: 'Homme',
        female: 'Femme',
        Mme: 'Femme',
    };

    apiPath = "/pre_purchase_requests";

    requestHelper;
    itemLogHelper;

    constructor(props) {
        super(props);
        this.requestHelper = new ApiResourceHelper(this.apiPath);
        this.itemLogHelper = new ApiResourceHelper("/item_logs");
    }

    componentDidMount = () => {
        this.getrequest();
    };

    getrequest = async () => {
        try {
            const id = this.props.match.params.id;
            const {data: request} = await this.requestHelper.findOne(
                `${this.apiPath}/${id}`
            );
            const {data: campaign} = await this.requestHelper.findOne(
                `${request.campaign['@id']}`
            );

            const {data: itemLogs} = await this.itemLogHelper.findBy(
                [{key: 'prePurchaseRequest', value: `${this.apiPath}/${id}`}]
            );

            this.setState({request, campaign, loaded: true, itemLogs: itemLogs['hydra:member']});

        } catch (ex) {
        }
    };

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    render() {
        const {request, loaded, campaign, itemLogs} = this.state;
        const columns = [
            {
                path: "processedAt",
                label: "traité à",
                content: (request) =>
                    (moment(request.processedAt).format('lll'))
            },
            {
                path: "resContent",
                label: "Response status"
            },
            {
                path: "resContent",
                label: "Response Content"
            },
            {
                path: "webhelpStatus",
                label: "Webhelp Status"
            },
            {
                path: "validationLog",
                label: "validation Log",
            },
            {
                path: "logs",
                label: "Log"
            },
        ];
        console.log("request", request);
        // console.log("request", campaign);


        if (loaded) {
            return (
                <div className="card" style={{width: "100%"}}>
                    <div className="card-body">
                        <h5 className="card-title" style={{textTransform: "capitalize"}}>Fiche De Précommande</h5>
                        <div>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "info" && "active"}`} href="#"
                                       onClick={() => this.setState({tab: "info"})}>Info</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "feedback" && "active"}`} href="#"
                                       onClick={() => this.setState({tab: "feedback"})}>Feedback</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "log" && "active"}`} href="#"
                                       onClick={() => this.setState({tab: "log"})}>Log</a>
                                </li>
                            </ul>
                            {this.state.tab === "info" && <table className="table">
                                <tbody>
                                <tr>
                                    <th scope="col">Date</th>
                                    <td>
                                        <Date date={request.createdAt}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col">Civilité</th>
                                    <td>{this.genders[request.gender] ? this.genders[request.gender] : request.gender}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Nom</th>
                                    <td>{request.firstName} {request.lastName} </td>
                                </tr>
                                <tr>
                                    <th scope="col">Email</th>
                                    <td>{request.email}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Téléphone</th>
                                    <td>{request.telephone}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Ville</th>
                                    <td>{(request.showroom && request.showroom.city) && request.showroom.city.name}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Concessionnaire</th>
                                    <td>{request.showroom && request.showroom.name}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Marque</th>
                                    <td>{campaign.model && campaign.model.brand.name}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Modèle</th>
                                    <td>{campaign.model && campaign.model.name}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Version</th>
                                    <td>{request.trim}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Couleur</th>
                                    <td>{request.color}</td>
                                </tr>

                                <tr>
                                    <th scope="col">UTM Campaign</th>
                                    <td>{request.utmCampaign ? request.utmCampaign : 'direct'}</td>
                                </tr>

                                <tr>
                                    <th scope="col">UTM Source</th>
                                    <td>{request.utmSource ? request.utmSource : 'direct'}</td>
                                </tr>

                                <tr>
                                    <th scope="col">UTM Medium</th>
                                    <td>{request.utmMedium ? request.utmMedium : 'direct'}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Transaction ID</th>
                                    <td>{request.transactionId}</td>
                                </tr>
                                <tr style={{overflow: "scroll", height: "200px !important"}}>
                                    <th scope="col">Logs</th>
                                    <div style={{height: "200px !important"}}>

                                        <td style={{whiteSpace: 'pre-wrap'}}>{request.log}</td>
                                    </div>
                                </tr>
                                </tbody>
                            </table>}
                            {this.state.tab === "feedback" && <div>
                                {request.feedback && <table className="table">
                                    <tbody>
                                    <tr>
                                        <th scope="col">Webhelp ID</th>
                                        <td>{request.feedback.webhelpId}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Commentaite</th>
                                        <td>{request.feedback.comment}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Date d'appel</th>
                                        <td><Date date={request.feedback.callDate}/></td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Heure d'appel</th>
                                        <td>{request.feedback.callHour}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Statut d'appel</th>
                                        <td>{request.feedback.callStatus}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Action</th>
                                        <td>{request.feedback.action}</td>
                                    </tr>
                                    </tbody>
                                </table>}

                            </div>}
                            {this.state.tab === "log" && <table className="table">
                                <thead>
                                <tr>
                                    {
                                        columns.map((column) => (
                                            <th key={column.path || column.key}>{column.label}</th>
                                        ))
                                    }
                                </tr>

                                </thead>
                                <tbody>
                                {
                                    itemLogs && itemLogs.map((request) => (
                                        <tr key={request.id}>
                                            <td>{moment(request.processedAt).format('lll')}</td>
                                            <td>{request.resStatus}</td>
                                            <td>{request.resContent}</td>
                                            <td>{request.webhelpStatus}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{request.validationLog}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{request.log}</td>
                                        </tr>
                                    ))
                                }

                                </tbody>
                            </table>}
                        </div>

                    </div>
                    <div className="card-footer">
                        <div className={"btn-group"}>
                            <button onClick={() => this.getPrevious()} className="btn badge badge-secondary badge-pill">
                                Retour
                            </button>
                            {/*<Link href={`/quote_requests/${request.id}/edit`} className="btn btn-primary">*/}
                            {/*    Edit Quote*/}
                            {/*</Link>*/}
                        </div>

                    </div>
                </div>
            );
        }

        return null;
    }
}

export default PrePurchaseRequestShow;
