import axios from "axios";
import { toast } from "react-toastify";
import apiConfig from "../../config/apiConfig.json";

//export const endPoint = apiConfig.base;
export const endPoint = process.env.REACT_APP_BASE_API;
//console.log("endpoint", endPoint);

//axios.defaults.headers.common["Accept"] = "Application/json";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    console.log("error", error);
    toast.warn("An unexpected error occurred.");
  }

  return Promise.reject(error);
});

export function setJwt(jwt) {
  //axios.defaults.headers.common["x-auth-token"] = jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
}

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setJwt,
  endPoint,
};

export default http;
