import "./App.css";
import React, {Component} from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "react-datetime/css/react-datetime.css";
import "react-image-lightbox/style.css";
import RouteList from "./components/App/RouteList";
import ApiResourceHelper from "./helpers/ApiResourceHelper";
import SidebarTwo from "./components/App/SidebarTwo";
import authHelper from "./helpers/AuthHelper";
import HorizontalNavbar from "./components/App/HorizontalNavbar";
import ThemeHelper from "./helpers/ThemeHelper";

class App extends Component {
    state = {
        theme: "light",
        style: "theme.css",
        user: {},
        brands: [],
        mobileNavbarOpen: false
    };

    setMobileNavbarOpen = () => {
        this.setState({mobileNavbarOpen: !this.state.mobileNavbarOpen});
    }

    componentDidMount() {
        this.getCurrentTheme();
        this.getCurrentUser();
        this.getBrands();
    }

    getBrands = async () => {
        const brandHelper = new ApiResourceHelper("/brands");
        const {data} = await brandHelper.findAll();
        console.log('app_brands', data);
        this.setState({brands: data["hydra:member"]});
    };

    getCurrentUser = () => {
        const user = authHelper.getCurrentUser();
        this.setState({user});
    };

    getCurrentTheme = () => {
        const themeHelper = new ThemeHelper();
        const theme = themeHelper.getTheme();
        this.setState({theme});

        switch (theme) {
            case 'light':
                this.setState({style: 'theme.css'});
                break;
            case 'dark':
                this.setState({style: 'theme-dark.css'});
                break;
        }
    }

    handleThemeChange = (theme) => {
        console.log('handleThemeChange called');
        const themeHelper = new ThemeHelper();
        themeHelper.setTheme(theme);
        this.setState({theme});

        switch (theme) {
            case 'light':
                this.setState({style: 'theme.css'});
                break;
            case 'dark':
                this.setState({style: 'theme-dark.css'});
                break;
        }
    }

    render() {
        const {user, brands, theme} = this.state;
        //console.log("app_user", user);
        //console.log("app_brands", brands);
        console.log('theme', theme);

        return (
            <React.Fragment>
                <link rel="stylesheet" type="text/css" href={`/css/${this.state.style}`} />
                <div className="container-fluid">
                    <SidebarTwo user={user} brands={brands} currentBrand={brands[0]} mobileNavbarOpen={this.state.mobileNavbarOpen}/>
                    <div className="content">
                        <HorizontalNavbar theme={theme} onThemeChange={this.handleThemeChange} setMobileNavbarOpen={this.setMobileNavbarOpen}/>
                        <RouteList theme={theme} user={user} brands={brands}/>
                    </div>
                </div>
                <ToastContainer/>
            </React.Fragment>
        );
    }
}

export default App;
