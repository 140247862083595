import React, { Component } from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {FaTimes} from "react-icons/all";
import Swal from "sweetalert2";

class DocumentRemove extends Component {
    apiHelper;

    constructor(props) {
        super(props);
        this.apiHelper = new ApiResourceHelper("/documents");
    }

    componentDidMount = async () => {};

    onDelete = async () => {
        console.log('onDelete document');

        try {
            console.log('deleting documents');
            const {document, removeCallback} = this.props;
            removeCallback();
            //const iri = `/documents/${document.id}`;
            //await this.apiHelper.remove(iri);
        } catch (ex) {
            console.log('upload_ex', ex);
        }
    };

    render() {
        return (
            <div className="file btn btn-outline-danger sm-delete">
                <FaTimes onClick={() => {
                    Swal.fire({
                        title: 'Etes-vous sûr?',
                        text: 'Vous êtes sur le point de supprimer une brochure.',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Oui, je la supprime!',
                        cancelButtonText: 'Non, je la garde!'
                    }).then(result => {
                        if (result.isConfirmed) {
                            if (this.onDelete()) {
                                Swal.fire('Brochure supprimée!', 'La brochure a été bien supprimée.', 'success');
                            } else {
                                Swal.fire('Brochure non supprimée!', 'La brochure n\'a pas été supprimée à cause d\'une erreur système.', 'error');
                            }
                        }
                    });
                }} />
            </div>
        );
    }
}

export default DocumentRemove;
