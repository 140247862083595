import Joi from "joi-browser";
import CampaignCreate from "../CampaignCreate";

class CampaignEdit extends CampaignCreate {
  schema = { ...this.schema, id: Joi.number().required() };

  formTitle = "Modification de campagne";

  componentDidMount = () => {
    this.populateCampaign();
    this.getBrands();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.campaign.id && this.state.campaign.model && this.state.campaign.model.brand) {
      this.getModels(`/brands/${this.state.campaign.model.brand.id}`);
    }
  }

  populateCampaign = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: campaign } = await this.campaignHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("campaign", campaign);

      this.setState({ data: this.mapToViewModel(campaign), campaign });
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.campaignHelper.update(`${this.apiPath}/${id}`, this.state.data);
    this.props.history.replace("/campaigns");
  };

  mapToViewModel = (campaign) => {
    return {
      id: campaign.id,
      title: campaign.title,
      source: campaign.source,
      brand: `/brands/${campaign.model.brand.id}`,
      model: `/models/${campaign.model.id}`,
    };
  };
}

export default CampaignEdit;
