import React, {Component} from "react";
import queryString from "query-string";
import ApiResourceHelper from "../../helpers/ApiResourceHelper";
import {toast} from "react-toastify";
import DateDisplay from "../../components/DateDisplay";
import {Dropdown} from "react-bootstrap";
import {FaEllipsisH, FaEye} from "react-icons/all";
import {Link} from "react-router-dom";
import Table from "../../components/Table";
import {getDate} from "../../utils/utils";

class BotLeadList extends Component {
    state = {
        loading: true,
        currentPage: 1,
        pageSize: 30,
        brand: null,
        leads: [],
        total: 0,
        search: {},
        sortColumn: {path: "dateSorted", order: "desc"},
        selectedStatus: null,
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()]
    };

    genders = {
        mister: 'Homme',
        lady: 'Femme',
        miss: 'Demoiselle',
    };

    columns = [
        {
            path: "dateSorted",
            label: "Date",
            content: (request) => (
                <DateDisplay date={request.createdAt}/>
            ),
        },
        {
            path: "brand",
            label: "Type de demande",
            content: (request) => <React.Fragment>{request.brand && request.brand.name}</React.Fragment>,
        },
        {
            path: 'nameSorted',
            content: (request) => <React.Fragment>{request.name}</React.Fragment>,
            label: "Nom"
        },
        {
            path: "email",
            label: "Email",
        },
        {
            path: 'telephone',
            label: "Téléphone",
            content: (request) => <React.Fragment>{request.telephone}</React.Fragment>,
        },
        {
            path: "request",
            label: "Type de demande",
        },
        {
            path: "model",
            label: "Modèle",
        },
        {
            path: "trim",
            label: "Version",
        },
        {
            label: "webhelp status",
            content: (request) => (
                request.latestItemLog && request.latestItemLog.status === "ok"
                    ? <span className="badge badge-success">OK</span> :
                    request.latestItemLog && request.latestItemLog.status === "error" ?
                        <span className="badge badge-danger">ERROR</span> :
                        <span className="badge badge-warning">not processed or no log available</span>
            )
        },
        // {
        //     path: "processedAt",
        //     label: "traité à",
        //     content: (request) =>
        //         (moment(request.processedAt).format('lll'))
        // },
        // {
        //     label: "Response status",
        //     content: (request) =>
        //         (
        //             request.itemLogs.slice(-1)[0].resStatus
        //         )
        // },
        // {
        //     path: "resContent",
        //     label: "Response Content",
        //     content: (request) =>
        //         (
        //             request.itemLogs.slice(-1)[0].resContent
        //         )
        // },
        // {
        //     path: "webhelpStatus",
        //     label: "Webhelp Status",
        //     content: (request) =>
        //         (
        //             request.itemLogs.slice(-1)[0].webhelpStatus
        //         )
        // },
        {
            key: "actions",
            content: (request) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="transparent"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}>
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link to={`/bot_leads/${request.id}`}
                                      className={"btn btn-light btn-block shadow-sm text-left"}>
                                    <FaEye/> Voir
                                </Link>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //this.getModels();
        this.getLeads();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;

        let params = queryString.parse(location.search);

        if (prevProps.location.key !== location.key) {
            //this.getModels();
            this.getLeads();
        }

        console.log('prevState.dateRange', prevState.dateRange);
        console.log('state.dateRange', this.state.dateRange);

        if ((this.state.dateRange === null)
            || (!prevState.dateRange || (
                prevState.dateRange[0] !== this.state.dateRange[0]
                || prevState.dateRange[1] !== this.state.dateRange[1]))
            || (this.state.selectedPlatform !== prevState.selectedPlatform)
            || (this.state.selectedCampaign !== prevState.selectedCampaign)
            || (this.state.selectedStatus !== prevState.selectedStatus)
            || (this.state.currentPage !== prevState.currentPage)
            || (this.state.search !== prevState.search)
        ) {
            this.getLeads();
        }
    }

    getLeads = async () => {
        this.setState({loading: true});
        const {dateRange, currentPage, search, selectedStatus} = this.state;
        const {location} = this.props;

        let params = queryString.parse(location.search);
        const apiHelper = new ApiResourceHelper(`/bot_leads`);
        const queryParams = this.getFilters(params.brand_id, dateRange, currentPage, search, selectedStatus);
        const {data} = await apiHelper.findBy(queryParams);
        const leads = data["hydra:member"];

        const finalRequests = [];
        leads.forEach((r) => {
            const date = new Date(r.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalRequests.push({
                ...r, dateSorted, nameSorted: `${r.firstName} ${r.lastName}`
            });
        });

        this.setState({leads: finalRequests, total: data["hydra:totalItems"], loading: false});
    };

    getFilters(brandId, dateRange, currentPage, search, webHelpResult) {
        const queryParams = [];

        if (brandId) {
            queryParams.push({key: "brand", value: brandId});
        }

        if (dateRange && dateRange[0]) {
            queryParams.push({key: 'createdAt%5Bafter%5D', value: getDate(dateRange[0])});
        }

        if (webHelpResult) {
            queryParams.push({key: "latestItemLog.status", value: webHelpResult});
        }

        if (dateRange && dateRange[1]) {
            queryParams.push({key: 'createdAt%5Bbefore%5D', value: `${getDate(dateRange[1])} 23:59`});
        }

        if (currentPage) {
            queryParams.push({key: 'page', value: currentPage});
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams.push({key: filter[0], value: filter[1]})
            })
        }
        return queryParams;
    }

    getExportFilters(brandId, dateRange, currentPage, search) {
        const queryParams = {};

        if (brandId) {
            queryParams['brand'] = brandId;
        }

        if (dateRange && dateRange[0]) {
            queryParams['from'] = getDate(dateRange[0]);
        }

        if (dateRange && dateRange[1]) {
            queryParams['to'] = `${getDate(dateRange[1])} 23:59`;
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams[filter[0]] = filter[1];
            });
        }
        return queryParams;
    }

    handleDelete = async (request) => {
        const originalRequests = this.state.leads;

        const leads = originalRequests.filter((b) => b.id !== request.id);
        this.setState({leads});

        try {
            await this.apiHelper.remove(`${this.apiPath}/${request.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This request has already been removed.");

            this.setState({leads: originalRequests});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    handleDateRangeChange = (dateRange) => {
        console.log("dateRange", dateRange);

        this.setState({
            currentPage: 1,
            dateRange
        });
    }

    setPage = (page) => {
        this.setState({currentPage: page});
    }

    onTextChange = (event) => {
        console.log('on.event', event);

        this.setState({search: {...this.state.search, [event.name]: event.value}});
        console.log('on.search', Object.entries(this.state.search));
    }

    onRequestChange = (event) => {
        console.log('onRequestChange.event', event);
        const value = event.target.value !== '---' ? event.target.value : null;
        this.setState({search: {...this.state.search, request: value}});
    }

    onStatusChange = (event) => {
        this.setState({selectedStatus: event.target.value});
    }

    render() {
        const {leads, total, currentPage, pageSize, sortColumn, dateRange, loading, search} = this.state;

        const {location} = this.props;
        let params = queryString.parse(location.search);

        let title = 'Leads Bot';
        let tableName = 'leads bot';

        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={leads}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    tableName={tableName}
                    tableTitle={title}
                    onSearchChange={this.handleSearchChange}
                    totalCount={total}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    dateRange={dateRange}
                    handleDateRangeChange={this.handleDateRangeChange}
                    loading={loading}
                    extraFilters={[
                        {
                            name: "request", type: "select", label: false, options: [
                                {key: "Type de demande", value: "---"},
                                {key: "Demande de brochure", value: "Demande de brochure"},
                                {key: "Demande d\'essai", value: "Demande d\'essai"},
                                {key: "Demande rappel commercial", value: "Demande rappel commercial"},
                            ], onChange: this.onRequestChange
                        },
                        {
                            name: "status",
                            type: "select",
                            label: false,
                            options: [{key: "webhelpStatus", value: ""},
                                {key: "Success", value: "ok"},
                                {key: "Error", value: "error"},],
                            onChange: this.onStatusChange
                        },
                        {name: "model", type: "text", label: "Modèle", onChange: this.onTextChange},
                        {name: "trim", type: "text", label: "Version", onChange: this.onTextChange},
                        {name: "email", type: "text", label: "Email", onChange: this.onTextChange},
                        {name: "name", type: "text", label: "Nom", onChange: this.onTextChange},
                        {name: "telephone", type: "text", label: "Téléphone", onChange: this.onTextChange},
                    ]}
                    exportEndpoint={"/bot_lead_exports"}
                    exportFilename={"leads_bot.csv"}
                    exportFilters={this.getExportFilters(params.brand_id, dateRange, currentPage, search)}
                />
            </React.Fragment>
        );
    }
}

export default BotLeadList;
