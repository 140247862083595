import Joi from "joi-browser";
import {Component} from "react";

class BotLeadEdit extends Component{
  componentDidMount = () => {
    this.populateLead();
  };

  populateLead = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: Lead } = await this.LeadHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("Lead", Lead);

      this.setState({ data: this.mapToViewLead(Lead) });

      console.log('arrived here');
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.LeadHelper.update(`${this.apiPath}/${id}`, this.state.data);
    this.props.history.replace("/bot_leads");
  };

  mapToViewLead = (Lead) => {
    return {
      id: Lead.id,
      name: Lead.name,
      email: Lead.email,
      telephone: Lead.telephone,
    };
  };
}

export default BotLeadEdit;
