import React, {Component} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import Table from "../../../components/Table";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {Dropdown} from "react-bootstrap";
import {FaEllipsisH, FaStickyNote, FaTrash} from "react-icons/all";
import DateTimeDisplay from "../../../components/DateTimeDisplay";
import {getDate} from "../../../utils/utils";

class LeadGenList extends Component {
    state = {
        loading: true,
        leadGens: [],
        currentPage: 1,
        total: 0,
        pageSize: 30,
        sortColumn: {path: "firstName", order: "asc"},
        searchQuery: "",
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()],
    };

    apiPath = "/spreadsheet_data_imports";

    columns = [
        {
            path: "createdAt",
            label: "Date",
            content: (leadGen) => {
                if (leadGen.createdAt)
                    return <DateTimeDisplay date={leadGen.createdAt}/>
                else
                    return <span/>
            }
        },
        {
            path: "campaign.model.brand.name",
            label: "Marque",
        },
        {
            path: "campaign.model.name",
            label: "Modèle",
        },
        {
            path: "campaign.title",
            label: "Campagne",
        },
        {
            path: "source",
            label: "Source",
        },
        {
            path: "totalLeads",
            label: "Total des leads",
        },
        {
            path: "importedAt",
            label: "Importé",
            content: (leadGen) => (
                <span>{leadGen.importedAt ? 'oui':'non'}</span>
            )
        },
        {
            key: "actions",
            content: (leadGen) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="transparent"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}>
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link
                                    to={`/lead_gens/${leadGen.id}`}
                                    className="btn btn-light btn-block shadow-sm text-left"
                                >
                                    <FaStickyNote/> Fiche
                                </Link>
                            </Dropdown.Item>

                            {!leadGen.importedAt && (<Dropdown.Item className={"p-0"}>
                                <button
                                    className="btn btn-light btn-block shadow-sm text-left"
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Etes-vous sûr?',
                                            text: 'Vous êtes sur le point de supprimer un lead gen.',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Oui, je veux le supprimer!',
                                            cancelButtonText: 'Non, je le garde!'
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                if (this.handleDelete(leadGen)) {
                                                    Swal.fire('Lead gen supprimé!', 'L\'lead gen a été bien supprimé.', 'success');
                                                } else {
                                                    Swal.fire('Lead gen non supprimé!', 'On n\'a pas pu supprimer l\'lead gen.', 'error');
                                                }
                                            } else {
                                            }
                                        });
                                    }}
                                >
                                    <FaTrash/> Supprimer
                                </button>
                            </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    LeadGenHelper;

    constructor(props) {
        super(props);
        this.LeadGenHelper = new ApiResourceHelper(this.apiPath);
    }

    componentDidMount() {
        this.getLeadGens();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.state.dateRange === null)
            || (!prevState.dateRange || (
                prevState.dateRange[0] !== this.state.dateRange[0]
                || prevState.dateRange[1] !== this.state.dateRange[1]))
            || prevState.currentPage !== this.state.currentPage
            || prevState.searchQuery !== this.state.searchQuery
        ) {
            this.getLeadGens();
        }
    }

    getLeadGens = async () => {

        const queryParams = [];

        const {currentPage, dateRange} = this.state;

        if (currentPage) {
            queryParams.push({key: 'page', value: currentPage});
        }

        if (dateRange && dateRange[0]) {
            queryParams.push({key: 'createdAt%5Bafter%5D', value: getDate(dateRange[0])});
        }

        if (dateRange && dateRange[1]) {
            queryParams.push({key: 'createdAt%5Bbefore%5D', value: `${getDate(dateRange[1])} 23:59`});
        }

        const {data} = await this.LeadGenHelper.findBy(queryParams);
        this.setState({leadGens: data["hydra:member"], total: data["hydra:totalItems"], loading: false});
    };

    handleDelete = async (leadGen) => {
        const originalLeadGens = this.state.leadGens;

        const leadGens = originalLeadGens.filter((b) => b.id !== leadGen.id);
        this.setState({leadGens});

        try {
            await this.LeadGenHelper.remove(`${this.apiPath}/${leadGen.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This leadGen has already been removed.");

            this.setState({leadGens: originalLeadGens});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    handleSearchChange = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    handleDateRangeChange = (dateRange) => {
        console.log("dateRange", dateRange);

        this.setState({
            searchQuery: "",
            currentPage: 1,
            dateRange
        });
    }

    render() {
        const {currentPage, pageSize, sortColumn, dateRange, leadGens, total} = this.state;
        const {leadGen} = this.props;

        console.log("leadGen", leadGen);

        return (
            <React.Fragment>
                <Table
                    tableName={"lead gens"}
                    tableTitle={`Injections leads médias`}
                    columns={this.columns}
                    data={leadGens}
                    exportedData={[]}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    createLink="/lead_gens/create"
                    searchQuery={this.state.searchQuery}
                    onSearchChange={this.handleSearchChange}
                    totalCount={total}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    dateRange={dateRange}
                    hideHelper={true}
                    handleDateRangeChange={this.handleDateRangeChange}
                />
            </React.Fragment>

        );
    }
}

export default LeadGenList;
