import Joi from "joi-browser";
import QuoteRequestCreate from "../QuoteRequestCreate";

class QuoteRequestEdit extends QuoteRequestCreate {
  schema = { ...this.schema, id: Joi.number().required() };

  componentDidMount = () => {
    this.populateQuoteRequest();
  };

  populateQuoteRequest = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: quoteRequest } = await this.quoteRequestHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("quoteRequest", quoteRequest);

      this.setState({ data: this.mapToViewQuoteRequest(quoteRequest) });

      console.log('arrived here');
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.quoteRequestHelper.update(`${this.apiPath}/${id}`, this.state.data);
    this.props.history.replace("/quote_requests");
  };

  mapToViewQuoteRequest = (quoteRequest) => {
    return {
      id: quoteRequest.id,
      firstName: quoteRequest.firstName,
      lastName: quoteRequest.lastName,
      email: quoteRequest.email,
      telephone: quoteRequest.telephone,
      zipCode: quoteRequest.zipCode,
      acceptedMarketingActivity: quoteRequest.acceptedMarketingActivity,
    };
  };
}

export default QuoteRequestEdit;
