import React, {Component} from 'react';
import Date from "../../DateDisplay";
import DateTime from "../../DateTimeDisplay";
import moment from "moment";

class RequestDetail extends Component {
    state = {
        tab: "info"
    }

    render() {
        const {request, form, getPrevious, itemLogs} = this.props;
        const columns = [
            {
                path: "processedAt",
                label: "traité à",
                content: (request) =>
                    (moment(request.processedAt).format('lll'))
            },
            {
                path: "resContent",
                label: "Response status"
            },
            {
                path: "resContent",
                label: "Response Content"
            },
            {
                path: "webhelpStatus",
                label: "Webhelp Status"
            },
            {
                path: "validationLog",
                label: "validation Log",
            },
            {
                path: "logs",
                label: "Log"
            },
        ];
        return (
            <>
                <div className="card" style={{width: "100%"}}>
                    <div className="card-body">
                        <h5 className="card-title" style={{textTransform: "capitalize"}}>{form} details</h5>
                        <div>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "info" && "active"}`} href="#" onClick={() => this.setState({tab: "info"})}>Info</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "feedback" && "active"}`} href="#" onClick={() => this.setState({tab: "feedback"})}>Feedback</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "log" && "active"}`} href="#" onClick={() => this.setState({tab: "log"})}>Log</a>
                                </li>
                            </ul>
                            {this.state.tab === "info" && <div>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <td>
                                            <DateTime date={request.createdAt}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Civilité</th>
                                        <td>{request.gender}</td>
                                    </tr>

                                    <tr>
                                        <th scope="col">Nom</th>
                                        <td>{request.firstName} {request.lastName}</td>
                                    </tr>

                                    <tr>
                                        <th scope="col">Email</th>
                                        <td>{request.email}</td>
                                    </tr>
                                    {/*{form !== "brochure" && (*/}
                                    <tr>
                                        <th scope="col">Téléphone</th>
                                        <td>{request.telephone}</td>
                                    </tr>
                                    {/* )}*/}

                                    {/*{form !== "brochure" && (*/}
                                    <tr>
                                        <th scope="col">Ville</th>
                                        <td>{request.city && request.city.name}</td>
                                    </tr>
                                    {/*)}*/}

                                    <tr>
                                        <th scope="col">Modèle</th>
                                        <td>{request.model && request.model.name}</td>
                                    </tr>

                                    {/*{form !== "brochure" && (*/}
                                    <tr>
                                        <th scope="col">Showroom</th>
                                        <td>
                                            {request.showroom && (
                                                <React.Fragment>

                                                    <p>
                                                        <b>{request.showroom.name}</b><br/>
                                                        {request.showroom.telephone}{request.showroom.telephoneSecondary ? '/' + request.showroom.telephoneSecondary : ''}<br/>
                                                        {request.showroom.address}
                                                    </p>
                                                </React.Fragment>
                                            )}
                                        </td>
                                    </tr>
                                    {/*)}*/}

                                    <tr>
                                        <th scope="col">J'accepte l'activité du marketing</th>
                                        <td>{request.acceptedMarketingActivity ? 'oui' : 'non'}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>}
                            {this.state.tab === "feedback" && <div>
                                {request.feedback && <table className="table">
                                    <tbody>
                                    <tr>
                                        <th scope="col">Webhelp ID</th>
                                        <td>{request.feedback.webhelpId}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Commentaite</th>
                                        <td>{request.feedback.comment}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Date d'appel</th>
                                        <td><Date date={request.feedback.callDate} /></td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Heure d'appel</th>
                                        <td>{request.feedback.callHour}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Statut d'appel</th>
                                        <td>{request.feedback.callStatus}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Action</th>
                                        <td>{request.feedback.action}</td>
                                    </tr>
                                    </tbody>
                                </table>}

                            </div>}
                            {this.state.tab === "log" && <table className="table">
                                <thead>
                                <tr>
                                    {
                                        columns.map((column) => (
                                            <th key={column.path || column.key}>{column.label}</th>
                                        ))
                                    }
                                </tr>

                                </thead>
                                <tbody>
                                {
                                    itemLogs && itemLogs.sort(
                                        (a, b) => {
                                            return new Date(a.processedAt) - new Date(b.processedAt)
                                        }).map((request) => (
                                        <tr key={request.id}>
                                            <td>{new Date(request.processedAt)}</td>
                                            <td>{request.resStatus}</td>
                                            <td>{request.resContent}</td>
                                            <td>{request.webhelpStatus}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{request.validationLog}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{request.log}</td>
                                        </tr>
                                    ))

                                }

                                </tbody>
                            </table>}
                        </div>

                    </div>
                    <div className="card-footer">
                        <div className={"btn-group"}>
                            <button onClick={() => getPrevious()} className="btn badge badge-secondary badge-pill">
                                Retour
                            </button>
                            {/*<Link href={`/quote_requests/${request.id}/edit`} className="btn btn-primary">*/}
                            {/*    Edit Quote*/}
                            {/*</Link>*/}
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default RequestDetail;
