import React, { Component } from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import RequestDetail from "../../../components/App/RequestDetail";

class BrochureRequestShow extends Component {
  state = {
    brochureRequest: {},
    errors: {},
    loaded: false,
    itemLogs: []
  };

  apiPath = "/brochure_requests";

  brochureRequestHelper;
  itemLogHelper;

  constructor(props) {
    super(props);
    this.brochureRequestHelper = new ApiResourceHelper(this.apiPath);
    this.itemLogHelper = new ApiResourceHelper("/item_logs");
  }

  componentDidMount = () => {
    this.getBrochureRequest();
  };

  getBrochureRequest = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: brochureRequest } = await this.brochureRequestHelper.findOne(
        `${this.apiPath}/${id}`
      );

      const {data: itemLogs} = await this.itemLogHelper.findBy(
          [{key: 'brochureRequest', value: `${this.apiPath}/${id}`}]
      );

      this.setState({ brochureRequest, loaded: true, itemLogs: itemLogs['hydra:member']});
    } catch (ex) {}
  };

  getPrevious = () => {
    const {history} = this.props;
    history.goBack();
  }

  render() {
    const { brochureRequest, loaded, itemLogs } = this.state;

    console.log("brochureRequest", brochureRequest);

    if (loaded) {
      return (
          <RequestDetail request={brochureRequest} getPrevious={this.getPrevious} form={"brochure"} itemLogs={itemLogs}/>
      );
    }

    return null;
  }
}

export default BrochureRequestShow;
