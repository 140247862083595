import React from 'react';
import ReactECharts from "echarts-for-react";
import {themeColors} from '../../../falcon/src/helpers/utils';

const EChartLeadsByCity = ({title, subTitle, data, theme}) => {
    console.log('EChartLeadsByCity.data', data);

    /* legends: brochure, devis, essai,
     * categories: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
     * series: [
            {
                name: '2011年',
                type: 'bar',
                data: [18203, 23489, 29034, 104970, 131744, 630230]
            },
            {
                name: '2012年',
                type: 'bar',
                data: [19325, 23438, 31000, 121594, 134141, 681807]
            }
        ]
     */
    const option = {
        title: {
            text: title,
            subtext: subTitle,
            textStyle: {color: theme === 'dark' ? '#ffffff' : '#5d5e5e'},
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 30,
            data: data.labels,
            textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: '55%',
                data: data.values,
                center: ['40%', '50%'],
                top: 10,
                left: 10,
                emphasis: {
                    itemStyle: {
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                        shadowOffsetX: 0,
                        shadowBlur: 10,
                    },
                },
                label: {
                    show: false
                },
                labelLine: {
                    show: false
                },
            }
        ],
        grid: { containLabel: true },
    };

    return <ReactECharts
        option={option}
        style={{height: 420, width: '100%'}}
        theme={theme}
    />;
}

    export default EChartLeadsByCity;
