import React, {Component} from "react";
import queryString from "query-string";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {toast} from "react-toastify";
import DateDisplay from "../../../components/DateDisplay";
import {Dropdown} from "react-bootstrap";
import {FaEllipsisH, FaEye} from "react-icons/all";
import {Link} from "react-router-dom";
import Table from "../../../components/Table";
import {getDate} from "../../../utils/utils";

class OcarzLeadList extends Component {
    state = {
        loading: true,
        total: 0,
        campaigns: [],
        ocarzLeads: [],
        currentPage: 1,
        pageSize: 30,
        sortColumn: {path: "dateSorted", order: "desc"},
        search: {},
        brand: {},
        selectedCampaign: null,
        selectedStatus: null,
        dateRange: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date()]

    };
    genders = {
        mister: 'Homme',
        lady: 'Femme',
        miss: 'Demoiselle',
    };
    columns = [
        {
            path: "dateSorted",
            label: "Date",
            content: (request) => (
                <DateDisplay date={request.createdAt}/>
            ),
        },
        {
            path: "email",
            label: "Email",
        },
        {
            path: 'nameSorted',
            content: (request) => <React.Fragment>{request.firstName} {request.lastName}</React.Fragment>,
            label: "Nom"
        },
        {
            path: 'telephone',
            label: "Téléphone",
            content: (request) => <React.Fragment>
                {request.telephone}{request.telephoneSecondary && `/${request.telephoneSecondary}`}
            </React.Fragment>,
        },
        {
            path: 'utmCampaign',
            label: "UTM Campaign",
        },
        {
            path: 'utmSource',
            label: "UTM Source",
        },
        {
            path: 'utmMedium',
            label: "UTM Medium",
        },
        {
            path: 'webhelpStatus',
            label: "webhelp status",
            content: (request) => (
                request.latestItemLog && request.latestItemLog.result === "ok"
                    ? <span className="badge badge-success">OK</span> :
                    request.latestItemLog && request.latestItemLog.result === "error" ?
                        <span className="badge badge-danger">ERROR</span> :
                        <span className="badge badge-warning">not processed or no log available</span>
            )
        },
        {
            key: "actions",
            content: (request) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="transparent"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}>
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link to={`/ocarz_leads/${request.id}`}
                                      className={"btn btn-light btn-block shadow-sm text-left"}>
                                    <FaEye/> Voir
                                </Link>
                            </Dropdown.Item>

                            {/*<Dropdown.Item className="p-0">
                                <button
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Etes-vous sûr?',
                                            text: 'Vous êtes sur le point de supprimer cette demande.',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Oui, je le supprime!',
                                            cancelButtonText: 'Non, je le garde!'
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                if (this.handleDelete(request)) {
                                                    Swal.fire('Demande supprimée!', 'La demande a été bien supprimée.', 'success');
                                                } else {
                                                    Swal.fire('Demande non supprimée!', 'La demande n\'a pas été supprimée.', 'error');
                                                }
                                            } else {
                                            }
                                        });
                                    }}
                                    className={"btn btn-light btn-block shadow-sm text-left"}>
                                    <FaTrash/> Supprimer
                                </button>
                            </Dropdown.Item>*/}
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getCampaigns();
        this.getRequests();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;

        if (prevProps.location.key !== location.key) {
            this.getRequests();
        }

        if ((this.state.dateRange === null)
            || (!prevState.dateRange || (
                prevState.dateRange[0] !== this.state.dateRange[0]
                || prevState.dateRange[1] !== this.state.dateRange[1]))
            || (this.state.currentPage !== prevState.currentPage)
            || (this.state.selectedCampaign !== prevState.selectedCampaign)
            || (this.state.selectedStatus !== prevState.selectedStatus)
            || (this.state.search !== prevState.search)) {
            this.getRequests();
        }
    }

    getRequests = async () => {
        this.setState({loading: true});
        const {location} = this.props;
        const {dateRange, currentPage, search, selectedCampaign, selectedStatus} = this.state;
        let params = queryString.parse(location.search);

        const apiHelper = new ApiResourceHelper(`/ocarz_leads`);

        const queryParams = this.getFilters(selectedCampaign, params.brand_id, dateRange, currentPage, search, selectedStatus);
        const {data} = await apiHelper.findBy(queryParams);

        const finalRequests = [];
        data["hydra:member"].forEach((r) => {
            const date = new Date(r.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;

            finalRequests.push({
                ...r, dateSorted, nameSorted: `${r.firstName} ${r.lastName}`
            });
        });

        this.setState({ocarzLeads: finalRequests, loading: false, total: data["hydra:totalItems"]});
    };

    handleDelete = async (request) => {
        const originalRequests = this.state.ocarzLeads;

        const ocarzLeads = originalRequests.filter((b) => b.id !== request.id);
        this.setState({ocarzLeads});

        try {
            await this.apiHelper.remove(`${this.apiPath}/${request.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This request has already been removed.");

            this.setState({ocarzLeads: originalRequests});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    setPage = (page) => {
        this.setState({currentPage: page});
    }

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    handleDateRangeChange = (dateRange) => {
        console.log("dateRange", dateRange);

        this.setState({
            searchQuery: "",
            currentPage: 1,
            dateRange
        });
    }

    getCampaigns = async (source) => {
        try {
            const apiPath = "/campaigns";
            const helper = new ApiResourceHelper(apiPath);
            const params = [];

            const {location} = this.props;
            const urlParams = queryString.parse(location.search);
            params.push({key: "model.brand", value: urlParams.brand_id});

            if (source) {
                params.push({key: "source", value: 'ocarz'});
            }

            const {data} = await helper.findBy(params);
            this.setState({campaigns: data["hydra:member"]});
        } catch (ex) {
        }
    };

    getCampaignList = () => {
        const {campaigns} = this.state;

        const campaignList = [];
        campaigns.map((campaign) => {
            campaignList.push({
                key: `${campaign.title}`,
                value: `/campaigns/${campaign.id}`,
            });

            return true;
        });

        return campaignList;
    };

    onCampaignChange = (event) => {
        this.setState({selectedCampaign: event.target.value});
    }

    getFilters(campaign, brandId, dateRange, currentPage, search, webHelpResult) {
        const queryParams = [];

        queryParams.push({key: "source", value: "ocarz"});

        if (brandId) {
            queryParams.push({key: "campaign.model.brand", value: brandId});
        }

        if (webHelpResult) {
            queryParams.push({key: "latestItemLog.status", value: webHelpResult});
        }

        if (campaign) {
            queryParams.push({key: "campaign", value: campaign});
        }

        if (dateRange && dateRange[0]) {
            queryParams.push({key: 'createdAt%5Bafter%5D', value: getDate(dateRange[0])});
        }

        if (dateRange && dateRange[1]) {
            queryParams.push({key: 'createdAt%5Bbefore%5D', value: `${getDate(dateRange[1])} 23:59`});
        }

        if (currentPage) {
            queryParams.push({key: 'page', value: currentPage});
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams.push({key: filter[0], value: filter[1]})
            })
        }
        return queryParams;
    }

    getExportFilters(dateRange, search, campaign) {
        const queryParams = {};

        if (dateRange && dateRange[0]) {
            queryParams['from'] = getDate(dateRange[0]);
        }

        if (dateRange && dateRange[1]) {
            queryParams['to'] = `${getDate(dateRange[1])} 23:59`;
        }

        if (campaign) {
            queryParams['campaign'] = campaign;
        }

        if (search) {
            Object.entries(search).forEach(filter => {
                queryParams[filter[0]] = filter[1];
            });
        }
        return queryParams;
    }

    onTextChange = (event) => {
        console.log('on.event', event);

        this.setState({search: {...this.state.search, [event.name]: event.value}});
        console.log('on.search', Object.entries(this.state.search));
    }

    onRequestChange = (event) => {
        console.log('onRequestChange.event', event);
        const value = event.target.value !== '---' ? event.target.value : null;
        this.setState({search: {...this.state.search, request: value}});
    }
    onStatusChange = (event) => {
        this.setState({selectedStatus: event.target.value});
    }

    render() {
        const {
            ocarzLeads,
            total,
            currentPage,
            pageSize,
            sortColumn,
            dateRange,
            loading,
            search,
            selectedCampaign,
            selectedStatus
        } = this.state;

        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={ocarzLeads}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    tableName={"ocarz leads"}
                    tableTitle={"Ocarz leads"}
                    searchQuery={this.state.searchQuery}
                    onSearchChange={this.handleSearchChange}
                    totalCount={total}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    dateRange={dateRange}
                    handleDateRangeChange={this.handleDateRangeChange}
                    loading={loading}
                    extraFilters={[
                        {
                            name: "campaign",
                            type: "select",
                            label: false,
                            options: [{key: "Campagne", value: "---"}].concat(this.getCampaignList()),
                            onChange: this.onCampaignChange
                        },
                        {
                            name: "result",
                            type: "select",
                            label: false,
                            options: [{key: "webhelpStatus", value: ""},
                                {key: "Success", value: "ok"},
                                {key: "Error", value: "error"},],
                            onChange: this.onStatusChange
                        },
                        {name: "email", type: "text", label: "Email", onChange: this.onTextChange},
                        {name: "firstName", type: "text", label: "Prénom", onChange: this.onTextChange},
                        {name: "lastName", type: "text", label: "Nom", onChange: this.onTextChange},
                        {name: "telephone", type: "text", label: "Téléphone", onChange: this.onTextChange},
                    ]}
                    exportEndpoint={"/ocarz_lead_exports"}
                    exportFilename={"ocarz_leads.csv"}
                    exportFilters={this.getExportFilters(dateRange, search, selectedCampaign)}
                />
            </React.Fragment>
        );
    }
}

export default OcarzLeadList;
