import Joi from "joi-browser";
import OcarzLeadCreate from "../OcarzLeadCreate";

class OcarzLeadEdit extends OcarzLeadCreate {
  schema = { ...this.schema, id: Joi.number().required() };

  componentDidMount = () => {
    this.populateOcarzLead();
  };

  populateOcarzLead = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: OcarzLead } = await this.OcarzLeadHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("OcarzLead", OcarzLead);

      this.setState({ data: this.mapToViewOcarzLead(OcarzLead) });

      console.log('arrived here');
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.OcarzLeadHelper.update(`${this.apiPath}/${id}`, this.state.data);
    this.props.history.replace("/leads");
  };

  mapToViewOcarzLead = (OcarzLead) => {
    return {
      id: OcarzLead.id,
      firstName: OcarzLead.firstName,
      lastName: OcarzLead.lastName,
      email: OcarzLead.email,
      telephone: OcarzLead.telephone,
      zipCode: OcarzLead.zipCode,
      acceptedMarketingActivity: OcarzLead.acceptedMarketingActivity,
    };
  };
}

export default OcarzLeadEdit;
