
const themeKey = "theme";

class ThemeHelper {
    /**
     * Set theme = light/Dark
     * @param theme
     */
    setTheme = (theme) => {
        localStorage.setItem(themeKey, theme);
    }

    getTheme = () => {
        return localStorage.getItem(themeKey);
    }
}

export default ThemeHelper;