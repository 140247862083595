import React, {Component} from "react";
import ApiResourceHelper from "../../helpers/ApiResourceHelper";
import Date from "../../components/DateTimeDisplay";
import moment from "moment/moment";

class ChatbotShow extends Component {
    state = {
        chabotLead: {},
        errors: {},
        loaded: false,
        campaign: null,
        tab: 'info',
        itemLogs: []
    };

    genders = {
        male: 'Homme',
        Mr: 'Homme',
        female: 'Femme',
        Mme: 'Femme',
    };

    apiPath = "/leads";

    requestHelper;
    itemLogHelper;

    constructor(props) {
        super(props);
        this.requestHelper = new ApiResourceHelper(this.apiPath);
        this.itemLogHelper = new ApiResourceHelper("/item_logs");
    }

    componentDidMount = () => {
        this.getrequest();
    };

    getrequest = async () => {
        try {
            const id = this.props.match.params.id;
            const {data: chabotLead} = await this.requestHelper.findOne(
                `/leads/${id}`
            );
            const {data: campaign} = await this.requestHelper.findOne(
                `${chabotLead.lead['@id']}`
            );

            const {data: itemLogs} = await this.itemLogHelper.findBy(
                [{key: 'mediaLead', value: `/leads/${id}`}]
            );

            this.setState({chabotLead, campaign, loaded: true, itemLogs: itemLogs['hydra:member']});

        } catch (ex) {
            console.error(ex)
        }
    };

    getPrevious = () => {
        const {history} = this.props;
        history.goBack();
    }

    render() {
        const {chabotLead, loaded, campaign, itemLogs} = this.state;
        const columns = [
            {
                path: "processedAt",
                label: "traité à",
                content: (request) =>
                    (moment(request.processedAt).format('lll'))
            },
            {
                path: "resContent",
                label: "Response status"
            },
            {
                path: "resContent",
                label: "Response Content"
            },
            {
                path: "webhelpStatus",
                label: "Webhelp Status"
            },
            {
                path: "validationLog",
                label: "validation Log",
            },
            {
                path: "logs",
                label: "Log"
            },
        ];
        console.log("request", chabotLead);
        // console.log("request", campaign);


        if (loaded) {
            return (
                <div className="card" style={{width: "100%"}}>
                    <div className="card-body">
                        <h5 className="card-title" style={{textTransform: "capitalize"}}>Fiche De Précommande</h5>
                        <div>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "info" && "active"}`} href="#"
                                       onClick={() => this.setState({tab: "info"})}>Info</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "feedback" && "active"}`} href="#"
                                       onClick={() => this.setState({tab: "feedback"})}>Feedback</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.tab === "log" && "active"}`} href="#"
                                       onClick={() => this.setState({tab: "log"})}>Log</a>
                                </li>
                            </ul>
                            {this.state.tab === "info" && <table className="table">
                                <tbody>
                                <tr>
                                    <th scope="col">Date</th>
                                    <td>
                                        <Date date={chabotLead.createdAt}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col">Civilité</th>
                                    <td>{this.genders[chabotLead.gender] ? this.genders[chabotLead.gender] : chabotLead.gender}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Nom</th>
                                    <td>{chabotLead.firstName} {chabotLead.lastName} </td>
                                </tr>
                                <tr>
                                    <th scope="col">Email</th>
                                    <td>{chabotLead.email}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Téléphone</th>
                                    <td>{chabotLead.telephone}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Ville</th>
                                    <td>
                                        {chabotLead.showroom &&
                                            <>
                                                {(chabotLead.showroom && chabotLead.showroom.city) && chabotLead.showroom.city.name}
                                            </>
                                        }
                                        {chabotLead.city &&
                                            <>
                                                {(chabotLead.city) && chabotLead.city.name}
                                            </>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="col">Concessionnaire</th>
                                    <td>{chabotLead.showroom && chabotLead.showroom.name}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Marque</th>
                                    <td>{campaign.model && campaign.model.brand.name}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Modèle</th>
                                    <td>{campaign.model && campaign.model.name}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Version</th>
                                    <td>{chabotLead.trim}</td>
                                </tr>

                                <tr>
                                    <th scope="col">Couleur</th>
                                    <td>{chabotLead.color}</td>
                                </tr>

                                <tr>
                                    <th scope="col">UTM Campaign</th>
                                    <td>{chabotLead.utmCampaign ? chabotLead.utmCampaign : 'direct'}</td>
                                </tr>

                                <tr>
                                    <th scope="col">UTM Source</th>
                                    <td>{chabotLead.utmSource ? chabotLead.utmSource : 'direct'}</td>
                                </tr>

                                <tr>
                                    <th scope="col">UTM Medium</th>
                                    <td>{chabotLead.utmMedium ? chabotLead.utmMedium : 'direct'}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Transaction ID</th>
                                    <td>{chabotLead.transactionId}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Tag</th>
                                    <td>{chabotLead.tag}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Version</th>
                                    <td>{chabotLead.version}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Pack</th>
                                    <td>{chabotLead.pack}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Couleur</th>
                                    <td>{chabotLead.color}</td>
                                </tr>
                                <tr>
                                    <th scope="col">Prix</th>
                                    <td>{chabotLead.price}</td>
                                </tr>
                                </tbody>
                            </table>}
                            {this.state.tab === "feedback" && <div>
                                {chabotLead.feedback && <table className="table">
                                    <tbody>
                                    <tr>
                                        <th scope="col">Webhelp ID</th>
                                        <td>{chabotLead.feedback.webhelpId}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Commentaite</th>
                                        <td>{chabotLead.feedback.comment}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Date d'appel</th>
                                        <td><Date date={chabotLead.feedback.callDate}/></td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Heure d'appel</th>
                                        <td>{chabotLead.feedback.callHour}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Statut d'appel</th>
                                        <td>{chabotLead.feedback.callStatus}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Action</th>
                                        <td>{chabotLead.feedback.action}</td>
                                    </tr>
                                    </tbody>
                                </table>}

                            </div>}
                            {this.state.tab === "log" && <table className="table">
                                <thead>
                                <tr>
                                    {
                                        columns.map((column) => (
                                            <th key={column.path || column.key}>{column.label}</th>
                                        ))
                                    }
                                </tr>

                                </thead>
                                <tbody>
                                {
                                    itemLogs && itemLogs.map((request) => (
                                        <tr key={request.id}>
                                            <td>{moment(request.processedAt).format('lll')}</td>
                                            <td>{request.resStatus}</td>
                                            <td>{request.resContent}</td>
                                            <td>{request.webhelpStatus}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{request.validationLog}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{request.log}</td>
                                        </tr>
                                    ))
                                }

                                </tbody>
                            </table>}
                        </div>

                    </div>
                    <div className="card-footer">
                        <div className={"btn-group"}>
                            <button onClick={() => this.getPrevious()} className="btn badge badge-secondary badge-pill">
                                Retour
                            </button>
                            {/*<Link href={`/quote_requests/${request.id}/edit`} className="btn btn-primary">*/}
                            {/*    Edit Quote*/}
                            {/*</Link>*/}
                        </div>

                    </div>
                </div>
            );
        }

        return null;
    }
}

export default ChatbotShow;
