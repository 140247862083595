import React, {Component} from "react";
import RequestList from "../../../components/App/RequestList";
import queryString from "query-string";

class BrochureRequestList extends Component {
    render() {
        const {location, user} = this.props;
        let params = queryString.parse(location.search);

        return (
            <RequestList user={user} title={"Liste des brochures"} tableName={"brochures"} form={"brochure"} endpoint={"/brochure_requests"} exportEndpoint={"/brochure_request_exports"} exportFilename={"brochures.csv"} brandId={params.brand_id} location={location} />
        );
    }
}

export default BrochureRequestList;
