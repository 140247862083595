import React, { Component } from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import http from "../../../services/http";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageCreate from "../../../components/App/ImageCreate";
import imageStyles from "../../../assets/styles/image.module.css";
import DocumentCreate from "../../../components/App/DocumentCreate";
import ImageRemove from "../../../components/App/ImageRemove";
import DocumentRemove from "../../../components/App/DocumentRemove";
import {
  FaArrowLeft,
  FaEdit,
  FaExclamation,
  FaExclamationTriangle,
  FaPencilAlt,
} from "react-icons/all";

class ModelShow extends Component {
  state = {
    model: {},
    errors: {},
    loaded: false,
  };

  apiPath = "/models";

  modelHelper;

  brandImageSizes = [
    { brand: "fiat", width: 567, height: 267 },
    { brand: "fiat-professional", width: 547, height: 362 },
    { brand: "jeep", width: 559, height: 326 },
    { brand: "alfa-romeo", width: 450, height: 200 },
    { brand: "abarth", width: 640, height: 343 },
  ];

  constructor(props) {
    super(props);
    this.modelHelper = new ApiResourceHelper(this.apiPath);
  }

  componentDidMount = () => {
    this.getModel();
  };

  getPrevious = () => {
    const { location, history } = this.props;
    //console.log('location', history);
    history.goBack();
  };

  getModel = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: model } = await this.modelHelper.findOne(
        `${this.apiPath}/${id}`
      );

      this.setState({ model, loaded: true });
    } catch (ex) {}
  };

  updatePhoto = async (image) => {
    console.log("start updating model photo");

    const { model } = this.state;

    const currentModel = {
      ...model,
      image: `/images/${image.id}`,
      brand: `/brands/${model.brand.id}`,
    };

    if (model.document) {
      currentModel.document = `/documents/${model.document.id}`;
    }

    console.log("currentModel", currentModel);

    const modelIri = `/models/${model.id}`;

    try {
      const { data: updatedModel } = await this.modelHelper.update(
        modelIri,
        currentModel
      );
      console.log("model photo updated", model);

      this.setState({ model: updatedModel });
    } catch (ex) {
      console.log("updating model image ex", ex);
    }
  };

  removePhoto = async () => {
    console.log("start removing model photo");
    try {
      const { model } = this.state;

      const currentModel = {
        ...model,
        brand: `/brands/${model.brand.id}`,
      };

      console.log("currentModel", currentModel);

      const modelIri = `/models/${model.id}`;

      currentModel.image = null;

      if (model.document) {
        currentModel.document = `/documents/${model.document.id}`;
      }

      const { data: updatedModel } = await this.modelHelper.update(
        modelIri,
        currentModel
      );

      console.log("model photo removed", updatedModel);

      this.setState({ model: updatedModel });
    } catch (ex) {
      console.log("removing model image ex", ex);
    }
  };

  updateModelDocument = async (document) => {
    try {
      const documentIri = `/documents/${document.id}`;
      console.log("start updating model document", documentIri);

      const { model } = this.state;

      const currentModel = {
        ...model,
        document: documentIri,
        brand: `/brands/${model.brand.id}`,
      };

      if (model.image) {
        currentModel.image = `/images/${model.image.id}`;
      }

      const modelIri = `/models/${model.id}`;
      const { data: updatedModel } = await this.modelHelper.update(
        modelIri,
        currentModel
      );

      console.log("model document updated", updatedModel);

      this.setState({ model: updatedModel });
    } catch (ex) {
      console.log("updating model document ex", ex);
    }
  };

  removeDocument = async () => {
    console.log("start removing model document");
    try {
      const { model } = this.state;

      const currentModel = {
        ...model,
        brand: `/brands/${model.brand.id}`,
      };

      console.log("currentModel", currentModel);

      const modelIri = `/models/${model.id}`;

      currentModel.document = null;

      if (model.image) {
        currentModel.image = `/images/${model.image.id}`;
      }

      const { data: updatedModel } = await this.modelHelper.update(
        modelIri,
        currentModel
      );

      console.log("model document removed", updatedModel);

      this.setState({ model: updatedModel });
    } catch (ex) {
      console.log("removing model document ex", ex);
    }
  };

  getSize = (brand) => {
    const sizes = this.brandImageSizes.filter((s) => s.brand === brand.slug);
    return sizes[0];
  };

  render() {
    const { model, loaded } = this.state;
    const { history } = this.props;

    console.log("model", model);

    if (loaded) {
      const { width: minWidth, height: minHeight } = this.getSize(model.brand);

      return (
        <div className="card" style={{ width: "100%" }}>
          <div className="card-header border-bottom">
            <h5 className="card-title text-center">{model.name}</h5>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={6}>
                <div className={imageStyles["container"]}>
                  <img
                    src={
                      model.image
                        ? `${http.endPoint}/uploads/images/${
                            model.image.filePath
                          }`
                        : `https://via.placeholder.com/${minWidth}x${minHeight}`
                    }
                    alt={`${model.name}`}
                    style={{ width: "100%" }}
                  />
                  {!model.image && (
                    <ImageCreate
                      uploadCallback={this.updatePhoto}
                      minWidth={minWidth}
                      minHeight={minHeight}
                    />
                  )}
                  {model.image && (
                    <ImageRemove
                      image={model.image}
                      removeCallback={this.removePhoto}
                    />
                  )}

                  <div className="alert alert-info d-flex align-items-center mt-2">
                    <FaExclamation className="mr-3" />{" "}
                    {`La largeur doît être égal à ${minWidth}px et la hauteur à ${minHeight}px. )`}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                  }}
                >
                  <Link
                    to={`/models/${model.id}/edit`}
                    className="btn-reveal py-0 px-2 btn btn-link"
                  >
                    <FaPencilAlt />
                  </Link>
                </div>

                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">Marque</th>
                      <td>{model.brand.name}</td>
                    </tr>
                    <tr>
                      <th scope="col">Modèle</th>
                      <td>{model.name}</td>
                    </tr>
                    <tr>
                      <th scope="col">Description</th>
                      <td>{model.description}</td>
                    </tr>
                    <tr>
                      <th scope="col">Brochure</th>
                      <td>
                        {model.document && (
                          <a
                            href={model.brochureUrl}
                            download
                            className={"mr-2"}
                          >
                            {model.document.source}
                          </a>
                        )}
                        {model.document && (
                          <DocumentRemove
                            image={model.document}
                            removeCallback={this.removeDocument}
                          />
                        )}
                        {!model.document && (
                          <React.Fragment>
                            <DocumentCreate
                              uploadCallback={this.updateModelDocument}
                            />
                            &nbsp;
                            <span className={"help"}>
                              (cliquer pour ajouter une brochure)
                            </span>
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
          <div className="card-footer">
            <div className={"btn-group"}>
              <button
                onClick={() => history.goBack()}
                className="btn badge badge-secondary badge-pill"
              >
                <FaArrowLeft /> Retour
              </button>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default ModelShow;
