import React from 'react';
import {Route, Switch} from "react-router-dom";
import Login from "../../../pages/security/Login";
import ProtectedRoute from "../ProtectedRoute";
import Logout from "../../../pages/security/Logout";
import TestDriveRequestEdit from "../../../pages/testDriveRequests/TestDriveRequestEdit";
import TestDriveRequestCreate from "../../../pages/testDriveRequests/TestDriveRequestCreate";
import TestDriveRequestShow from "../../../pages/testDriveRequests/TestDriveRequestShow";
import TestDriveRequestList from "../../../pages/testDriveRequests/TestDriveRequestList";
import QuoteRequestEdit from "../../../pages/quoteRequests/QuoteRequestEdit";
import QuoteRequestCreate from "../../../pages/quoteRequests/QuoteRequestCreate";
import QuoteRequestShow from "../../../pages/quoteRequests/QuoteRequestShow";
import QuoteRequestList from "../../../pages/quoteRequests/QuoteRequestList";
import BrochureRequestEdit from "../../../pages/brochureRequests/BrochureRequestEdit";
import BrochureRequestCreate from "../../../pages/brochureRequests/BrochureRequestCreate";
import BrochureRequestShow from "../../../pages/brochureRequests/BrochureRequestShow";
import BrochureRequestList from "../../../pages/brochureRequests/BrochureRequestList";
import ShowroomEdit from "../../../pages/showrooms/ShowroomEdit";
import ShowroomCreate from "../../../pages/showrooms/ShowroomCreate";
import ShowroomShow from "../../../pages/showrooms/ShowroomShow";
import ShowroomList from "../../../pages/showrooms/ShowroomList";
import ModelEdit from "../../../pages/models/ModelEdit";
import ModelCreate from "../../../pages/models/ModelCreate";
import ModelShow from "../../../pages/models/ModelShow";
import ModelList from "../../../pages/models/ModelList";
import BrandEdit from "../../../pages/brands/BrandEdit";
import BrandCreate from "../../../pages/brands/BrandCreate";
import BrandShow from "../../../pages/brands/BrandShow";
import BrandList from "../../../pages/brands/BrandList";
import Home from "../../../pages/Home";
import NotFound from "../../../pages/NotFound";
import {Redirect} from "react-router";
import UserEdit from "../../../pages/users/UserEdit";
import UserCreate from "../../../pages/users/UserCreate";
import UserShow from "../../../pages/users/UserShow";
import UserList from "../../../pages/users/UserList";
import AbandonedRequestList from "../../../pages/abandons/AbandonnedRequestList";
import LeadSubmissionEdit from "../../../pages/leads/LeadEdit";
import LeadSubmissionCreate from "../../../pages/leads/LeadCreate";
import LeadSubmissionShow from "../../../pages/leads/LeadShow";
import LeadList from "../../../pages/leads/LeadList";
import LeadGenEdit from "../../../pages/leadGens/LeadGenEdit";
import LeadGenCreate from "../../../pages/leadGens/LeadGenCreate";
import LeadGenShow from "../../../pages/leadGens/LeadGenShow";
import LeadGenList from "../../../pages/leadGens/LeadGenList";
import CampaignShow from "../../../pages/campaigns/CampaignShow";
import CampaignEdit from "../../../pages/campaigns/CampaignEdit";
import CampaignList from "../../../pages/campaigns/CampaignList";
import CampaignCreate from "../../../pages/campaigns/CampaignCreate";
import OcarzLeadCreate from "../../../pages/ocarzLeads/OcarzLeadCreate";
import OcarzLeadEdit from "../../../pages/ocarzLeads/OcarzLeadEdit";
import OcarzLeadList from "../../../pages/ocarzLeads/OcarzLeadList";
import OcarzLeadShow from "../../../pages/ocarzLeads/OcarzLeadShow";
import XSLeadShow from "../../../pages/xsLeads/XSLeadShow";
import XSLeadList from "../../../pages/xsLeads/XSLeadList";
import BotLeadList from "../../../pages/botLeads/BotLeadList";
import BotLeadShow from "../../../pages/botLeads/BotLeadShow";
import BotLeadEdit from "../../../pages/botLeads/BotLeadEdit";
import PrePurchaseRequestEdit from "../../../pages/prePurchaseRequests/PrePurchaseRequestEdit";
import PrePurchaseRequestShow from "../../../pages/prePurchaseRequests/PrePurchaseRequestShow";
import PrePurchaseRequestList from "../../../pages/prePurchaseRequests/PrePurchaseRequestList";
import InformationRequestList from "../../../pages/informationRequests/list";
import InformationRequestShow from "../../../pages/informationRequests/show";
import InformationRequestEdit from "../../../pages/informationRequests/edit";
import LogList from "../../../pages/Logs/LogList";
import LogShow from "../../../pages/Logs/LogShow";
import ChatbotEdit from "../../../pages/chatbot/ChatbotEdit";
import ChatbotShow from "../../../pages/chatbot/ChatbotShow";
import ChatbotList from "../../../pages/chatbot/ChatbotList";

const RouteList = ({user, brands, theme}) => {
    console.log('RouteList.user', user);
    console.log('RouteList.brands', brands);
    return (
        <Switch>
            <Route
                path="/login"
                component={Login}
                exact
            />
            <ProtectedRoute
                path="/logout"
                render={(props) => <Logout user={user} {...props} exact/>}
                exact
            />

            {/* lead_gens */}
            <ProtectedRoute
                path="/lead_gens/:id/edit"
                render={(props) => <LeadGenEdit user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/lead_gens/create"
                render={(props) => <LeadGenCreate user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/lead_gens/:id"
                render={(props) => <LeadGenShow user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/lead_gens"
                render={(props) => <LeadGenList user={user} {...props} exact/>}
                exact
            />

            {/* Information requests */}
            <ProtectedRoute
                path="/information_requests"
                render={(props) => <InformationRequestList user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/information_requests/:id/edit"
                render={(props) => <InformationRequestEdit user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/information_requests/:id"
                render={(props) => <InformationRequestShow user={user} {...props} exact/>}
                exact
            />

            {/* Ocarz Leads */}
            <ProtectedRoute
                path="/ocarz_leads/:id/edit"
                render={(props) => <OcarzLeadEdit user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/ocarz_leads/create"
                render={(props) => <OcarzLeadCreate user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/ocarz_leads/:id"
                render={(props) => <OcarzLeadShow user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/ocarz_leads"
                render={(props) => <OcarzLeadList user={user} {...props} exact/>}
                exact
            />

            {/* Xs Leads */}
            <ProtectedRoute
                path="/xs_leads/:id"
                render={(props) => <XSLeadShow user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/xs_leads"
                render={(props) => <XSLeadList user={user} {...props} exact/>}
                exact
            />

            {/* Logs */}
            <ProtectedRoute
                path="/logs_list"
                render={(props) => <LogList user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/logs_show/:id"
                render={(props) => <LogShow user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/prebookings/:id/edit"
                render={(props) => <PrePurchaseRequestEdit user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/prebookings/:id"
                render={(props) => <PrePurchaseRequestShow user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/prebookings"
                render={(props) => <PrePurchaseRequestList user={user} {...props} exact/>}
                exact
            />


        <ProtectedRoute
            path="/chatbot_leads/:id/edit"
            render={(props) => <ChatbotEdit user={user} {...props} exact/>}
            exact
        />

        <ProtectedRoute
            path="/chatbot_leads/:id"
            render={(props) => <ChatbotShow user={user} {...props} exact/>}
            exact
        />

        <ProtectedRoute
            path="/chatbot_leads"
            render={(props) => <ChatbotList user={user} {...props} exact/>}
            exact
        />

            <ProtectedRoute
                path="/bot_leads/:id/edit"
                render={(props) => <BotLeadEdit user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/bot_leads/:id"
                render={(props) => <BotLeadShow user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/bot_leads"
                render={(props) => <BotLeadList user={user} {...props} exact/>}
                exact
            />

            {/* Leads */}
            <ProtectedRoute
                path="/leads/:id/edit"
                render={(props) => <LeadSubmissionEdit user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/leads/create"
                render={(props) => <LeadSubmissionCreate user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/leads/:id"
                render={(props) => <LeadSubmissionShow user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/leads"
                render={(props) => <LeadList user={user} {...props} exact/>}
                exact
            />

            {/* Abandons */}
            <ProtectedRoute
                path="/abandons"
                render={(props) => <AbandonedRequestList user={user}{...props} exact/>}
                user={user}
                role={"ROLE_SUPER_ADMIN"}
                exact
            />

            {/* Users */}
            <ProtectedRoute
                path="/users/:id/edit"
                render={(props) => <UserEdit user={user} {...props} exact/>}
                user={user} role={"ROLE_SUPER_ADMIN"}
                exact
            />

            <ProtectedRoute
                path="/users/create"
                render={(props) => <UserCreate user={user} {...props} exact/>}
                user={user} role={"ROLE_SUPER_ADMIN"}
                exact
            />

            <ProtectedRoute
                path="/users/:id"
                render={(props) => <UserShow user={user}  {...props} exact/>}
                user={user} role={"ROLE_SUPER_ADMIN"}
                exact
            />

            <ProtectedRoute
                path="/users"
                render={(props) => <UserList user={user} {...props} exact/>}
                user={user} role={"ROLE_SUPER_ADMIN"}
                exact
            />

            {/* TestDriveRequests */}
            <ProtectedRoute
                path="/test_drive_requests/:id/edit"
                render={(props) => <TestDriveRequestEdit user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/test_drive_requests/create"
                render={(props) => <TestDriveRequestCreate user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/test_drive_requests/:id"
                render={(props) => <TestDriveRequestShow user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/test_drive_requests"
                render={(props) => <TestDriveRequestList user={user} {...props} exact/>}
                exact
            />

            {/* QuoteRequests */}
            <ProtectedRoute
                path="/quote_requests/:id/edit"
                render={(props) => <QuoteRequestEdit user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/quote_requests/create"
                render={(props) => <QuoteRequestCreate user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/quote_requests/:id"
                render={(props) => <QuoteRequestShow user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/quote_requests"
                render={(props) => <QuoteRequestList user={user} {...props} exact/>}
                exact
            />

            {/* BrochureRequests */}
            <ProtectedRoute
                path="/brochure_requests/:id/edit"
                render={(props) => <BrochureRequestEdit user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/brochure_requests/create"
                render={(props) => <BrochureRequestCreate user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/brochure_requests/:id"
                render={(props) => <BrochureRequestShow user={user} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/brochure_requests"
                render={(props) => <BrochureRequestList user={user} {...props} exact/>}
                exact
            />

            {/* Showrooms */}
            <ProtectedRoute
                path="/showrooms/:id/edit"
                render={(props) => <ShowroomEdit user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>}
            />

            <ProtectedRoute
                path="/showrooms/create"
                render={(props) => <ShowroomCreate user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>}
                exact/>

            <ProtectedRoute
                path="/showrooms/:id"
                render={(props) => <ShowroomShow user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>}
                exact/>

            <ProtectedRoute
                path="/showrooms"
                render={(props) => <ShowroomList user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>}
                exact/>

            {/* Models */}
            <ProtectedRoute
                path="/models/:id/edit"
                render={(props) => <ModelEdit user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>}
            />

            <ProtectedRoute
                path="/models/create"
                render={(props) => <ModelCreate user={user} {...props} exact/>}
                exact/>

            <ProtectedRoute
                path="/models/:id"
                render={(props) => <ModelShow user={user} {...props} exact/>}
                exact/>

            <ProtectedRoute
                path="/models"
                render={(props) => <ModelList user={user} {...props} exact/>}
                user={user}
                role={"ROLE_SUPER_ADMIN"}
                exact/>

            {/* Campaigns */}
            <ProtectedRoute
                path="/campaigns/:id/edit"
                render={(props) => <CampaignEdit user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/campaigns/create"
                component={CampaignCreate}
                render={(props) => (<CampaignCreate user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>)}
                exact
            />

            <ProtectedRoute
                path="/campaigns/:id"
                render={(props) => (<CampaignShow user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>)}
                exact
            />

            <ProtectedRoute
                path="/campaigns"
                render={(props) => (<CampaignList user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>)}
                exact
            />

            {/* Brands */}
            <ProtectedRoute
                path="/brands/:id/edit"
                render={(props) => <BrandEdit user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>}
                exact
            />

            <ProtectedRoute
                path="/brands/create"
                component={BrandCreate}
                render={(props) => (<BrandShow user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>)}
                exact
            />

            <ProtectedRoute
                path="/brands/:id"
                render={(props) => (<BrandShow user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>)}
                exact
            />

            <ProtectedRoute
                path="/brands"
                render={(props) => (<BrandList user={user} role={"ROLE_SUPER_ADMIN"} {...props} exact/>)}
                exact
            />

            <ProtectedRoute
                path="/"
                render={(props) => (<Home user={user} brands={brands} theme={theme} {...props} exact/>)}
                exact
            />

            {/* NotFound */}
            <Route path="/404" component={NotFound}/>
            <Redirect to="/404"/>
        </Switch>
    );
};

export default RouteList;
