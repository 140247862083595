import React from "react";
import { Redirect, Route } from "react-router-dom";
import authHelper from "../../../helpers/AuthHelper";

const ProtectedRoute = ({ path, component: Component, render, role, user, ...rest }) => {
    console.log('ProtectedRoute.user', user);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!authHelper.getCurrentUser()) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    );
                }

                if (role && user.roles && !user.roles.includes(role)) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location },
                            }}
                        />
                    );
                }

                return Component ? <Component {...props} /> : render(props);
            }}
        />
    );
};

export default ProtectedRoute;
