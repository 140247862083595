export const sources = ["fb", "linkedin", "moteur", "ig", "TikTok", "Avito", "Wandaloo"];

export const sourceOptions = [
    {key: "Séléctionnez la source", value: ""},
    {key: "Facebook", value: "fb"},
    {key: "Landing page", value: "website"},
    {key: "Ocarz", value: "ocarz"},
    {key: "Linkedin", value: "linkedin"},
    {key: "Moteur", value: "moteur"},
    {key: "Instagram", value: "ig"},
    {key: "Tik Tok", value: "TikTok"},
    {key: "Avito", value: "Avito"},
    {key: "Wandaloo", value: "Wandaloo"},
    {key: "chatbot", value: "chatbot"},
]

