import React from "react";
import Joi from "joi-browser";
import {Link, Redirect} from "react-router-dom";
import Form from "../../../components/Form";
import AuthHelper from "../../../helpers/AuthHelper";
import {Container} from "react-bootstrap";

class Login extends Form {
    state = {
        data: {username: "", password: ""},
        errors: {},
    };

    schema = {
        username: Joi.string().required().label("Username"),
        password: Joi.string().required().label("Password"),
    };

    doSubmit = async () => {
        try {
            const {data} = this.state;
            await AuthHelper.login(data.username, data.password);

            //const {state} = this.props.location;
            //window.location = state ? state.from.pathname : "/";

            window.location = "/";
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                console.log("Login error", ex);
            }
        }
    };

    render() {
        if (AuthHelper.getCurrentUser()) return <Redirect to="/"/>;
        return (
            <div className={"flex-center min-vh-100 py-6 row"}>
                <div className={"col-xxl-4 col-sm-10 col-md-8 col-lg-6 col-xl-5"}>

                    <a className="text-decoration-none" href="/">
                        <div className="d-flex flex-center font-weight-extra-bold fs-5 mb-4">
                            <img className="mr-2"
                                 src="https://upload.wikimedia.org/wikipedia/commons/1/14/Logo_Fiat_Chrysler_Automobiles.png"
                                 alt=""
                                 width="200"/>
                            <span className="text-sans-serif"></span>
                        </div>
                    </a>
                    <div className={"card"}>
                        <div className={"fs--1 font-weight-normal p-5 card-body"}>
                            <div className={"text-left justify-content-between row"}>
                                <div className={"col-auto"}>
                                    <h5>Log in</h5>
                                </div>
                            </div>

                            <form onSubmit={this.handleSubmit}>
                                <div className={"form-group"}>
                                    {this.renderInput("username", "text", "Username")}
                                </div>
                                <div className={"    <div className={\"form-group\"}>"}>
                                    {this.renderInput("password", "password", "Password")}
                                </div>
                                {this.renderButton("Login")}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
