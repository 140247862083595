import React, { Component } from "react";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import {FaTimes} from "react-icons/all";
import imageStyles from "../../../assets/styles/image.module.css";
import Swal from "sweetalert2";

class ImageRemove extends Component {
    apiHelper;

    constructor(props) {
        super(props);
        this.apiHelper = new ApiResourceHelper("/images");
    }

    componentDidMount = async () => {};

    onDelete = async () => {
        try {
            const {image, removeCallback} = this.props;
            removeCallback();
            //const iri = `/images/${image.id}`;
            //await this.apiHelper.remove(iri);
        } catch (ex) {
            console.log('upload_ex', ex);
        }
    };

    render() {
        return (
            <label className={imageStyles['btn']} onClick={() => {
                Swal.fire({
                    title: 'Etes-vous sûr?',
                    text: 'Vous êtes sur le point de supprimer une image.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Oui, je la supprime!',
                    cancelButtonText: 'Non, je la garde!'
                }).then(result => {
                    if (result.isConfirmed) {
                        if (this.onDelete()) {
                            Swal.fire('Image supprimée!', 'La image a été bien supprimée.', 'success');
                        } else {
                            Swal.fire('Image non supprimée!', 'La image n\'a pas été supprimée à cause d\'une erreur système.', 'error');
                        }
                    }
                });
            }}>
                <div className="file btn btn-outline-danger sm-delete">
                    <FaTimes />
                </div>
            </label>
        );
    }
}

export default ImageRemove;
