import React from 'react';
import ReactECharts from "echarts-for-react";
import { themeColors } from '../../../falcon/src/helpers/utils';

const LeadsByModelAndPlatformChart = ({title, subTitle, data, theme}) => {
    console.log('theme in chart component', theme);

    /* legends: brochure, devis, essai,
     * categories: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
     * series: [
            {
                name: '2011年',
                type: 'bar',
                data: [18203, 23489, 29034, 104970, 131744, 630230]
            },
            {
                name: '2012年',
                type: 'bar',
                data: [19325, 23438, 31000, 121594, 134141, 681807]
            }
        ]
     */
    const option = {
        title: {
            text: title,
            subtext: subTitle,
            textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: { color: '#ffffff' },
        },
        legend: {
            data: ['Brochure', 'Devis', 'Essai', 'Leads LP', 'Leads Media', 'Leads Ocarz'],
            textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            type: 'scroll',
            orient: 'horizontal',
            top: 20,
            bottom: 20,
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: data.categories,
            axisLabel: {
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            }
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLabel: {
                textStyle: { color: theme === 'dark' ? '#ffffff':'#5d5e5e' },
            }
        },
        series: [
            {
                name: 'Brochure',
                type: 'bar',
                barGap: 0,
                data: data.brochures,
                itemStyle: {
                    //color: themeColors.primary
                }
            },
            {
                name: 'Devis',
                type: 'bar',
                barGap: 0,
                data: data.quotes,
                itemStyle: {
                    //color: themeColors.success
                }
            },
            {
                name: 'Essai',
                type: 'bar',
                barGap: 0,
                data: data.testDrives,
                itemStyle: {
                    //color: themeColors.warning
                }
            },
            {
                name: 'Leads LP',
                type: 'bar',
                barGap: 0,
                data: data.lp,
                itemStyle: {
                    //color: themeColors.warning
                }
            },
            {
                name: 'Leads Media',
                type: 'bar',
                barGap: 0,
                data: data.mediaLeads,
                itemStyle: {
                    //color: themeColors.warning
                }
            },
            {
                name: 'Leads Ocarz',
                type: 'bar',
                barGap: 0,
                data: data.ocarz,
                itemStyle: {
                    //color: themeColors.warning
                }
            }
        ]
    };

    return <ReactECharts
        option={option}
        style={{height: 420}}
        theme={theme}
    />;
}

export default LeadsByModelAndPlatformChart;