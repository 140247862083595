import React, {Component} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import _ from "lodash";
import Swal from "sweetalert2";

import Pagination from "../../../components/Pagination";
import Search from "../../../components/Form/Search";
import Table from "../../../components/Table";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import paginate from "../../../utils/paginate";
import {Col, Dropdown, Row} from "react-bootstrap";
import {FaEdit, FaEllipsisH, FaEye, FaTrash} from "react-icons/all";

class UserList extends Component {
    state = {
        users: [],
        currentPage: 1,
        pageSize: 30,
        sortColumn: {path: "firstName", order: "asc"},
        searchQuery: "",
    };

    apiPath = "/users";

    roles = {
        'ROLE_LEAD': 'Leads',
        'ROLE_BRAND': 'Brand',
        'ROLE_ADMIN': 'Admin',
        'ROLE_SUPER_ADMIN': 'Super admin',
    };

    columns = [
        {
            path: "email",
            label: "Email",
        },
        {
            path: "lastName",
            label: "Nom",
        },
        {
            path: "firstName",
            label: "Prénom",
        },
        {
            path: "roles",
            label: "Role",
            content: (user) => (<span>{this.roles[user.roles[0]]}</span>)
        },
        {
            key: "actions",
            content: (user) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="transparent"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}>
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link
                                    to={`/users/${user.id}/edit`}
                                    className="btn btn-light btn-block shadow-sm text-left"
                                >
                                    <FaEdit/> Edit
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item className={"p-0"}>
                                <button
                                    className="btn btn-light btn-block shadow-sm text-left"
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Etes-vous sûr?',
                                            text: 'Vous êtes sur le point de supprimer un utilisateur.',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Oui, je veux le supprimer!',
                                            cancelButtonText: 'Non, je le garde!'
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                if (this.handleDelete(user)) {
                                                    Swal.fire('Utilisateur supprimé!', 'L\'utilisateur a été bien supprimé.', 'success');
                                                } else {
                                                    Swal.fire('Utilisateur non supprimé!', 'On n\'a pas pu supprimer l\'utilisateur.', 'error');
                                                }
                                            } else {
                                            }
                                        });
                                    }}
                                >
                                    <FaTrash/> Supprimer
                                </button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        },
    ];

    userHelper;

    constructor(props) {
        super(props);
        this.userHelper = new ApiResourceHelper(this.apiPath);
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers = async () => {
        const {data} = await this.userHelper.findAll();

        this.setState({users: data["hydra:member"]});
    };

    handleDelete = async (user) => {
        const originalusers = this.state.users;

        const users = originalusers.filter((b) => b.id !== user.id);
        this.setState({users});

        try {
            await this.userHelper.remove(`${this.apiPath}/${user.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This user has already been removed.");

            this.setState({users: originalusers});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    getPagedData = () => {
        const {
            users: allusers,
            currentPage,
            pageSize,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = allusers;

        if (searchQuery) {
            filtered = allusers.filter((b) =>
                b.name.toLowerCase().startsWith(searchQuery)
            );
        }

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const users = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: users};
    };

    getExportedData = () => {
        const {data} = this.getPagedData();

        const exportedData = [];

        data.forEach((item, index) => {
            const date = new Date(item.createdAt);

            exportedData.push({
                date: `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${date.getHours()}:${date.getMinutes()}`,
                email: item.email,
                nom: item.lastName + ' ' + item.firstName,
                role: this.roles[item.roles[0]],
            });
        });

        return exportedData;
    }

    handleSearchChange = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    render() {
        const {currentPage, pageSize, sortColumn} = this.state;
        const {totalCount, data: users} = this.getPagedData();
        const {user} = this.props;

        console.log("user", user);

        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={users}
                    //exportedData={this.getExportedData()}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    createLink="/users/create"
                    tableName={"utilisateurs"}
                    tableTitle={`Liste d\'utilisateurs`}
                    searchQuery={this.state.searchQuery}
                    onSearchChange={this.handleSearchChange}
                    totalCount={totalCount}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                />
            </React.Fragment>

        );
    }
}

export default UserList;
