import React from "react";

const Button = ({ type, label, icon: Icon, cssClass, ...rest }) => {
  return (
    <button className={cssClass} rest>
      <Icon /> {label}
    </button>
  );
};

export default Button;

